<template>
  <div class="modal-login">
    <div class="loginbox">
      <div class="colselogin" @click="closeModal()">×</div>
      <div class="modal-content-login">
        <h2>Sign Up</h2>
        <p>Sign up with Google or Apple</p>

        <div class="googlebuttonbox">
          <!-- <div class="gicon">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-google"></use>
            </svg>
          </div> -->
          <div id="g_id_onload"
            data-client_id="386542798820-ebrc5182hidokcqt50k6iga2qlji6p0c.apps.googleusercontent.com"
            data-callback="handleCredentialResponse" data-auto_prompt="false" data-auto_select="true"></div>
          <div class="g_id_signin" data-type="standard" data-size="large" data-theme="outline" data-text="continue_with"
            data-shape="pill" data-width="350" data-logo_alignment="center"></div>

          <!-- Apple登录按钮 -->
          <div class="apple-auth-button">
            <div id="appleid-signin" data-mode="center-align" data-type="sign in" data-color="black" data-border="true"
              data-border-radius="15" data-width="350" data-height="40" data-label="Sign in with Apple">
            </div>
          </div>
        </div>

        <div class="divider"><span>OR</span></div>

        <div class="createuser" v-if="iscreate">
          <div class="input-group">
            <input type="text" placeholder="First name" v-model="firstName" />
            <input type="text" placeholder="Last name" v-model="lastName" />
          </div>

          <input type="email" placeholder="Email" v-model="email" />
          <input type="password" placeholder="Password" v-model="password" />

          <button class="create-account" @click="createtempaccount">
            Create an account
          </button>
          <p>
            Already have an account?
            <a href="#" @click="iscreate = false">Sign in</a>
          </p>
        </div>
        <div class="sinnbox" v-if="!iscreate">
          <input type="email" placeholder="Email" v-model="email" />
          <input type="password" placeholder="Password" v-model="password" />

          <button v-loading="isclick_login" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)" class="create-account" @click="userlogin">Login</button>
          <p>
            Don't have an account?
            <a href="#" @click="iscreate = true">Sign up</a>
          </p>
        </div>
      </div>
      <p class="terms">
        By creating or entering an account, you agree to the
        <a @click="goindex('temsservice')" href="#">Terms of Service</a> and
        <a @click="goindex('PrivacyPolicy')" href="#">Privacy Policy</a>.
      </p>
      <img :src="banner1" class="banner" alt="banner" />
    </div>

  </div>
</template>

<script>
export default {
  name: "LoginPage",
  props: {},
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      banner1: require("@/assets/images/index/banner7.png"),
      isVisible: false,
      isclick_login: false,

      iscreate: true,
      isRegistering: false,
      appleClientId: '', // Apple 登录的 clientId
    };
  },
  mounted() {
    window.handleCredentialResponse = this.handleCredentialResponse; // 关键：将方法注册为全局函数
    // 重新加载google登录插件
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    document.head.appendChild(script);

    // 打印当前环境信息，方便调试
    console.log('Current environment:', this.getEnvironment());

    // 初始化 Apple 登录
    this.initializeAppleLogin();
  },
  methods: {
    goindex(pagekey) {
      this.$router.push({ name: "IndexPage", params: { pagekey: pagekey } });
    },
    async createaccount() {
      // 测试请求方式为post的relogin接口
      const ret = await this.$axios.post("/api/relogin/", {
        email: "email",
        password: "mima",
      });
      console.log(ret.data);
    },
    async handleCredentialResponse(response) {
      if (response.credential) {
        // 解码 JWT token 以获取用户信息
        const payload = JSON.parse(atob(response.credential.split(".")[1]));
        // console.log("ID: " + payload.sub);
        // console.log("Full Name: " + payload.name);
        // console.log("Given Name: " + payload.given_name);
        // console.log("Family Name: " + payload.family_name);
        // console.log("Image URL: " + payload.picture);
        // console.log("Email: " + payload.email);
        // 在这里，您可以将用户信息发送到您的后端服务器进行验证和进一步处理
        this.sendToServer(payload);
      } else {
        console.error("No credential received");
        // 处理登录失败的情况
        this.$message.error("Login failed");
      }
    },
    async sendToServer(payload) {
      // console.log('payload',payload);
      const subid = payload.sub;
      const email = payload.email;
      const username = payload.name;
      if (subid && email) {
        // 根据subid获取用户信息,没有注册则自动根据subid注册
        const userdata = await this.$axios.post(
          "/api/getuserdata/",
          {
            subid: subid,
            email: email,
            username: username,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const resdaata = userdata.data;
        if (resdaata.code == 200) {
          // cookie保存登录信息
          this.$cookies.set("subid", resdaata.subid, { expires: 30 });
          this.$cookies.set("username", resdaata.username, { expires: 30 });
          this.$cookies.set("email", resdaata.email, { expires: 30 });
          this.$cookies.set("userlogin", true, { expires: 30 });
          this.$router.push("/home");
        }
        // 注册成功后跳转到首页
      }
    },
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        return false;
      } else {
        return true;
      }
    },
    async createtempaccount() {
      if (this.isRegistering) return;
      this.isRegistering = true;

      try {
        // 随机生一个邮箱和subid
        let guestId = localStorage.getItem("guestId");
        if (!guestId) {
          guestId = this.getOrCreateGuestId();
        }
        const subid =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);
        // const email =
        //   Math.random().toString(36).substring(2, 15) + "@easynoteai.com";
        const email = this.email;
        const password = this.password;
        const username = this.firstName + " " + this.lastName;
        if (!email) {
          this.$message.error("please input your email");
          return;
        }
        if (!this.validateEmail(email)) {
          this.$message.error("please input a valid email");
          return;
        }
        if (!password) {
          this.$message.error("please input your password");
          return;
        }
        if (!username) {
          this.$message.error("please input your name");
          return;
        }
        if (subid) {
          // console.log(response.subid);
          // 根据subid获取用户信息,没有注册则自动根据subid注册
          const userdata = await this.$axios.post(
            "/api/getuserdata/",
            {
              subid: subid,
              email: email,
              username: username,
              password: password,
              guestid: guestId,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const resdaata = userdata.data;
          if (resdaata.code == 202) {
            this.$message.error("email already exists");
            this.iscreate = false;
            return;
          }
          if (resdaata.code == 200) {
            // cookie保存登录信息
            this.$cookies.set("subid", resdaata.subid, { expires: 30 });
            this.$cookies.set("username", resdaata.username, { expires: 30 });
            this.$cookies.set("email", resdaata.email, { expires: 30 });
            this.$cookies.set("userlogin", true, { expires: 30 });
            this.$router.push("/home");
          }
          // 注册成功后跳转到首页
        }
      } finally {
        this.isRegistering = false;
      }
    },
    async userlogin() {

      const email = this.email;
      const password = this.password;
      if (!email) {
        this.$message.error("please input your email");
        return;
      }
      if (!password) {
        this.$message.error("please input your password");
        return;
      }

      this.isclick_login = true;
      const userdata = await this.$axios.post(
        "/api/emaillogin/",
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resdaata = userdata.data;
      this.isclick_login = false;
      if (resdaata.code == 200) {
        // cookie保存登录信息
        this.$cookies.set("subid", resdaata.subid, { expires: 30 });
        this.$cookies.set("username", resdaata.username, { expires: 30 });
        this.$cookies.set("email", resdaata.email, { expires: 30 });
        this.$cookies.set("userlogin", true, { expires: 30 });
        this.$router.push("/home");
      } else {
        this.$message.error("login failed");
      }
    },
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    generateUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    getOrCreateGuestId() {
      let guestId = localStorage.getItem("guestId");
      if (!guestId) {
        guestId = this.generateUUID();
        localStorage.setItem("guestId", guestId);
        console.log("新的游客ID已创建:", guestId);
      } else {
        console.log("已有游客ID:", guestId);
      }
      return guestId;
      // 你可以在这里将游客ID发送到你的服务器进行记录
      // 例如：this.sendGuestIdToServer(guestId);
    },
    initializeAppleLogin() {
      // 根据域名判断使用哪个 clientId
      const hostname = window.location.hostname;
      if (hostname.includes('online')) {
        this.appleClientId = 'online.easynoteai.applelogin';
      } else if (hostname.includes('com')) {
        this.appleClientId = 'com.easynoteai.applelogin';
      } else {
        // 本地开发环境
        this.appleClientId = 'online.easynoteai.applelogin';
      }

      const appleScript = document.createElement("script");
      appleScript.type = "text/javascript";
      appleScript.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";

      appleScript.onload = () => {
        if (window.AppleID) {
          window.AppleID.auth.init({
            clientId: this.appleClientId,
            scope: 'name email',
            redirectURI: window.location.protocol + '//' + window.location.host + '/api/applecallback/',
            state: 'origin:web',
            usePopup: true
          });

          // 添加事件监听器
          document.addEventListener('AppleIDSignInOnSuccess', this.handleAppleSignIn);
          document.addEventListener('AppleIDSignInOnFailure', this.handleAppleSignInError);
        }
      };

      document.head.appendChild(appleScript);
    },
    async handleAppleSignIn(event) {
      console.log('Apple Sign In Response:', event);
      if (event.detail && event.detail.authorization) {
        const { authorization } = event.detail;

        try {
          // 发送到后端验证
          const response = await this.$axios.post('/api/applecallback/', {
            code: authorization.code,
            id_token: authorization.id_token
          });

          if (response.data.code === 200) {
            // 设置用户信息
            this.$cookies.set("subid", response.data.subid, { expires: 30 });
            this.$cookies.set("username", response.data.username, { expires: 30 });
            this.$cookies.set("email", response.data.email, { expires: 30 });
            this.$cookies.set("userlogin", true, { expires: 30 });

            // 提示成功
            this.$message.success('Login successful');

            // 跳转到首页
            this.$router.push("/home");
          } else {
            throw new Error(response.data.messages || 'Login failed');
          }
        } catch (error) {
          console.error('Apple login error:', error);
          this.$message.error(error.message || 'Login failed. Please try again.');
        }
      }
    },
    handleAppleSignInError(error) {
      console.error('Apple Sign In Error:', error);
      // this.$message.error('Apple sign in failed');
    },
    getEnvironment() {
      const hostname = window.location.hostname;
      if (hostname.includes('online')) {
        return 'online';
      } else if (hostname.includes('com')) {
        return 'com';
      }
      return 'development';
    },
  },
};
</script>

<style lang="less">
.modal-login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  .banner {

    position: absolute;
    top: 4vw;
    width: 100vw;
    padding: 0;
    left: 0;
    z-index: 0;
    opacity: 0.7;
  }

  .loginbox {

    background-color: rgb(14 16 20);

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .colselogin {
      position: absolute;
      right: 25px;
      top: 20px;
      font-size: 30px;
      cursor: pointer;
    }

    .modal-content-login {
      position: relative;
      z-index: 10;
      background-color: rgba(255, 255, 255, 0.1);
      box-shadow: 0px 0px 2px #eee;
      border-radius: 15px;
      padding: 5px 20px;
      color: white;
      width: 350px !important;
    }
  }


  h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 30px;
  }

  p {
    color: #b5bac1;
    margin-bottom: 20px;
  }

  #g_id_onload {
    display: flex !important;
    justify-content: center !important;
  }

  .googlebuttonbox {
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .divider {
    text-align: center;
    margin: 28px 0;
    height: 0px;
    color: #b5bac1;
    border: 1px solid #65798d;
    position: relative;

    span {
      position: absolute;
      top: -10px;
      padding: 0px 10px;
      left: 50%;
      translate: -50%;
      background-color: #2d2d37;
    }
  }

  .input-group {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }

  input {
    width: calc(100% - 24px);
    padding: 12px;
    border: 1px solid #65798d;
    background-color: rgb(16 21 26);
    border-radius: 8px;
    color: white;
    margin-bottom: 10px;
  }

  .create-account {
    width: 100%;
    padding: 15px 10px;
    background-color: #8845fc;

    border: none;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    margin-bottom: 20px;
    margin-top: 20px;

    .el-loading-spinner {
      margin-top: -10px !important;
    }
  }

  a {
    color: #9232ff;
    text-decoration: none;
  }

  .terms {
    font-size: 12px;
    text-align: center;
  }

  .apple-auth-button {
    margin-top: 15px;
    width: 350px;
    height: 45px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  #appleid-signin {
    width: 350px !important;
    height: 40px !important;
    border-radius: 20px !important;
    overflow: hidden;
    margin: 0 auto;
  }

  // 确保 Apple 按钮样式正确显示
  .apple-auth-button iframe {
    width: 100% !important;
    height: 100% !important;
    border-radius: 20px !important;
  }
}
</style>
