<template>
    <div class="lawful-interception-guide">

        <!-- <div ref="contentToDownload" class="markdown-content" v-if="notes.mk_notes" v-html="renderedNotes"></div> -->
        <div :class="{ markmapbox: true }">
            <div class="download-btn">
                <el-tooltip 
                    content="Download Mindmap" 
                    placement="left-end" 
                    effect="light"
                    :popper-class="'custom-tooltip'"
                >
                    <i @click="downloadMindmap(down_mk_notes)" 
                       class="el-icon-download"
                       :class="{ 'is-loading': isDownloading }"
                       :style="{ cursor: isDownloading ? 'not-allowed' : 'pointer' }"></i>
                </el-tooltip>
            </div>
            <svg id="markmapsvg"></svg>
        </div>
    </div>
</template>

<script>
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import { Transformer } from "markmap-lib";
import { Markmap } from "markmap-view";
import html2canvas from 'html2canvas';
// import { saveAs } from 'file-saver';
// import { jsPDF } from 'jspdf'
export default {
    props: {
        noteobj: {
            type: Object,
            default: () => ({}),
        },
    },
    watch: {
        noteobj: {
            handler(newobject) {
                this.iscreating = this.noteobj.status;
                this.notes["iscreating"] = newobject.status;
                this.notes["taskcount"] = newobject.task_count;
                // this.notes["iscreating"] = 0;
                this.notes["mk_notes"] = newobject.note_text;
    
                if (newobject.filetype == 'record' && newobject?.live_transcript?.part_objs) {
                    this.transcript = newobject.live_transcript;
                    let mknotes = ''
                    this.transcript?.part_objs.forEach((item) => {
                        item['mk_notes'] = this.md.render(item.notes);
                        mknotes = mknotes + item.notes
                    });
                    this.daoduTomarkmap(mknotes);
                } else {
                    this.daoduTomarkmap(newobject.note_text);
                }
            },
            deep: true, // 添加深度监听
        },
    },
    computed: {
        renderedNotes() {
            if (!this.notes || typeof this.notes.mk_notes !== "string") {
                return ""; // 或者返回一个默认消息，如 "No notes available"
            }
            return this.md.render(this.notes.mk_notes);
        },
    },
    data() {
        return {

            md: new MarkdownIt({
                html: true,
                linkify: true,
                typographer: true,
                highlight: function (str, lang) {
                    if (lang && hljs.getLanguage(lang)) {
                        try {
                            return hljs.highlight(lang, str).value;
                        } catch (__) {
                            console.log("error", str, lang);
                        }
                    }
                    return ""; // 使用默认的转义
                },
            }),
            notes: {
                mk_notes: "",
                iscreating: 0,
            },
            transcript: {},
            mm: null,
            naotuloading: false,
            down_mk_notes: '',
            isDownloading: false,
        };
    },
    mounted() {
        this.notes["iscreating"] = this.noteobj.status;
        this.notes["mk_notes"] = this.noteobj.note_text;
        this.notes["type"] = this.noteobj.progress;
        this.notes["taskcount"] = this.noteobj.task_count;

        if (this.noteobj.filetype == 'record' && this.noteobj?.live_transcript?.part_objs) {
            this.transcript = this.noteobj.live_transcript;
            let mknotes = ''
            this.transcript?.part_objs.forEach((item) => {
                item['mk_notes'] = this.md.render(item.notes);
                mknotes = mknotes + item.notes
            });
            this.daoduTomarkmap(mknotes);
        } else {
            this.daoduTomarkmap(this.notes.mk_notes);
        }
    },
    methods: {

        async daoduTomarkmap(markobj) {
            
            if (!markobj) {
                return;
            }
            if(this.down_mk_notes){
                return;
            }
            this.down_mk_notes = markobj
            this.naotuloading = true;
            await this.rendernaotu(markobj); // 等待渲染完成

        },
        async rendernaotu(markstr) {
            if (this.mm) {
                this.mm.destroy();
                const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                svg.setAttribute("id", "markmapsvg");
                const markmapbox = document.querySelector(".markmapbox");
                markmapbox.innerHTML = "";
                markmapbox.appendChild(svg);
            }

            const transformer = new Transformer();
            const { root } = await transformer.transform(markstr);
            const markmapsvg = await this.getHTMLAsPromise("markmapsvg");

            this.mm = await Markmap.create(markmapsvg, {
                zoom: true,
                pan: true,
            });

            this.mm.setData(root);
            this.mm.fit(); // 自动适应视图

            this.naotuloading = false;
        },
        getHTMLAsPromise(nameid) {
            return new Promise((resolve, reject) => {
                this.$nextTick(() => {
                    const svgElement = document.getElementById(nameid);
                    if (svgElement) {
                        resolve(svgElement);
                    } else {
                        reject(new Error("SVG element not found"));
                    }
                });
            });
        },
        async downloadMindmap(download_mk_notes) {
            if (this.isDownloading) return;
            
            this.isDownloading = true;
            try {
                // 估算内容大小
                const contentSize = this.estimateContentSize(download_mk_notes);
                
                // 创建临时容器
                const tempContainer = document.createElement('div');
                tempContainer.style.position = 'absolute';
                tempContainer.style.left = '-9999px';
                tempContainer.style.width = `${contentSize.width}px`;
                tempContainer.style.height = `${contentSize.height}px`;

                // 创建新的SVG元素
                const tempSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                tempSvg.setAttribute('id', 'temp-markmap-svg');
                tempSvg.setAttribute('width', '100%');
                tempSvg.setAttribute('height', '100%');
                tempSvg.style.backgroundColor = 'white';

                tempContainer.appendChild(tempSvg);
                document.body.appendChild(tempContainer);

                try {
                    const transformer = new Transformer();
                    const { root } = await transformer.transform(download_mk_notes);

                    const mm = Markmap.create(tempSvg, {
                        zoom: false,
                        pan: false,
                        maxWidth: Math.min(contentSize.width - 40, 3000), // 考虑边距
                        paddingX: 20,
                        duration: 0,
                        autoFit: true,
                        style: `
                        .markmap-node {
                            cursor: pointer;
                        }
                        .markmap-node-circle {
                            fill: #fff;
                            stroke-width: 1.5px;
                        }
                        .markmap-node-text {
                            fill: #333;
                            font-size: 16px;
                        }
                        .markmap-link {
                            stroke: #999;
                            stroke-width: 1.5px;
                        }
                        `
                    });

                    mm.setData(root);
                    mm.fit();

                    await new Promise(resolve => setTimeout(resolve, 300)); // 增加等待时间确保渲染完成

                    const canvas = await html2canvas(tempContainer, {
                        backgroundColor: '#ffffff',
                        scale: 2,
                        logging: false,
                        width: contentSize.width,
                        height: contentSize.height,
                        allowTaint: true,
                        useCORS: true,
                    });

                    const imgData = canvas.toDataURL('image/png', 1.0);
                    const link = document.createElement('a');
                    link.download = 'mindmap.png';
                    link.href = imgData;
                    link.click();
                } finally {
                    document.body.removeChild(tempContainer);
                }
            } catch (error) {
                console.error('下载脑图时发生错误:', error);
                this.$message.error('下载失败，请重试');
            } finally {
                this.isDownloading = false;
            }
        },
        // 添加新方法用于估算内容大小
        estimateContentSize(content) {
            // 计算内容的复杂度

            console.log('content',content)
            const lines = content.split('\n').filter(line => line.trim());
            const maxLineLength = Math.max(...lines.map(line => line.length));
            const headingCount = lines.filter(line => line.startsWith('-')).length;
            
            // 基础尺寸
            let baseWidth = 1200;
            let baseHeight = 800;
            
            console.log('maxLineLength',maxLineLength)
            // 根据内容复杂度调整尺寸
            const width = Math.max(
                baseWidth,
                Math.min(maxLineLength * 12, 3000) // 每个字符约20px，最大4000px
            );
            console.log('lines.length',lines.length)
            console.log('headingCount',headingCount)
            
            const height = Math.max(
                baseHeight,
                Math.min(lines.length * 12, 8000) // 每行约40px，标题多加60px，最大6000px
            );
            
            return {
                width: Math.round(width),
                height: Math.round(height)
            };
        }
    },
};
</script>

<style lang="less" scoped>
.lawful-interception-guide {
    color: #ffffff;
    font-family: Arial, sans-serif;

    .markmapbox {
        height: 82vh;
        padding: 0 2vw;
        overflow: visible;

        .download-btn {
            position: absolute;
            top: 5px;
            right: 10px;

            i {
                background-color: #fff;
                /* 默认蓝色 */
                color: #1976D2;
                /* 白色文字 */
                border: 1px solid #1976D2;
                box-shadow: 0 0 2px #1976D2;
                /* 边框颜色 */
                padding: 4px 7px;
                /* 内边距，增加按钮可点击区域 */
                font-size: 26px;
                /* 字体大小 */
                font-weight: bold;
                /* 加粗文字 */
                border-radius: 14px;
                /* 圆角按钮 */
                cursor: pointer;
                /* 鼠标指针 */
                transition: background-color 0.3s, transform 0.1s ease-in-out;

                /* 平滑过渡 */
                &:hover {
                    color: #fff;
                    background-color: #1976D2; /* 悬停时背景颜色 */
                }

                &.is-loading {
                    background-color: #ccc;
                    opacity: 0.6;
                    animation: rotating 2s linear infinite;
                }
            }
        }

        #markmapsvg {
            height: 99%;
            width: 100%;
            border-radius: 5px;
            background-color: #f1f1f5;

            g {
                transform-origin: center center;
            }

            .markmap-node {
                cursor: pointer;

                text {
                    fill: #333;
                    font-size: 14px;
                }

                line {
                    stroke: #999;
                    stroke-width: 1.5px;
                }
            }
        }
    }

    .download-btn {
        padding: 10px 2vw;

        button {
            padding: 8px 16px;
            background-color: #4CAF50;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background-color: #45a049;
            }
        }
    }
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>

<style lang="less">
.custom-tooltip {
    padding: 8px 12px !important;
    font-size: 14px !important;
    color: #1976D2 !important;
    border: 1px solid #1976D2 !important;
    background-color: #fff !important;
    box-shadow: 0 2px 8px rgba(25, 118, 210, 0.1) !important;
    border-radius: 8px !important;

    .popper__arrow {
        border-bottom-color: #1976D2 !important;
        &::after {
            border-bottom-color: #fff !important;
        }
    }
}
</style>