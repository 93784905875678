<template>
    <div class="d_home">
        <div class="bodydiv">
            <aside>
                <div class="logotitle" @click="backindex()">
                    <img :src="home_logo" alt="" />
                </div>
                <ul>
                    <li :class="{ liactiveis: asidekey == 'dhome' }" @click="gopageurl('dhome')">
                        <img :src="dashboard" alt="" />
                        Home
                    </li>
                    <li :class="{ liactiveis: asidekey == 'createchat' }" @click="gopageurl('createchat')">
                        <img :src="dashboard" alt="" />
                        Create New
                    </li>
                    <li :class="{ liactiveis: asidekey == 'history' }" @click="gopageurl('history')">
                        <img :src="dashboard" alt="" />
                        History
                    </li>
                    <li v-if="false" :class="{ liactiveis: asidekey == 'd_howtouse' }" @click="gopageurl('d_howtouse')">
                        <img :src="howtouse" alt="" />
                        How to use
                    </li>
                </ul>
                <div v-if="false" class="gopaypage" @click="gopaypage">
                    ✨ Upgrade to Premium
                </div>
                <div class="acount" @mouseenter="showpaybtn = true" @mouseleave="showpaybtn = false"
                    @click="gopageurl('acount')">
                    <div class="plan-bubble">
                        <div class="bubble-row" @click="gopageurl('settings')">
                            <span>Your Plan:</span>
                            <span>{{ planname }}</span>
                        </div>
                    </div>
                    <div class="signin">
                        <img :src="userimg" alt="" />
                        <span>{{ email }}</span>
                    </div>
                </div>
            </aside>
            <main>
                <DhomePage v-if="asidekey == 'dhome'" @selectOption="selectOptionForchat"
                    @tryExample="tryExampleForchat" />
                <CreateChatPage v-if="asidekey == 'createchat'" ref="createChatPage" />
                <HistoryPage v-if="asidekey == 'history'" />
                <DHowToUsePage v-if="asidekey == 'd_howtouse'" />
            </main>
        </div>
        <popupdeleteaccount v-if="isdeletepopup" />
    </div>
</template>

<script>
import DhomePage from '@/views/deepresearch/pages/Dhome.vue'
import CreateChatPage from '@/views/deepresearch/pages/CreateChat.vue'
import HistoryPage from '@/views/deepresearch/pages/History.vue'
import DHowToUsePage from '@/views/deepresearch/pages/DHowToUse.vue'

export default {
    components: {
        DhomePage,
        CreateChatPage,
        HistoryPage,
        DHowToUsePage
    },
    name: "d_homePage",
    data() {
        return {
            dashboard: require("@/assets/images/home/dashboard.png"),
            howtouse: require("@/assets/images/home/supports.png"),
            supports: require("@/assets/images/home/supports.png"),
            settingicon: require("@/assets/images/home/setting.png"),
            discordlogo: require("@/assets/images/home/discordlogo.png"),

            // vipicon: require("../assets/images/home/vip_gif1.gif"),

            userimg: require("@/assets/images/home/userimg.png"),
            asidekey: "dhome",
            home_logo: require("@/assets/images/index/logo.png"),
            showpaybtn: false,
            email: "",

            planname: "--",

            isdeletepopup: false,
        };
    },
    mounted() {
        // cookies中获取登录信息。
        if (this.$route.params && this.$route.params.pagekey) {
            this.chooseaside(this.$route.params.pagekey);
        } else {
            this.chooseaside("dhome");
        }
        this.email = this.$cookies.get("email");
        this.getminfo();
    },
    created() {
        // 添加事件监听
        // window.addEventListener('beforeunload', this.handleBeforeUnload);
        // window.addEventListener('popstate', this.handleBeforeUnload);
    },
    destroyed() {
        // 移除事件监听
        // window.removeEventListener('beforeunload', this.handleBeforeUnload);
        // window.removeEventListener('popstate', this.handleBeforeUnload);
    },
    watch: {
        '$route'(to,) {
            // 检查路由参数的变化
            const pagekey = to.params.pagekey;
            if (pagekey) {
                // 根据 URL 中的 pagekey 展示相应组件
                this.chooseaside(pagekey);
            } else {
                this.chooseaside("dhome");
            }
        },
    },
    methods: {
        tryExampleForchat(example) {
            console.log('tryExampleForchat', example)
            this.$router.push({
                name: "deepresearch_key", // 目标路由的名称 
                params: { pagekey: 'createchat' }, // 通过 params 传递参数
            });
            this.$nextTick(() => {
                this.$refs.createChatPage.tryExample(example)
            });
        },
        selectOptionForchat(option) {
            console.log('selectOptionForchat', option)
            // 跳转到createchat，并执行selectOption
            this.$router.push({
                name: "deepresearch_key", // 目标路由的名称 
                params: { pagekey: 'createchat' }, // 通过 params 传递参数
            });
            this.$nextTick(() => {
                this.$refs.createChatPage.selectOption(option);
            });
        },

        async getminfo() {
            try {
                const subid = this.$cookies.get("subid");
                const response = await this.$axios.post("/api/getminfo/", {
                    subid: subid,
                });
                const resdata = response.data;
                if (resdata.code == 200) {
                    this.planname = resdata.minfo;

                }
            } catch (error) {
                console.error("Error:", error);
            }
        },
        handleBeforeUnload(event) {
            const ischilddeep = this.$cookies.get("ischilddeep", '');
            if (ischilddeep == 'islive') {
                const message = 'You have unsaved changes. Do you really want to leave?';
                this.$cookies.set('ischilddeep', '')
                event.returnValue = message; // 标准
                return message; // 旧版
            }
        },

        backindex() {
            this.$router.push({ name: "IndexPage" });
        },
        async testapi() {
            // 测试axios
            try {
                console.log(
                    "Request URL:",
                    this.$axios.defaults.baseURL + "/api/hello"
                ); // 打印URL
                const res = await this.$axios.get("/api/hello");
                console.log(res.data);
            } catch (error) {
                console.log(error);
            }
        },
        gopaypage() {
            this.$router.push({
                name: "IndexPage", // 目标路由的名称
                params: { pagekey: "price" }, // 通过 params 传递参数
                // query: { category: "electronics" }, // 通过 query 传递参数
            });
        },
        gopageurl(key) {
            if (key == "acount") {
                key = "settings";
                this.$router.push({
                    name: "Homeindex_key", // 目标路由的名称
                    params: { pagekey: key }, // 通过 params 传递参数
                    // query: { category: "electronics" }, // 通过 query 传递参数
                });
                return;
            }
            this.$router.push({
                name: "deepresearch_key", // 目标路由的名称
                params: { pagekey: key }, // 通过 params 传递参数
                // query: { category: "electronics" }, // 通过 query 传递参数
            });
        },
        chooseaside(key) {
            this.asidekey = key;
        },
        JoinDiscord() {
            window.open("https://discord.gg/ffkFvpVBjt");
        },
    },
    // beforeRouteLeave(to, from, next) {
    //   拦截路由返回（后退按钮）时的离开
    //   const ischilddeep = this.$cookies.get("ischilddeep",''); 
    //   if (ischilddeep == 'islive') {
    //     // 提示用户是否保存数据
    //     const answer = window.confirm('You have unsaved data, are you sure you want to leave?');
    //     this.$cookies.set('ischilddeep','')
    //     if (answer) {
    //       next(); // 用户确认离开，继续路由跳转
    //     } else {
    //       next(false); // 用户取消，阻止路由跳转
    //     }
    //   } else {
    //     next(); // 没有修改数据，继续路由跳转
    //   }
    // },
};
</script>

<style lang="less" scoped>
@import "@/assets/styles/home.less";

.acount {
    position: relative;

    .plan-bubble {
        position: absolute;
        bottom: 100%;
        left: -10px;
        background: rgba(47, 45, 58, 0.95);
        border-radius: 10px;
        padding: 15px 20px;
        min-width: 150px;
        transition: all 0.3s ease;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
        width: 100%;

        // 添加小三角
        &:after {
            content: '';
            position: absolute;
            bottom: -8px;
            left: 50%;
            transform: translateX(-50%);
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid rgba(47, 45, 58, 0.95);
        }

        .bubble-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            font-size: 13px;

            span:first-child {
                color: #9ca3af;
            }
        }

        .bubble-divider {
            height: 1px;
            background: rgba(255, 255, 255, 0.1);
            margin: 12px 0;
        }
    }
}
</style>