<template>
  <div class="note-transcript">
    <h1>Transcript</h1>
    <div ref="contentToDownload" class="transcripts">
      <p v-if="transcript.type != 'record'">
        {{ transcript.value }}
      </p>
      <div class="alltextbox" ref="alltextbox" v-if="transcript.type == 'record'">
        <div v-if="transcript.value?.text_objs">
          <div class="left_text">
            <div class="textbox" v-for="(textobj, i) in transcript.value?.part_objs" :key="i">
              <h5>{{ textobj.time }}</h5>
              <div class="yuantext" v-html="textobj.notes"></div>
              <!-- <div class="translatetext"> {{ textobj.trans }}</div> -->
            </div>
          </div>
          <div class="left_text">
            <div class="textbox" v-for="(textobj, i) in transcript.value?.text_objs" :key="i">
              <h5>{{ textobj.time }}</h5>
              <div class="yuantext">{{ textobj.text }}</div>
              <div class="translatetext"> {{ textobj.trans }}</div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="textbox" v-for="(textobj, i) in transcript.value" :key="i">
              <h5>{{ textobj.time }}</h5>
              <div class="yuantext">{{ textobj.text }}</div>
              <div class="translatetext"> {{ textobj.trans }}</div>
            </div>
        </div>
      </div>
    </div>

    <div>
      <!-- <el-button @click="downloadNotes" class="el-icon-upload2 export">Export</el-button> -->
      <el-dropdown class="export" @command="handleCommand">
        <span class="el-dropdown-link">
          Copy as<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="copytext" icon="el-icon-tickets">Copy as TXT</el-dropdown-item>
          <el-dropdown-item command="downloadaspdf" icon="el-icon-download">Download as
            PDF</el-dropdown-item>
          <el-dropdown-item command="noteshare" icon="el-icon-share">Share my Notes</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
export default {
  props: {
    transcript: {
      type: Object,
      default: () => ({
        // 这里填入默认对象的属性
      }),
    },
  },
  name: "NoteTranscript",
  data() {
    return {
      noteTitle: "Lawful Interception in Telecommunications Networks Overview",
      isPublic: true,
      dateUploaded: "2024-07-12",
      classSubject: "Computer Science",

      md: new MarkdownIt({
        html: true,
        linkify: true,
        typographer: true,
        highlight: function (str, lang) {
          if (lang && hljs.getLanguage(lang)) {
            try {
              return hljs.highlight(lang, str).value;
            } catch (__) {
              console.log("error", str, lang);
            }
          }
          return ""; // 使用默认的转义
        },
      }),
    };
  },
  created() {
    if (this.transcript.type == "record") {
      this.markdowntext()
    }
  },
  methods: {
    markdowntext() {
      this.$nextTick(() => {
        this.transcript.value?.part_objs.forEach((item) => {
          item.notes = this.md.render(item.notes);
        });
      });
    },
    deleteNotes() {
      // 实现删除笔记的逻辑
      console.log("Deleting notes...", this.transcript);
    },
    handleCommand(command) {
      switch (command) {
        case "copymarkdown":
          this.copymarkdown();
          break;
        case "copytext":
          this.copytext();
          break;
        case "downloadaspdf":
          this.downloadaspdf();
          break;
        case "noteshare":
          this.noteshare();
          break;
        default:
          break;
      }
    },
    copymarkdown() {
      console.log("copymarkdown", this.notes);
      const markdownContent = this.notes.mk_notes;
      this.copyToClipboard(markdownContent);
      this.$message({ message: "Copied to clipboard", type: "success" });
    },
    copytext() {
      if (this.transcript.type != "record") {
        const textContent_a = this.transcript.value;
        this.copyToClipboard(textContent_a);
        this.$message({ message: "Copied to clipboard", type: "success" });
      } else {

        const textContent_r = this.formattedText(this.transcript.value)
        this.copyToClipboard(textContent_r);
        this.$message({ message: "Copied to clipboard", type: "success" });
      }

    },
    formattedText(data) {
      return data
        .map(item => `${item.time} ${item.text}`)
        .join('\n');
    },
    downloadaspdf() {
      // this.generateA4PaginatedPdf();
      this.printPdf();
    },
    async printPdf() {
      try {
        // 获取打印容器
        const printContainer = this.$refs.contentToDownload;
        if (!printContainer || !printContainer.innerHTML.trim()) {
          throw new Error('No content to print');
        }

        // 创建一个隐藏的 iframe
        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.top = '-9999px';
        iframe.style.left = '-9999px';
        document.body.appendChild(iframe);

        // 将打印容器的内容复制到 iframe 中
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        iframeDocument.open();
        iframeDocument.write(`
      <html>
        <head>
          <title>打印内容</title>
          <style>
            /* 添加你需要的样式 */
            body {
              font-family: Arial, sans-serif;
            }
            .print-container {
              padding: 20px;
            }
            ${this.getAdditionalStyles()} /* 获取额外需要的样式 */
          </style>
        </head>
        <body>
          ${printContainer.innerHTML}
        </body>
      </html>
    `);
        iframeDocument.close();

        // 等待 iframe 加载完成
        await new Promise((resolve) => {
          iframe.onload = resolve;
        });

        // 等待一小段时间，确保所有内容都已渲染
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // 调用浏览器的打印功能
        iframe.contentWindow.print();

        // 移除 iframe
        document.body.removeChild(iframe);
      } catch (error) {
        console.error('PDF打印失败:', error);
        alert('PDF打印失败，请稍后重试或联系支持人员。');
      }
    },
    noteshare() {
      console.log("noteshare", this.transcript);
      const shareurl = 'https://www.easynoteai.com/share?t=' + this.transcript.notetoken;
      this.copyToClipboard(shareurl);
      this.$message({ message: "Copied to clipboard", type: "success" });
    },
    copyToClipboard(text) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(text).then(() => {
          console.log('Text copied to clipboard');
        }).catch((err) => {
          console.error('Could not copy text: ', err);
        });
      } else {
        // 使用旧的方法复制到剪贴板
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
          console.log('Text copied to clipboard');
        } catch (err) {
          console.error('Could not copy text: ', err);
        }
        document.body.removeChild(textArea);
      }
    },
    // 获取额外需要的样式
    getAdditionalStyles() {
      // 这里可以返回任何额外需要的CSS
      return `
    /* 额外的CSS样式 */
    .custom-class {
      color: #333;
    }
  `;
    },

  },
};
</script>
<style lang="less" scoped>
.note-transcript {
  color: #ffffff;
  padding: 0 2vw;
  font-family: Arial, sans-serif;
  position: relative;
  .export {
    position: absolute;
    right: 2.4vw;
    top: 0px;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    border: none;
    font-size: 1vw;
    padding: 0.4vw 1vw;
    cursor: pointer;
    border-radius: 0.5vw;
    transition: all 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }

  h1 {
    font-size: 36px;
    margin: 0;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    i {
      margin-right: 10px;
      font-size: 44px;
    }
  }

  .transcripts {
    P {
      line-height: 28px;
      font-size: 16px;
    }
  }

  .alltextbox {

    padding: 0.5vw;
    border-radius: 5px;
    display: flex;

    .left_text {
      flex: 1;
      margin-right: 10px;
      background-color: rgba(255, 255, 255, 0.1);
      height: calc(100vh - 260px);
      overflow: auto;
      padding: 10px;
    }

    .textbox {
      font-size: 16px;

      h5 {
        margin: 0;
        text-align: center;
      }

      .yuantext {
        font-family: 'Arial', sans-serif;
        line-height: 1.6;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }

      .translatetext {
        margin-bottom: 0.8vw;
      }
    }
  }
}
</style>
