<template>
  <div class="faq-page">
    <div class="faq-container">
      <h1>{{ $t('faqItemstitle22') }}</h1>

      <div class="faq-list">
        <div v-for="(item, index) in $t('faqItems')" :key="index" class="faq-item">
          <h2>{{ item.question }}</h2>
          <div class="answer" v-html="formatAnswer(item.answer)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecordNotesPage',
  data() {
    return {
      faqItems: [
        {
          question: "Record live lecture?",
          answer: "Record, transcribe, and translate your notes in real-time. This incredible feature allows you to listen to lectures while simultaneously generating notes in your preferred language. Free up your hands and never miss a key point again."
        },
        {
          question: "Is my language supported?",
          answer: "We provide services in English, French, Spanish, German, Italian, Russian, Japanese, Arabic, Hindi, Portuguese, Dutch, Korean, Turkish, Simplified Chinese, and Traditional Chinese. We're continually expanding our language offerings."
        },
        {
          question: "Why am I unable to download my audio file?",
          answer: "If the recording is around 2 hours, older PCs or tablets may automatically delete the recording due to insufficient memory and browser protection mechanisms. It's recommended to close unnecessary tabs and programs before recording, and to use Chrome for optimal performance."
        },
        {
          question: "I am having trouble uploading my audio?",
          answer: "Please confirm that your file meets these requirements:<br>- File size is less than 140MB<br>- File format is MP3, AIFF, WAV, FLAC, AAC, OGG, M4A, or ALAC."
        },
        {
          question: "Why did EasyNoteAI only record part of my audio?",
          answer: "As a web-based tool, EasyNoteAI can occasionally experience issues due to network conditions or system factors. We're working on a mobile app that will provide a more reliable and efficient recording experience."
        },
        {
          question: "I am having issues with YouTube videos?",
          answer: "Please note that EasyNoteAI currently does not support YouTube Shorts, Live streams, private videos, or videos longer than 3 hours. If you are still experiencing issues, please contact our support team via email or the message board."
        },
        {
          question: "Can I use EasyNoteAI on my mobile phone?",
          answer: "While we recommend using a computer for now, our mobile app is in development and will be available soon."
        },
        {
          question: "How to download a mind map from notes?",
          answer: "Copy the note as markdown, paste it into <a href='https://markmap.js.org/repl' target='_blank' style='color: #fff; text-decoration: underline; font-weight: bold;'>https://markmap.js.org/repl</a>                            , and you're ready to edit and save the mind map."
        },
        {
          question: "Why can't I download the PDF?",
          answer: "If you cannot download the PDF now, please try the following:<br>1. Clear your browser cache and cookies.<br>2. Try incognito mode.<br>3. Disable all browser extensions.<br>4. Update your browser to the latest version.<br>5. Try using a different browser like Chrome."
        }
      ]
    }
  },
  methods: {
    formatAnswer(answer) {
      return answer.replace(/\n/g, '<br>')
    }
  }
}
</script>

<style scoped>
.faq-page {
  min-height: 100vh;
  /* background-color: #f8f9fa; */
  padding: 0;
}

.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
  /* background: white; */
  border-radius: 16px;
  /* box-shadow: 0 4px 20px rgba(255, 231, 231, 0.08); */
  color: rgba(255, 255, 255, 0.9);
}

h1 {
  /* color: #2d3436; */
  margin-bottom: 40px;
  text-align: left;
  font-size: 36px;
  font-weight: 600;
  position: relative;
  color: rgba(255, 255, 255, 0.9);
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.faq-item {
  margin-bottom: 0;

  padding: 20px 32px 40px 32px;
  border-radius: 12px;
  /* background: #f8f9fa; */
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  /* border-left: 4px solid transparent; */
}

.faq-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
  pointer-events: none;
}

.language-support {
  /* border-left-color: #4a90e2; */
}

.contact-support {
  /* border-left-color: #00b894; */
}

.faq-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(248, 233, 255, 0.05);
}

h2 {
  color: rgba(255, 255, 255, 1);
  /* opacity: 0.8; */
  /* margin-bottom: 20px; */
  font-size: 26px;
  /* font-weight: 600; */
  line-height: 0.5;
  display: flex;
  align-items: center;
  gap: 10px;
  /* background: red; */
}

.answer {
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.5;

  a {
    /* color: #cbe4ff; */
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    transition: all 0.2s ease;
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  }

  a:hover {
    color: #cc85ff;
    border-bottom-style: solid;
    border-bottom: 1px solid #cc85ff;
    ;
  }

}


h2 i {
  font-size: 24px;
  /* opacity: 0.8; */
}

.language-support h2 i {
  color: rgba(255, 255, 255, 0.8);
}

.contact-support h2 i {
  color: rgba(255, 255, 255, 0.8);
}

p {
  /* color: #636e72; */
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.8;
  font-size: 14px;
  margin: 0;

  text-align: left;
  padding-left: 34px;

}

ol {
  padding-left: 50px;
  margin: 0;
  list-style-position: outside;
}

li {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 15px;
  line-height: 0.8;
  font-size: 14px;
  padding-left: 10px;
  text-align: left;
}

li:last-child {
  margin-bottom: 0;
}

strong {
  /* color: #f5fdff; */
  color: rgba(255, 255, 255, 0.8);
  /* font-weight: 600; */
  margin-right: 8px;
}

a {
  /* color: #cbe4ff; */
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: all 0.2s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}

a:hover {
  color: #cc85ff;
  border-bottom-style: solid;
  border-bottom: 1px solid #cc85ff;
  ;
}

@media (max-width: 768px) {
  .faq-container {
    padding: 20px;
    margin: 0 15px;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 20px;
  }

  .faq-item {
    padding: 20px;
  }

  p,
  li {
    font-size: 15px;
  }
}

/* 添加动画效果 */
.faq-item {
  animation: fadeInUp 0.5s ease-out forwards;
  opacity: 0;
}

.faq-item:nth-child(1) {
  animation-delay: 0.1s;
}

.faq-item:nth-child(2) {
  animation-delay: 0.2s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>