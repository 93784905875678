<template>
    <div class="history-page background-color">

        <div class="history-body">
            <div class="history-header">
                <h1>History Conversation</h1>
                <div class="new-chat" @click="createNewChat">
                    <i class="el-icon-plus"></i>
                    New Conversation
                </div>
            </div>
            <div class="history-content">
                <div class="chat-list" v-loading="chatLoading" element-loading-text="加载中..."
                    element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.5)">
                    <div class="chat-item" v-for="(chat, index) in chatList" :key="index"
                        @mouseenter="showActions(chat)" @mouseleave="hideActions(chat)" @click="!chat.isEditing && goToChat(chat)">
                        <div class="chat-info">
                            <div class="chat-title">
                                <img :src="avatar" class="chat-avatar" alt="avatar" />
                                <template v-if="chat.isEditing">
                                    <el-input v-model="chat.editingTitle" size="small" @blur="saveTitle(chat)"
                                        @keyup.enter="saveTitle(chat)" ref="titleInput" v-focus
                                        :placeholder="chat.title" @click.stop></el-input>
                                </template>
                                <template v-else>
                                    <span class="title-text">{{ chat.title }}</span>
                                </template>
                            </div>
                            <div class="chat-time" v-if="!chat.showActions">{{ chat.time }}</div>
                            <div class="chat-actions" v-else>
                                <span @click.stop="editTitle(chat)">Edit Title</span>
                                <span @click.stop="deleteChat(chat)">Delete</span>
                            </div>
                        </div>
                        <div class="file-list" v-if="chat.files && chat.files.length">
                            <div class="file-item" v-for="(file, index) in chat.files" :key="index">
                                <div class="file-type-icon">
                                    <img v-if="file.type === 'DOCX'" :src="documentimg" alt="word" />
                                    <img v-else-if="file.type === 'PDF'" :src="documentimg" alt="pdf" />
                                </div>
                                <div class="file-info">
                                    <span class="file-name">{{ file.title }}</span>
                                    <div class="file-meta">
                                        <span v-if="file?.status === 'uploaded'">{{ file?.file_type }}</span>
                                        <span v-else>{{ file?.type }}</span>
                                        <span v-if="file?.status === 'uploaded'">{{ formatFileSize(file?.file_size) }}</span>
                                        <span v-else>{{ formatFileSize(file?.size) }}</span>
                                    </div>
                                </div>
                                <div class="file-actions-upload" v-if="file.status === 'uploaded'">
                                    <span class="upload-status"><i class="el-icon-upload"></i></span>
                                </div>
                                <div class="file-actions-edit" v-else>
                                    <!-- 编辑 -->
                                    <i class="el-icon-edit edit-icon"></i>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HistoryPage',
    directives: {
        focus: {
            inserted(el) {
                el.querySelector('input').focus()
            }
        }
    },
    data() {
        return {
            documentimg: require('@/assets/images/index/icons/3.png'),
            avatar: require('@/assets/images/home/userimg.png'),
            chatList: [],
            chatLoading: false,
        }
    },
    computed: {

    },
    mounted() {
        // 获取历史会话
        this.getHistoryChat()
    },
    methods: {
        goToChat(chat) {
            this.$router.push(`/deepresearch/createchat?chat_id=${chat.id}`);
        },
        initChatList(list) {
            // 初始化聊天列表时添加必要的编辑属性
            return list.map(chat => ({
                ...chat,
                isEditing: false,
                editingTitle: '',
            }))
        },
        formatFileSize(size) {
            // MB保留两位小数 
            if (size < 1024) {
                return size + 'B'
            } else if (size < 1024 * 1024) {
                return Math.round(size / 1024 * 100) / 100 + 'KB'
            } else {
                return Math.round(size / 1024 / 1024 * 100) / 100 + 'MB'

            }
        },
        createNewChat() {
            // 跳转带参数
            const params = new URLSearchParams({
                type: 'create',
            })
            this.$router.push(`/deepresearch/createchat?${params.toString()}`);
        },
        showActions(chat) {
            chat.showActions = true
        },
        hideActions(chat) {
            chat.showActions = false
        },
        editTitle(chat) {
            // 先关闭其他正在编辑的项
            this.chatList.forEach(item => {
                if (item.id !== chat.id) {
                    item.isEditing = false
                }
            })

            chat.editingTitle = chat.title
            chat.isEditing = true
        },
        async saveTitle(chat) {
            // 添加延时以确保blur事件正确触发
            setTimeout(() => {
                if (!chat.editingTitle?.trim()) {
                    chat.editingTitle = chat.title
                } else {
                    chat.title = chat.editingTitle.trim()
                }
                this.updateChatTitle(chat)
                chat.isEditing = false
            }, 100)
        },
        async deleteChat(chat) {
            this.chatList = this.chatList.filter(item => item.id !== chat.id)
            try {
                const subid = this.$cookies.get('subid', '')
                await this.$axios.post('/api/deletechat/', {
                    subid: subid,
                    chat_id: chat.id
                })
            } catch (error) {
                this.$message.error('删除失败')
            }
        },
        async updateChatTitle(chat) {
            // 更新聊天标题
            try {
                const subid = this.$cookies.get('subid', '')
                const res = await this.$axios.post('/api/updatechatname/', {
                    subid: subid,
                    chat_id: chat.id,
                    new_name: chat.title
                })
                if (res.data.code === 200) {
                    this.$message.success('更新成功')
                } else {
                    this.$message.error(res.data.message)
                }
            } catch (error) {
                this.$message.error('更新失败')
            }
        },
        async getHistoryChat() {
            this.chatLoading = true
            // 获取历史会话
            try {
                const subid = this.$cookies.get('subid', '')
                const res = await this.$axios.post('/api/getchatlist/', {
                    subid: subid
                })

                const data = res.data
                if (data.code === 200) {
                    this.chatList = this.initChatList(data.data)
                } else {
                    this.$message.error(data.message)
                }
            } catch (error) {
                this.$message.error('获取历史会话失败')
            } finally {
                this.chatLoading = false
            }
        }
    }
}
</script>

<style lang="less" scoped>
.history-page {
    background-color: #f5f5f5;
    width: 100%;

    .history-body {
        width: 100%;
        height: 100vh;
    }

    .history-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 13%;

        h1 {
            font-size: 24px;
            font-weight: 500;
            color: #333;
            margin: 0;
        }

        .new-chat {
            display: flex;
            align-items: center;
            gap: 5px;
            color: #ff6b6b;
            cursor: pointer;
            font-size: 14px;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .history-content {
        height: calc(100% - 100px);
        overflow-y: auto;
        padding: 0 13%;
    }

    .chat-list {
        min-height: 200px;

        .chat-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 16px;
            background: #fff;
            border-radius: 8px;
            margin-bottom: 12px;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
                background: #f6f9ff;
            }

            .chat-avatar {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-right: 12px;
            }

            .chat-info {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .chat-title {
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                    color: #333;
                    font-weight: 500;
                    width: calc(100% - 130px);

                    img {
                        width: 25px;
                        height: 25px;
                        margin-right: 10px;
                        background-color: #aaa;
                        padding: 5px;
                        border-radius: 50%;
                    }

                    .el-input {
                        width: 90%;
                        margin-right: 10px;
                        font-size: 15px;

                        :deep(.el-input__inner) {
                            padding: 0 10px;
                            height: 36px;
                            line-height: 32px;
                            border-radius: 4px;
                            border: 1px solid #dcdfe6;

                            &:focus {
                                border-color: #409eff;
                            }
                        }
                    }

                    .title-text {
                        display: inline-block;
                        min-width: 100px;
                        padding: 4px 0;
                    }
                }

                .chat-time {
                    font-size: 12px;
                    color: #999;
                }

                .chat-actions {
                    display: flex;
                    gap: 16px;
                    font-size: 13px;
                    color: #666;

                    span {
                        cursor: pointer;

                        &:hover {
                            color: #1890ff;
                        }
                    }
                }

            }

            .file-list {
                margin-top: 12px;
                margin-left: 2px;
                display: flex;
                flex-wrap: wrap;
                gap: 15px;

                .file-item {
                    display: flex;
                    align-items: center;
                    padding: 8px 45px 8px 10px;
                    background: #fff;
                    border: 1px solid #e8e8e8;
                    border-radius: 8px;
                    margin-bottom: 8px;
                    position: relative;
                    overflow: hidden;

                    .file-type-icon {
                        width: 34px;
                        height: 34px;
                        margin-right: 8px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .file-info {
                        flex: 1;
                        min-width: 0;

                        .file-name {
                            max-width: 150px;
                            display: block;
                            font-size: 14px;
                            color: #333;
                            margin-bottom: 4px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .file-meta {
                            display: flex;
                            gap: 8px;
                            font-size: 12px;
                            color: #999;

                            span {
                                position: relative;

                                &:not(:last-child):after {
                                    content: '·';
                                    position: absolute;
                                    right: -6px;
                                }
                            }
                        }
                    }

                    .file-actions-upload {
                        position: absolute;
                        right: -2px;
                        top: -3px;

                        .upload-status {
                            padding: 2px 5px;
                            font-size: 12px;
                            color: #52c41a;
                            background: #f6ffed;
                            border: 1px solid #b7eb8f;
                            border-radius: 4px;
                        }
                    }

                    .file-actions-edit {
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        transform: translateY(-50%);

                        .edit-icon {
                            font-size: 26px;
                            color: #999;
                            cursor: pointer;

                            &:hover {
                                color: #1890ff;
                            }
                        }
                    }
                }
            }


        }
    }
}
</style>