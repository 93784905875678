<template>
  <div class="how-to-use-page">
    <h1>How to Use</h1>
    <!-- 添加使用说明内容 -->
  </div>
</template>

<script>
export default {
  name: 'DHowToUsePage',
  data() {
    return {
      // 组件数据
    }
  },
  methods: {
    // 组件方法
  }
}
</script>

<style lang="less" scoped>
.how-to-use-page {
  padding: 20px;
}
</style> 