<template>
  <div class="turbolearn-page">
    <header>
      <div class="logo">
        <img @click="scrollToElement('turbolearn-page')" :src="index_logo" alt="Pen icon" />
      </div>
      <nav>
        <a href="/features" @click.prevent="scrollToElement('container')">{{ $t('index.features') }}</a>
        <a href="/price" @click.prevent="gopage('price')">{{ $t('index.pricing') }}</a>
        <a href="/about" @click.prevent="gopage('about')">{{ $t('index.about') }}</a>
        <a href="/faq" @click.prevent="gopage('faq')">{{ $t('index.faq') }}</a>

        <!-- <div class="faq-dropdown">
          <a>FAQ</a>
          <div class="dropdown-content">
            <a @click="openFaqPage('most-popular')">Most Popular</a>
            <a @click="openFaqPage('record-notes')">Record & Notes</a>
            <a @click="openFaqPage('subscription')">subscription</a>
          </div>
        </div> -->

      </nav>
      <!-- <p>{{ $t('route.dashboard') }}</p> -->

      <div class="header-right">

        <div v-if="!notlogin" @click="gohomepage()" class="dash-button">
          {{ $t('index.dashboard') }}
        </div>
        <div v-if="notlogin" @click="gologinpage()" class="dash-button">
          {{ $t('index.start') }}
        </div>

        <div class="dropdown-wrap">
          <img :src="language" alt="Language" @click="toggleDropdown" />
          <div v-if="showDropdown" class="dropdown-inner">
            <div class="dropdown-text" @click="changeLanguage('zh')">中文</div>
            <div class="dropdown-text" @click="changeLanguage('en')">English</div>
          </div>
        </div>
      </div>


    </header>
    <main v-if="showpage">
      <PricePage v-if="showpage == 'price'" />
      <temsservice v-if="showpage == 'TermsService'" />
      <PrivacyPolicy v-if="showpage == 'PrivacyPolicy'" />
      <AboutUs v-if="showpage == 'about'" />
      <FAQ v-if="showpage == 'faq'" />
    </main>
    <main v-if="showpage == ''">

      <div class="features">

        <div class="content">
          <h1>
            {{ $t('index.title') }}

            <!-- <span class="purple">Instant</span> notes, flashcards,
            <br />quizzes, and more. -->
          </h1>
          <p> {{ $t('index.subtitle') }}</p>
          <div class="startbox" @click="gologinpage()" v-if="notlogin">
            <div class="startbtn-button"> {{ $t('index.start2') }}</div>
          </div>
          <div class="startbox1" v-if="!notlogin">
            <div> {{ $t('index.start2') }}</div>
          </div>
        </div>
      </div>

      <div class="ripple-container">
        <div class="ripple-ring ripple-outer"></div>
        <div class="ripple-ring ripple-inner"></div>
      </div>


      <div class="particle-container">
        <div v-for="(particle, index) in particles" :key="index" class="particle" :style="{
          left: particle.x + 'px',
          top: particle.y + 'px',
          backgroundColor: particle.color,
          width: particle.size + 'px',
          height: particle.size + 'px',
          opacity: particle.opacity,
          animationDuration: particle.duration + 's'
        }"></div>
      </div>
      <div class="upload-box">

        <youtubeview />
      </div>
      <!-- <lunboimg :images="carouselImages" :autoPlayInterval="3000" /> -->
      <div class="colleges" v-if="true">
        <p>{{ $t('index.title2') }}</p>
        <div class="carousel-container">
          <div class="universities">
            <img :src="school_1" alt="Columbia" />
            <img :src="school_2" alt="HKUST" />
            <img :src="school_3" alt="Manchester" />
            <img :src="school_4" alt="PolyU" />
            <img :src="school_5" alt="Sydney" />
            <img :src="school_6" alt="Toronto" />
            <img :src="school_7" alt="UBC" />
            <img :src="school_8" alt="UCL" />
            <img :src="school_9" alt="UCLA" />
            <img :src="school_10" alt="Washington" />
            <img :src="school_1" alt="Columbia" />
            <img :src="school_2" alt="HKUST" />
            <img :src="school_3" alt="Manchester" />
            <img :src="school_4" alt="PolyU" />
            <img :src="school_5" alt="Sydney" />
            <img :src="school_6" alt="Toronto" />
            <img :src="school_7" alt="UBC" />
            <img :src="school_8" alt="UCL" />
            <img :src="school_9" alt="UCLA" />
            <img :src="school_10" alt="Washington" />
          </div>
        </div>
      </div>

      <div class="container">
        <h1>{{ $t('index.features') }} </h1>
        <div class="testimonials">
          <div :class="{
            testimonial: true,
            testimonial_l: true,
          }" v-for="(testimonial, index) in $t('testimonials')" :key="index">
            <!-- <div class="iconimg">
              <img :src="testimonial.iconurl" class="school-logo" />
            </div> -->
            <div class="student-info">
              <!-- <i :class="testimonial.iconurl"> </i> -->
              <img :src="testimonial.iconurl" class="school-logo" :alt="testimonial.name" />
            </div>
            <span class="name">{{ testimonial.name }}</span>
            <p>
              <strong> {{ testimonial.content1 }} </strong> {{ testimonial.content }}
            </p>
          </div>
        </div>
      </div>

      <div class="colleges2" v-if="true">
        <p>{{ $t('index.title3') }}</p>
        <div class="carousel-container1">
          <div class="universities1">
            <div class="student-wall">
              <div v-for="(student, index) in $t('students')" :key="index" class="student-card">
                <div class="student-card-header">
                  <img :src="student.schoolImage" :alt="student.schoolName" />
                  <div class="student-wall-name-wrap">
                    <div class="student-wall-name">{{ student.name }}</div>
                    <div class="student-wall-namenick">@{{ student.nickname }}</div>
                  </div>
                </div>
                <div class="student-wall-para" v-html="student.para"></div>
              </div>
            </div>


          </div>
          <div class="student-wall-wrap">
          </div>
        </div>

        <div class="carousel-container2">
          <div class="universities2">
            <div class="student-wall">
              <div v-for="(student, index) in $t('students2')" :key="index" class="student-card">
                <div class="student-card-header">
                  <img :src="student.schoolImage" :alt="student.schoolName" />
                  <div class="student-wall-name-wrap">
                    <div class="student-wall-name">{{ student.name }}</div>
                    <div class="student-wall-namenick">@{{ student.nickname }}</div>
                  </div>
                </div>
                <div class="student-wall-para" v-html="student.para"></div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </main>

    <img :src="banner1" v-if="!showpage" class="banner" alt="banner7" />
    <img :src="banner" v-if="!showpage" class="banner2" alt="banner5" />
    <img :src="banner" v-if="showpage && showpage !== 'price' && showpage !== 'about'" class="banner" alt="banner" />
    <img :src="banner2" v-if="showpage == 'about'" class="banner3" alt="banner3" />

    <div class="footer">
      <span class="footer-title">Copyright © 2025 ZenNyx AI inc. All rights reserved.</span>
      <div class="lista">
        <!-- <a href="#" v-for="(link, index) in footerLinks" :key="index">{{
          link
        }}</a> -->
        <a href="/features" @click.prevent="scrollToElement('container')"> {{ $t('index.features') }} </a>
        <!-- <a @click="scrollToElement('colleges')">{{ footerLinks[1] }}</a> -->
        <a href="/price" @click.prevent="gopage('price')"> {{ $t('index.pricing') }}</a>
        <a href="/terms" @click.prevent="gopage('TermsService')">{{ $t('index.service') }}</a>
        <a href="/privacy" @click.prevent="gopage('PrivacyPolicy')">{{ $t('index.privacy') }}</a>
      </div>
    </div>
    <popuplogin ref="pLogin" />
  </div>
</template>

<script>
import PricePage from "@/views/pages/index_page/pricePage.vue";
import temsservice from "@/views/pages/index_page/temsservice.vue";
import PrivacyPolicy from "@/views/pages/index_page/PrivacyPolicy.vue";
import AboutUs from "@/views/pages/index_page/AboutUs.vue";
import FAQ from "@/views/pages/index_page/FAQ.vue";
import popuplogin from "@/views/popups/popup_login.vue";
// import lunboimg from "@/views/comps/lunbo_img.vue";
import youtubeview from "@/views/comps/youtube.vue";
import i18n from '@/locale/i18n';  // 导入 VueI18n 实例
export default {
  name: "EasynoteaiPage",
  components: {
    PricePage,
    temsservice,
    PrivacyPolicy,
    popuplogin,
    // lunboimg,
    youtubeview,
    AboutUs,
    FAQ
  },
  data() {
    return {
      school_1: require("../assets/images/index/Columbia.png"),
      school_2: require("../assets/images/index/HKUST.png"),
      school_3: require("../assets/images/index/Manchester.png"),
      school_4: require("../assets/images/index/PolyU.png"),
      school_5: require("../assets/images/index/Sydney.png"),
      school_6: require("../assets/images/index/Toronto.png"),
      school_7: require("../assets/images/index/UBC.png"),
      school_8: require("../assets/images/index/UCL.png"),
      school_9: require("../assets/images/index/UCLA.png"),
      school_10: require("../assets/images/index/Washington.png"),
      banner: require("../assets/images/index/banner.png"),
      banner2: require("../assets/images/index/banner5.png"),
      banner1: require("../assets/images/index/banner7.png"),
      language: require("../assets/images/index/language.png"),
      videoId: "b1_7R57vrQA", // 提取的视频ID
      player: null,
      carouselImages: [
        require("../assets/images/index/index_lunbo1.png"),
        require("../assets/images/index/index_lunbo2.png"),
      ],
      notlogin: true,
      index_logo: require("../assets/images/index/logo.png"),
      showpage: "",
      students: [
        {
          name: "JohnDoe",
          nickname: "JohnDoe",
          schoolImage: require("../assets/images/index/Columbia.png"),
          schoolName: "Columbia",
          para: "Really, really liking <i style='color: #EA60FF; font-style: normal;'>@EasyNoteAI</i> so far. It's just the right amount of simple/fast for a personal note-taking app and does most of the hard work of organizing in the background.",
        },
        {
          name: "AlexTheGreat",
          nickname: "Alex",
          schoolImage: require("../assets/images/index/Columbia.png"),
          schoolName: "Harvard",
          para: "I just received an invite to  <i style='color: #EA60FF; font-style: normal;'>@EasyNoteAI</i>, and holy crap! It is well thought out, and I can see this being my note-taking platform going forward. Well done! I'm looking forward to seeing how the app progresses.",
        },
        {
          name: "SophieSpark",
          nickname: "spark",
          schoolImage: require("../assets/images/index/Columbia.png"),
          schoolName: "MIT",
          para: "Playing around with  <i style='color: #EA60FF; font-style: normal;'>@EasyNoteAI</i>, I'm backlogging key thoughts, details, and soundbites from episodes, books, meetings, articles, etc., from the past week. So far, it's a knowledge worker's dream come true.",
        },
        {
          name: "LiamRocks",
          nickname: "alice",
          schoolImage: require("../assets/images/index/Columbia.png"),
          schoolName: "Columbia",
          para: "Really, really liking <i style='color: #EA60FF; font-style: normal;'>@EasyNoteAI</i> so far. It's just the right amount of simple/fast for a personal note-taking app and does most of the hard work of organizing in the background.",
        },
        {
          name: "MiaJoy",
          nickname: "Mias",
          schoolImage: require("../assets/images/index/Columbia.png"),
          schoolName: "Harvard",
          para: "I just received an invite to  <i style='color: #EA60FF; font-style: normal;'>@EasyNoteAI</i>, and holy crap! It is well thought out, and I can see this being my note-taking platform going forward. Well done! I'm looking forward to seeing how the app progresses.",
        },
      ],
      students2: [
        {
          name: "JohnDoe",
          nickname: "JohnDoe",
          schoolImage: require("../assets/images/index/Columbia.png"),
          schoolName: "Columbia",
          para: "Really, really liking <i style='color: #EA60FF; font-style: normal;'>@EasyNoteAI</i> so far. It's just the right amount of simple/fast for a personal note-taking app and does most of the hard work of organizing in the background.",
        },
        {
          name: "AlexTheGreat",
          nickname: "Alex",
          schoolImage: require("../assets/images/index/Columbia.png"),
          schoolName: "Harvard",
          para: "I just received an invite to  <i style='color: #EA60FF; font-style: normal;'>@EasyNoteAI</i>, and holy crap! It is well thought out, and I can see this being my note-taking platform going forward. Well done! I'm looking forward to seeing how the app progresses.",
        },
        {
          name: "SophieSpark",
          nickname: "spark",
          schoolImage: require("../assets/images/index/Columbia.png"),
          schoolName: "MIT",
          para: "Playing around with  <i style='color: #EA60FF; font-style: normal;'>@EasyNoteAI</i>, I'm backlogging key thoughts, details, and soundbites from episodes, books, meetings, articles, etc., from the past week. So far, it's a knowledge worker's dream come true.",
        },
        {
          name: "LiamRocks",
          nickname: "alice",
          schoolImage: require("../assets/images/index/Columbia.png"),
          schoolName: "Columbia",
          para: "Really, really liking <i style='color: #EA60FF; font-style: normal;'>@EasyNoteAI</i> so far. It's just the right amount of simple/fast for a personal note-taking app and does most of the hard work of organizing in the background.",
        },
        {
          name: "MiaJoy",
          nickname: "Mias",
          schoolImage: require("../assets/images/index/Columbia.png"),
          schoolName: "Harvard",
          para: "I just received an invite to  <i style='color: #EA60FF; font-style: normal;'>@EasyNoteAI</i>, and holy crap! It is well thought out, and I can see this being my note-taking platform going forward. Well done! I'm looking forward to seeing how the app progresses.",
        },
      ],

      testimonials: [
        {
          name: "Record Live Lecture",
          content1: "",
          content:
            " Our tool offers live transcription in multiple languages, providing real-time text-based versions of spoken content.",
          iconurl: require("../assets/images/index/icons/10.png"),
        },
        {
          name: "Audio Note Generation",
          content1: "Record or upload audio files:",
          content:
            " Convert your spoken words into high-quality, structured notes.",
          iconurl: require("../assets/images/index/icons/1.png"),
        },
        {
          name: "YouTube Video Note Generation",
          content1: "Add YouTube video links: ",
          content: "Generate well-organized notes from YouTube videos.",
          iconurl: require("../assets/images/index/icons/2.png"),
        },
        {
          name: "PDF Note Generation",
          content1:
            "Upload PDFs, including research papers, financial reports, and more: ",
          content:
            "Create comprehensive summaries and analyses from your PDF documents.",
          iconurl: require("../assets/images/index/icons/3.png"),
        },
        {
          name: "Quizzes",
          content1: "Generate quizzes based on your content sources:",
          content:
            " Enhance your understanding of key concepts with interactive quizzes.",
          iconurl: require("../assets/images/index/icons/4.png"),
        },
        {
          name: "Flashcard",
          content1: "Generate flashcards: ",
          content:
            "Transform your notes into flashcards for effective knowledge retention.",
          iconurl: require("../assets/images/index/icons/5.png"),
        },
        {
          name: "Ask Questions",
          content1: "Ask questions to your uploaded documents:",
          content:
            " Gain deeper insights by asking questions directly to your documents.",
          iconurl: require("../assets/images/index/icons/6.png"),
        },
        // {
        //   name: "Mind Maps",
        //   content1: "Generate mind maps from uploaded documents:",
        //   content:
        //     " Visualize and organize complex information with mind maps.",
        //   iconurl: require("../assets/images/index/icons/7.png"),
        // },

        {
          name: "Summary Reports",
          content1: "Create summary reports from multiple types of documents: ",
          content:
            "Quickly grasp the main points of documents with automatically generated summaries.",
          iconurl: require("../assets/images/index/icons/8.png"),
        },
        {
          name: "Multiple Language Support",
          content1: "Translate your notes into multiple languages: ",
          content: "Communicate and share your knowledge globally.",
          iconurl: require("../assets/images/index/icons/9.png"),
        },

      ],

      footerLinks: [
        "Features",
        "Testimonials",
        "Pricing",
        "Terms of Service",
        "Privacy Policy",
      ],
      particles: [],
      // colors: ['#ff6b6b', '#48dbfb', '#1dd1a1', '#feca57', '#54a0ff'],
      colors: ['#ffffff', '#dd98ff', '#8472ff', '#fa3fff', '#191eff'],
      containerWidth: 0,
      containerHeight: 0,
      showDropdown: false,  // 控制下拉框显示与隐藏
    };
  },
  mounted() {
    // 在组件创建时访问路由参数
    // console.log("Route params:", this.$route);
    if (this.$route.params && this.$route.params.pagekey) {
      this.gopage(this.$route.params.pagekey);
    } else {
      this.scrollToElement('turbolearn-page')
    }
    // 如果需要将参数保存到组件的数据中
    // this.pageId = this.$route.params.id;
    // this.category = this.$route.query.category;

    // 检查登录状态
    this.checkLoginStatus();
    // this.metaInfo();
    this.containerWidth = this.$el.clientWidth;
    this.containerHeight = this.$el.clientHeight;
    this.generateParticles(400); // 生成50个粒子

    // 监听窗口大小变化，调整容器尺寸
    window.addEventListener('resize', this.handleResize);

    this.$nextTick(() => {
      this.checkPath(this.$route.path);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    checkPath(path) {
      console.log("path:", path);
      if (path.includes('features')) {
        this.scrollToElement('container');
        return; 
      }
      if (path.includes('price')) {
        this.gopage('price');
        return;
      }
      if (path.includes('terms')) {
        this.gopage('TermsService');
        return;
      }
      if (path.includes('privacy')) {
        this.gopage('PrivacyPolicy');
        return;
      }
      if (path.includes('about')) {
        this.gopage('about');
        return;
      }
      if (path.includes('faq')) {
        this.gopage('faq');
        return;
      }

    },
    generateParticles(count) {
      for (let i = 0; i < count; i++) {
        this.particles.push({
          x: Math.random() * this.containerWidth,
          y: Math.random() * this.containerHeight,
          size: Math.random() * 3 + 1, // 2-8px的随机大小
          color: this.colors[Math.floor(Math.random() * this.colors.length)],
          opacity: Math.random() * 0.7 + 0.2, // 0.2-0.8的随机透明度
          duration: Math.random() * 15 + 10 // 10-25秒的随机动画时长
        });
      }
    },
    handleResize() {
      this.containerWidth = this.$el.clientWidth;
      this.containerHeight = this.$el.clientHeight;
      // 重新生成粒子
      this.particles = [];
      this.generateParticles(50);
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    changeLanguage(language) {

      i18n.locale = language;  // 修改 i18n 的 locale
      localStorage.setItem('language', language);
      this.showDropdown = false;  // 选择后关闭下拉框
    },
    checkLoginStatus() {
      // 获取登录状态
      const subid = this.$cookies.get("subid");
      const ulogin = this.$cookies.get("userlogin");
      // console.log("subid:", subid);
      if (!!subid && subid != "undefined" && ulogin) {
        this.notlogin = false;
      }
    },
    gologinpage() {
      // this.$refs.pLogin.openModal();
      this.$router.push("/login");
    },
    gohomepage() {
      this.$router.push("/home");
    },
    gopage(page) {
      this.showpage = page;
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    },
    scrollToElement(className) {
      this.showpage = false;
      // 等待DOM更新
      this.$nextTick(() => {
        // 获取指定class的第一个元素
        const element = document.querySelector(`.${className}`);

        if (element) {
          // 获取元素的位置信息
          const rect = element.getBoundingClientRect();
          // 滚动到元素位置
          window.scrollTo({
            top: window.pageYOffset + rect.top,
            behavior: "smooth", // 平滑滚动
          });

          // 可选：给元素添加焦点
          element.focus({ preventScroll: true });
        } else {
          console.warn(`Element with class "${className}" not found.`);
        }
      });
    },
    openFaqPage(page) {
      const routes = {
        'most-popular': '/faq/most-popular',
        'record-notes': '/faq/record-notes',
        'subscription': '/faq/subscription'
      }
      window.open(routes[page], '_blank')
    }
  },
};
</script>

<style lang="less" scoped>
.turbolearn-page {
  color: #ffffff;
  font-family: Arial, sans-serif;
  min-height: 100vh;
  max-width: 82vw;
  margin: 0px auto;
  min-width: 800px;

  .particle-container {
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0; // 将粒子置于底层，不影响其他元素

    .particle {
      position: absolute;
      border-radius: 50%;
      animation-name: float;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      transform: translateY(0);
    }
  }

  @keyframes float {
    0% {
      transform: translateY(0) translateX(0);
    }

    25% {
      transform: translateY(-20px) translateX(10px);
    }

    50% {
      transform: translateY(-40px) translateX(-15px);
    }

    75% {
      transform: translateY(-20px) translateX(5px);
    }

    100% {
      transform: translateY(0) translateX(0);
    }
  }

  .ripple-container {
    position: relative;
    width: 0px;
    height: 0px;
    margin: 0px auto;
    display: flex;

    justify-content: center;
    align-items: center;
  }

  .ripple-ring {
    position: absolute;
    border: 1px solid rgba(255, 255, 255, 0.3);
    // background: rgba(255, 199, 249, 0.3);
    border-radius: 50%;
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ripple-outer {
    width: 90px;
    height: 90px;
    animation: ripple 4s infinite ease-out;
  }

  .ripple-inner {
    width: 45px;
    height: 45px;
    animation: ripple 4s infinite ease-out 0.5s;
  }

  @keyframes ripple {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }

    100% {
      transform: translate(-50%, -50%) scale(3);
      opacity: 0;
    }
  }

  .header-right {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown-wrap {

    margin-left: 40px;
    position: relative;
    border-radius: 6px;
    border-radius: 50px;

    // padding: 0.9vw 1.5vw;
    border: none;
    outline: none;
    background: #000;
    color: #fff;
    font-size: 0.9vw;
    width: 40px;
    height: 40px;
    // background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
    }

    .dropdown-inner {
      cursor: pointer;
      position: absolute;
      top: 50px;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 5px;

      border: 1px solid rgba(255, 255, 255, 0.3);
      width: 100px;
      margin-top: 5px;

      z-index: 100;
      padding: 10px;
      color: rgba(255, 255, 255, 0.8);

      .dropdown-text {

        margin-bottom: 5px;
        cursor: pointer;
      }
    }
  }


  .banner {
    position: absolute;
    top: 4vw;
    width: 100%;
    min-width: 1200px;
    padding: 0;
    left: 0;
    z-index: 0;
    opacity: 0.7;
  }

  .banner2 {
    position: absolute;
    top: 90vw;
    width: 100%;
    height: 1050px;
    min-width: 1200px;
    padding: 0;
    left: 0;
    z-index: 0;
    opacity: 0.7;
  }

  .banner3 {
    position: absolute;
    top: 65px;
    width: 100%;
    min-width: 1200px;
    padding: 0;
    left: 0;
    z-index: 0;
    opacity: 0.8;
  }


  .dash-button {
    position: relative;
    border-radius: 6px;
    border-radius: 50px;

    padding: 0.9vw 1.5vw;
    border: none;
    outline: none;
    background: #000;
    color: #fff;
    font-size: 0.9vw;

    cursor: pointer;
    background: linear-gradient(90deg, #5143ff, #9c49f8, #f15fe2);
  }

  .dash-button:before {
    content: '';
    position: absolute;
    background: inherit;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: 50px;
    filter: blur(10px);
    opacity: 0;
    transition: opacity 0.5s;
  }

  .dash-button:hover {
    background: linear-gradient(90deg, #5143ff, #9c49f8, #f15fe2, #03a9f4, #5143ff);
    background-size: 400%;
    z-index: 1;
    animation: glow 8s linear infinite;
  }

  .dash-button:hover:before {
    opacity: 1;
    z-index: -1;
  }

  @keyframes glow {
    0% {
      background-position: 0%;
    }

    100% {
      background-position: 400%;
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.8vw;

    .logo {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 1.6em;

      img {
        cursor: pointer;
        margin-right: 10px;
        width: 11vw;
      }
    }

    nav {

      text-align: center;


      a {
        cursor: pointer;
        color: rgb(255, 255, 255);
        font-family: Outfit;
        font-size: 15px;
        // font-weight: 500;
        letter-spacing: 0px;
        margin: 0 0.4vw;
        padding: 10px 0;
        text-align: center;
        opacity: 0.85;

        &:hover {
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }



  main {
    .features {
      margin-top: 6.8vw;
      display: flex;
      align-items: center;

      position: relative;
      z-index: 1;


      .content {
        flex: 8.4;
        text-align: center;


        h1 {
          font-size: 3vw;
          font-weight: 600;
          line-height: 60px;
          letter-spacing: 0px;
          text-align: center;
          word-break: keep-all;
          white-space: normal;
          font-family: Outfit;

          .purple {
            color: #6c5ce7;
          }
        }

        p {
          color: rgb(238, 224, 255);
          font-family: Outfit;
          font-size: 16.8px;
          // font-weight: 500;
          line-height: 2px;
          letter-spacing: 0px;
          opacity: 0.8;
        }
      }

      .startbox1 {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        // margin: 28px 0px;
        width: 100%;
        position: relative;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 68px;
        margin-bottom: 80px;
        visibility: hidden;
      }

      .startbox {
        text-align: center;
        display: inline-block;
        position: relative;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 68px;
        margin-bottom: 160px;
        border-radius: 50px;

        .startbtn-button {
          position: relative;
          height: 32px;
          width: 200px;
          line-height: 32px;
          border-radius: 6px;
          // margin: 0 35px;
          border-radius: 50px;
          // border-radius: 6px;
          padding: 0.9vw 1.5vw;
          border-radius: 50px;
          border: none;
          outline: none;
          color: #fff;
          font-size: 1.2vw;
          cursor: pointer;
          background: linear-gradient(90deg, #5143ff, #9c49f8, #f15fe2);
        }

        .startbtn-button::before {
          content: '';
          position: absolute;
          background: inherit;
          border-radius: 50px;
          opacity: 0;
          transition: opacity 0.5s;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          filter: blur(10px);
          /* 保持在按钮内容下方 */
        }

        .startbtn-button:hover {
          border-radius: 50px;
          background: linear-gradient(90deg, #5143ff, #9c49f8, #f15fe2, #03a9f4, #5143ff);
          background-size: 400%;
          z-index: 1;
          animation: glow 8s linear infinite;
        }

        .startbtn-button:hover::before {
          border-radius: 50px;
          opacity: 1;
          z-index: -1;
        }

        @keyframes glow {
          0% {
            background-position: 0%;
          }

          100% {
            background-position: 400%;
          }
        }


        .nocredlt {
          font-size: 14px;
          color: #888;
        }
      }

      .upload-box {

        background-color: #1e1e2e;

        overflow: hidden;
        border-radius: 12px;
        flex: 6;

      }
    }

    .colleges {
      text-align: center;
      margin-top: 80px;

      p {
        font-size: 1.4vw;
        padding-top: 30px;
        margin-bottom: 38px;
        opacity: 0.9;
      }

      .carousel-container {
        width: 100%;
        /* 容器宽度 */
        overflow: hidden;
        /* 隐藏溢出的内容 */
        position: relative;
        /* 相对定位 */

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          width: 110px;
          height: 100%;
          z-index: 1;
        }

        &::before {
          left: 0;
          background: linear-gradient(to right, rgba(9, 5, 37, 1) 20%, transparent);
          // background: linear-gradient(to right, rgba(9, 5, 37, 1) 20%, transparent);
          // background: linear-gradient(to right, rgba(9, 5, 37, 1) 0.2%, rgba(0, 0, 0, 0) 50%, rgba(9, 5, 37, 1) 99.5%);
        }

        &::after {
          right: 0;
          background: linear-gradient(to left, rgba(9, 5, 37, 1) 20%, transparent);
          // background: linear-gradient(to right, rgba(9, 5, 37, 1) 0.2%, rgba(0, 0, 0, 0) 50%, rgba(9, 5, 37, 1) 99.5%);
        }
      }

      .universities {
        display: flex;
        /* 子元素横向排列 */
        width: max-content;
        /* 宽度自动适应内容 */
        animation: scroll 15s linear infinite;
        /* 跑马灯动画 */
        transform-style: preserve-3d;
        /* 保留3D效果 */
      }

      .universities img {
        height: 100px;
        // width: 100px;
        // background: #ccc;
        opacity: 1;
        border-radius: 10px;
        margin: 0px 22px;
        transform: rotateY(20deg) translateZ(100px);

        /* 3D效果 */
        &:hover {
          opacity: 1;
        }
      }

      @keyframes scroll {
        0% {
          transform: translateX(0%);
          /* 从右边开始 */
        }

        100% {
          transform: translateX(-50%);
          /* 向左滚动直到完全移出视野 */
        }
      }
    }


    .colleges2 {

      width: 100%;
      overflow: hidden;
      position: relative;
      padding-bottom: 120px;


      p {
        text-align: center;
        font-size: 24px;
        margin-bottom: 50px;
      }

      .carousel-container1,
      .carousel-container2 {
        width: 100%;
        overflow: hidden;
        margin: 20px 0;

      }


      .student-wall-wrap {
        position: absolute;
        // background: red;
        top: 0;
        background: linear-gradient(to right, rgba(9, 5, 37, 1) 0.2%, rgba(0, 0, 0, 0) 50%, rgba(9, 5, 37, 1) 99.5%);
        width: 88vw;
        height: 600px;
        // height: 100%;
        z-index: 10;
      }


      .universities1,
      .universities2 {

        display: flex;
        align-items: center;
        // justify-content: center;
        position: relative;

        img {
          height: 50px; // Adjust based on your needs
          margin: 0 20px;
          flex-shrink: 0;
        }



        .student-wall {

          align-items: center;
          display: flex;


          .student-card {
            margin-left: 10px;
            margin-right: 10px;
            width: 350px;
            height: 170px;
            // background: rgb(10, 247, 255);
            background: rgba(255, 255, 255, 0.02);
            border: 1px solid rgba(245, 225, 255, 0.15);
            border-radius: 20px;
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 20px;
            padding-bottom: 30px;

            .student-card-header {
              padding-bottom: 10px;
              display: flex;
              align-items: center;

              .name {
                font-size: 20px;
              }

              .student-wall-name-wrap {
                display: flex;
                flex-direction: column;

                .student-wall-name {
                  color: rgba(255, 255, 255, 0.9);
                  font-size: 14px;
                  margin-bottom: 5px;
                }

                .student-wall-namenick {
                  color: rgba(254, 246, 255, 0.6);
                  font-size: 14px;
                  margin-bottom: 5px;

                }
              }


              img {
                width: 40px;
                /* 适当调整图片大小 */
                height: 40px;
                object-fit: cover;

                object-fit: cover;
                /* 保持图片比例 */
                margin-right: 12px;
                margin-left: 0px;
                border-radius: 100%;
                border: 1px solid rgba(245, 225, 255, 0.15);

              }


              /* 垂直居中对齐 */
            }

            .student-wall-para {
              width: 100%;
              word-wrap: break-word;
              /* 兼容性较好 */
              overflow-wrap: break-word;
              /* 更现代的方式 */
              white-space: normal;
              font-size: 16px;
              color: rgba(255, 255, 255, 0.8);
              line-height: 1.5;

              i {
                color: rgb(234, 96, 255);
                font-style: normal;

              }
            }


          }

        }
      }

      // Right to left animation (top carousel)
      .universities1 {
        animation: moveLeftwards 40s linear infinite;
      }

      // Left to right animation (bottom carousel)
      .universities2 {
        animation: moveRightwards 40s linear infinite;
      }
    }

    // Animation for right to left movement
    @keyframes moveLeftwards {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(-50%); // Move left by half the content width
      }
    }

    // Animation for left to right movement
    @keyframes moveRightwards {
      0% {
        transform: translateX(-50%); // Start from negative position
      }

      100% {
        transform: translateX(0);
      }
    }





    .container {
      color: white;
      padding: 5vw 0px;
      font-family: Arial, sans-serif;
      position: relative;
      z-index: 2;

      h1 {
        text-align: center;
        font-size: 1.7vw;
        margin: 0px;
        margin: 1vw 0px;
        font-family: Outfit;
      }

      .testimonials {
        border-radius: 12px;
        padding: 2.2vw 6vw;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1.1vw;


        .testimonial_l {
          border: 1px solid rgba(245, 225, 255, 0.15);
          background: rgba(255, 255, 255, 0.1);



          // backdrop-filter: blur(1px);

        }

        .testimonial_l:hover {
          border: 1px solid #5544f4;
          background: linear-gradient(to right, #5544f4, #924ef0, #d765de);
          // background: url("../assets/images/index/card-bg.png") no-repeat;
          background-size: 100% auto;
        }



        .testimonial {
          position: relative;
          border-radius: 0.5vw;
          padding: 0.4vw 1.6vw;


          p {

            color: rgba(255, 255, 255, 0.75);
            line-height: 1.2vw;
            font-size: 0.9vw;
            font-family: Outfit;
            font-weight: normal;


            strong {
              font-weight: normal;

            }

            padding-bottom: 2vw;

          }

          .student-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 0.7vw;



            img {
              max-height: 2.1vw;
              padding-top: 0.8vw;
              padding-bottom: 0.8vw;
              opacity: 0.8;

            }
          }

          .name {
            font-size: 1.35vw;
            font-weight: normal;
            display: block;
            font-family: Outfit;


          }

          i {
            margin: 20px 0px;
            font-size: 20px;
          }
        }
      }
    }
  }

  .footer {
    margin-top: 6.6vw;
    padding-bottom: 6.6vw;
    text-align: center;
    display: flex;
    font-family: Outfit;
    flex-direction: column;

    .footer-title {
      margin-bottom: 1vw;
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
      // font-weight: 500;
      line-height: 14px;
      letter-spacing: 0px;
    }

    .lista {
      margin-bottom: 1vw;
    }

    a {
      color: #888;
      color: rgba(255, 255, 255, 0.3);
      text-decoration: none;
      margin: 0 10px;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.faq-dropdown {
  position: relative;
  display: inline-block;
}

.faq-dropdown>a {
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background: rgba(47, 50, 65, 0.95);
  border-radius: 8px;
  padding: 10px 0;

  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.dropdown-content a {
  color: #fff;
  padding: 10px 20px;
  display: block;
  text-align: center;
  font-size: 14px;
  transition: background-color 0.3s;
  white-space: nowrap;
  cursor: pointer;
}

.dropdown-content a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* 显示下拉菜单 */
.faq-dropdown:hover .dropdown-content {
  display: block;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

/* 确保导航栏中的其他链接样式保持一致 */
nav {
  display: flex;
  align-items: center;
  gap: 30px;
}

nav a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

nav a:hover {
  color: #6c5ce7;
}
</style>
