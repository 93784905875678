<template>
  <div class="terms-of-service" v-html="$t('terms')">

  </div>
</template>

<script>
export default {
  name: "TermsOfService",
};
</script>

<style lang="less">
.terms-of-service {
  position: relative;
  z-index: 2;
  color: #ffffff;
  font-family: Arial, sans-serif;
  margin: 0 auto;
  max-width: 1000px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 50px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  margin-top: 50px;

  h1 {
    font-size: 2vw;
    margin-bottom: 30px;
    text-align: center;
  }

  h2 {
    font-size: 1.5vw;
    margin-top: 40px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 15px;
  }

  h3 {
    font-size: 1.2vw;
    color: rgb(233, 89, 255);
    margin-top: 30px;
    margin-bottom: 15px;
  }

  p,
  li {
    line-height: 1.6;
    margin-bottom: 15px;
  }

  ul {
    padding-left: 20px;
    margin-bottom: 20px;
  }

  a {
    color: #a855f7;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  section {
    margin-bottom: 40px;
  }
}
</style>
