import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Index from '@/views/index.vue'
import noteindex from '@/views/notes_index.vue'
import shatePage from '@/views/share_notes.vue'
import login from '@/views/pages/login.vue'
import Cookies from "js-cookie";

import SubscriptionComponent from '@/views/pages/index_page/SubscriptionComponent.vue'

import MostPopular from '@/views/faq/MostPopular.vue'
import RecordNotes from '@/views/faq/RecordNotes.vue'
import Subscription from '@/views/faq/Subscription.vue'

import d_home from '@/views/deepresearch/d_home.vue'
import Unstuck from '@/views/unstuck/u_home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/faq/most-popular',
        name: 'MostPopular',
        component: MostPopular
    },
    {
        path: '/faq/record-notes',
        name: 'RecordNotes',
        component: RecordNotes
    },
    {
        path: '/faq/subscription',
        name: 'Subscription',
        component: Subscription
    },
    {
        path: '/home',
        name: 'Homeindex',
        component: Home,
        meta: {
            title: "EasyNoteAI Notes",
            requiresAuth: true,
        },
    },
    {
        path: '/home/:pagekey',
        name: 'Homeindex_key',
        component: Home,
        meta: {
            title: "EasyNoteAI Notes",
            requiresAuth: true,
        },
    },
    {
        path: '/',
        name: 'IndexPage',
        component: Index,
        meta: {
            title: "EasyNoteAI Notes",
        },
    },
    {
        path: '/page/:pagekey',
        name: 'IndexPage_key',
        component: Index,
        meta: {
            title: "EasyNoteAI Notes",
        },
    },
    {
        path: '/notes',
        name: 'notesPage',
        component: noteindex,
        props: true,
        meta: {
            title: "EasyNoteAI Notes",
        },
    },
    {
        path: '/notes/:pagekey',
        name: 'notesPage_key',
        component: noteindex,
        meta: {
            title: "EasyNoteAI Notes",
        },
    },
    {
        path: '/share',
        name: 'shatePage',
        component: shatePage,
        meta: {
            title: "EasyNoteAI Notes",
        },
    },
    {
        path: '/login',
        name: 'loginPage',
        component: login,
        meta: {
            title: "EasyNoteAI",
        },
    },
    {
        path: '/SubscriptionComponent',
        name: 'SubscriptionComponent',
        component: SubscriptionComponent,
        meta: {
            title: "EasyNoteAI",
        },
    },
    {
        path: '/deepresearch',
        name: 'deepresearch',
        component: d_home,
        meta: {
            title: "DeepResearch",
        },
    },
    {
        path: '/deepresearch/:pagekey',
        name: 'deepresearch_key',
        component: d_home,
        meta: { title: "DeepResearch" }
    },
    {
        path: '/editor',
        name: 'Editor',
        component: () => import('@/views/deepresearch/pages/Editor.vue')
    },
    {
        path: '/unstuck',
        name: 'Unstuck',
        component: Unstuck,
        meta: { title: "Unstuck" }
    },
    {
        path: '/unstuck/:pagekey',
        name: 'Unstuck_key',
        component: Unstuck,
        meta: { title: "Unstuck" }
    },

    {
        path: '/about',
        name: 'About',
        component: () => import('@/views/index.vue')
    },
    {
        path: '/faq',
        name: 'Faq',
        component: () => import('@/views/index.vue')
    },
    {
        path: '/features',
        name: 'Features',
        component: () => import('@/views/index.vue')
    },
    {
        path: '/price',
        name: 'Price',
        component: () => import('@/views/index.vue')
    },
    {
        path: '/terms',
        name: 'Terms',
        component: () => import('@/views/index.vue')
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import('@/views/index.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    // console.log(from, 'from');
    const subid = Cookies.get('subid');
    // console.log(requiresAuth, 'requiresAuth',subid);
    if (requiresAuth && !subid) {
        // 如果需要登录且用户未登录，则重定向到登录页面
        next({ name: 'loginPage' });
    } else {
        next();
    }
});

export default router
