<template>
    <div class="video-player">
        <!-- Loading 状态 -->
        <div v-if="loading" class="loading-container">
            <div class="loading-spinner"></div>
            <p>Loading video...</p>
        </div>
        
        <!-- YouTube播放器 -->
        <youtube v-if="platform === 'youtube'"
                :video-id="videoId" 
                @ready="onReady" 
                @error="onError" 
                @playing="onPlaying"
                @buffering="onBuffering"
                @ended="onEnded" 
                ref="youtube"></youtube>

        <!-- 哔哩哔哩播放器 -->
        <iframe v-else-if="platform === 'bilibili'"
                :src="bilibiliEmbedUrl"
                scrolling="no"
                border="0"
                frameborder="no"
                framespacing="0"
                allowfullscreen="true"
                ref="bilibili">
        </iframe>
    </div>
</template>

<script>
export default {
    name: 'VideoPlayer',
    props: {
        url: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: true,
            videoId: '',
            platform: 'youtube',
            bilibiliEmbedUrl: '',
            isPlaying: false,
            isBuffering: false,
            player: null,
        }
    },
    watch: {
        url: {
            immediate: true,
            handler(newUrl) {
                this.loading = true;
                this.extractVideoId(newUrl)
            }
        }
    },
    methods: {
        extractVideoId(url) {
            try {
                // 检测是否为B站链接
                const bilibiliRegExp = /bilibili\.com\/video\/(BV[\w]+)/
                const bilibiliMatch = url.match(bilibiliRegExp)
                if (bilibiliMatch) {
                    this.platform = 'bilibili'
                    this.videoId = bilibiliMatch[1]
                    this.bilibiliEmbedUrl = `//player.bilibili.com/player.html?bvid=${this.videoId}&page=1&high_quality=1&danmaku=0`
                    this.loading = false
                    return
                }

                // YouTube链接处理
                this.platform = 'youtube'
                const youtubeRegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
                const match = url.match(youtubeRegExp)
                this.videoId = match && match[7].length === 11 ? match[7] : null
                
                if (!this.videoId) {
                    throw new Error('Invalid video URL')
                }
            } catch (err) {
                console.error('Error extracting video ID:', err)
                this.loading = true
            }
        },

        onReady(event) {
            this.player = event;
            if (!this.player) {
                return;
            }
            // 检查视频是否已经缓冲
            const state = this.player.getPlayerState();
            if (state !== -1) { // -1 表示未开始
                this.loading = false;
                this.player.mute();
                this.player.playVideo();
            }
        },

        onError(error) {
            console.error('YouTube player error:', error)
            this.loading = true;
        },

        onBuffering() {
            this.isBuffering = true;
            // 不在这里设置 loading = true，因为这可能会导致视频闪烁
        },

        onPlaying() {
            this.isPlaying = true;
            this.isBuffering = false;
            // 确保视频真正开始播放后才移除 loading
            this.loading = false;
            
        },

        onEnded() {
            this.isPlaying = false;
        }
    }
}
</script>

<style lang="less" scoped>
.video-player {
    width: 700px;
    height: 360px;
    background: #000;
    text-align: center;
    position: relative;

    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}

.loading-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #4a90e2;
    animation: spin 1s ease-in-out infinite;
    margin-bottom: 15px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.loading-container p {
    font-size: 16px;
    color: #fff;
    margin: 0;
}

/* 响应式设计 */
@media (max-width: 768px) {
    .video-player {
        width: 100%;
        height: 200px;
    }
}
</style>