<template>
  <div class="video-container">
    <button @click="playVideo" v-if="!isPlaying" class="control-btn-bg">
      <svg t="1740735997739" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
        p-id="2634" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" height="200">
        <path
          d="M512 0C230.4 0 0 230.4 0 512s230.4 512 512 512 512-230.4 512-512S793.6 0 512 0z m0 981.333333C253.866667 981.333333 42.666667 770.133333 42.666667 512S253.866667 42.666667 512 42.666667s469.333333 211.2 469.333333 469.333333-211.2 469.333333-469.333333 469.333333z"
          fill="#fff" p-id="2635"></path>
        <path
          d="M672 441.6l-170.666667-113.066667c-57.6-38.4-106.666667-12.8-106.666666 57.6v256c0 70.4 46.933333 96 106.666666 57.6l170.666667-113.066666c57.6-42.666667 57.6-106.666667 0-145.066667z"
          fill="#fff" p-id="2636"></path>
      </svg>
    </button>

    <video class="youtube-player" ref="videoPlayer" :src="videoSource" width="600" height="600" loop muted
      @click="pauseVideo"></video>
  </div>
</template>

<script>
export default {
  name: "YoutubePlayer",
  data() {
    return {
      videoSource: require('@/assets/video/3_3.mp4'),
      videoId: "zGekatBTLdA", // 提取的视频ID
      player: null,
      isPlaying: false,
      message: "",
      volume: 0, // 默认音量
    };
  },
  created() {
    // 组件创建时触发
  },
  mounted() {
    // 组件挂载完成后触发
    // 如果需要自动播放，可以取消下面代码的注释
    setTimeout(() => {
      this.playVideo();
    }, 1600);
  },
  methods: {
    playVideo() {
      const video = this.$refs.videoPlayer;
      if (video) {
        video.play()
          .then(() => {
            this.isPlaying = true;
          })
          .catch(error => {
            console.error("视频播放失败:", error);
          });
      }
    },
    pauseVideo() {
      // 只有在视频正在播放时才暂停
      if (this.isPlaying) {
        const video = this.$refs.videoPlayer;
        if (video) {
          video.pause();
          this.isPlaying = false;
        }
      }
    },
    toggleLoop() {
      const video = this.$refs.videoPlayer;
      if (video) {
        video.loop = !video.loop;
      }
    },
    setVolume() {
      const video = this.$refs.videoPlayer;
      if (video) {
        video.volume = this.volume / 100;
      }
    },
    muteVideo() {
      const video = this.$refs.videoPlayer;
      if (video) {
        video.muted = true;
      }
    },
    unMuteVideo() {
      const video = this.$refs.videoPlayer;
      if (video) {
        video.muted = false;
      }
    }
  },
};
</script>

<style>
.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 30px;
  z-index: 10;
  /* background: red; */
}

.youtube-player {
  width: 80%;
  height:auto;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.46);
  overflow: hidden;
  cursor: pointer;
  object-fit: cover;
}

.control-btn-bg {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 100;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.8;
}

.control-btn-bg svg {
  width: 100px;
  height: 100px;
}

.control-btn-bg:hover {
  opacity: 1;
}

.volume-slider {
  width: 100px;
  height: 10px;
  border-radius: 5px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.volume-slider:hover {
  opacity: 1;
}

.message {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
}
</style>