<template>
  <div class="faq-page">
    <div class="faq-container">
      <h1>{{ $t('faqItemstitle33') }}</h1>

      <div class="faq-list">
        <div v-for="(item, index) in $t('faqItems2')" :key="index" class="faq-item">
          <h2>{{ item.question }}</h2>
          <div class="answer" v-html="formatAnswer(item.answer)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionPage',
  data() {
    return {
      faqItems2: [
        {
          question: "Get the best value with our annual plans!",
          answer: "We offer a variety of subscription plans to meet your needs. Upgrade to an annual plan and save 70% compared to monthly billing."
        },
        {
          question: "What is the Free Plan?",
          answer: "Our Free Plan lets you try out all EasyNoteAI features, but with limited usage. Upgrade to a subscription for unlimited access."
        },
        {
          question: "How to pause my subscription?",
          answer: "We don't offer a pause feature right now. However, you can cancel your subscription at any time."
        },
        {
          question: "How to end or cancel subscription?",
          answer: "If you subscribed through our website, follow these steps:<br><span style='font-size: 14px;' > 1. Go to \"Settings\"</span><br><span style = 'font-size: 14px;'>2. Click on \"Billing Portal\"</span><br><span style = 'font-size: 14px;'>3. Enter your registered email address and submit</span> <br><span style='font-size: 14px;'>4. You will receive an email from Deepseek AI Limited</span><br><span style='font-size: 14px;'>5. Follow the instructions in the email</span>"
        }
      ]
    }
  },
  methods: {
    formatAnswer(answer) {
      return answer.replace(/\n/g, '<br>')
    }
  }
}
</script>

<style scoped>
.faq-page {
  min-height: 100vh;
  /* background-color: #f8f9fa; */
  padding: 0;
}

.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
  /* background: white; */
  border-radius: 16px;
  /* box-shadow: 0 4px 20px rgba(255, 231, 231, 0.08); */
  color: rgba(255, 255, 255, 0.9);
}

h1 {
  /* color: #2d3436; */
  margin-bottom: 40px;
  text-align: left;
  font-size: 36px;
  font-weight: 600;
  position: relative;
  color: rgba(255, 255, 255, 0.9);
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.faq-item {
  margin-bottom: 0;

  padding: 20px 32px 40px 32px;
  border-radius: 12px;
  /* background: #f8f9fa; */
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  /* border-left: 4px solid transparent; */
}

.faq-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
  pointer-events: none;
}

.language-support {
  /* border-left-color: #4a90e2; */
}

.contact-support {
  /* border-left-color: #00b894; */
}

.faq-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(248, 233, 255, 0.05);
}

h2 {
  color: rgba(255, 255, 255, 1);
  /* opacity: 0.8; */
  /* margin-bottom: 20px; */
  font-size: 26px;
  /* font-weight: 600; */
  line-height: 0.5;
  display: flex;
  align-items: center;
  gap: 10px;
  /* background: red; */
}

.answer {
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.5;
}


h2 i {
  font-size: 24px;
  /* opacity: 0.8; */
}

.language-support h2 i {
  color: rgba(255, 255, 255, 0.8);
}

.contact-support h2 i {
  color: rgba(255, 255, 255, 0.8);
}

p {
  /* color: #636e72; */
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.8;
  font-size: 14px;
  margin: 0;

  text-align: left;
  padding-left: 34px;

}

ol {
  padding-left: 50px;
  margin: 0;
  list-style-position: outside;
}

li {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 15px;
  line-height: 0.8;
  font-size: 14px;
  padding-left: 10px;
  text-align: left;
}

li:last-child {
  margin-bottom: 0;
}

strong {
  /* color: #f5fdff; */
  color: rgba(255, 255, 255, 0.8);
  /* font-weight: 600; */
  margin-right: 8px;
}

a {
  /* color: #cbe4ff; */
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: all 0.2s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}

a:hover {
  color: #cc85ff;
  border-bottom-style: solid;
  border-bottom: 1px solid #cc85ff;
  ;
}

@media (max-width: 768px) {
  .faq-container {
    padding: 20px;
    margin: 0 15px;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 20px;
  }

  .faq-item {
    padding: 20px;
  }

  p,
  li {
    font-size: 15px;
  }
}

/* 添加动画效果 */
.faq-item {
  animation: fadeInUp 0.5s ease-out forwards;
  opacity: 0;
}

.faq-item:nth-child(1) {
  animation-delay: 0.1s;
}

.faq-item:nth-child(2) {
  animation-delay: 0.2s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>