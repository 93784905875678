<template>
  <div id="app">
    <div class="woring" v-if="woringtext != 'none'"> {{ woringtext }}</div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      woringtext: "",
    };
  },
  created() {
    this.getOrCreateGuestId();
    this.getworking();
  },
  methods: {
    async getworking() {
      try {
        let res = await this.$axios.get("/api/getviewmsg");
        this.woringtext = res.data.viewmsg;
      } catch (e) {
        console.log(e);
      }

    },
    generateUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    getOrCreateGuestId() {
      let guestId = localStorage.getItem("guestId");
      if (!guestId) {
        guestId = this.generateUUID();
        localStorage.setItem("guestId", guestId);
        console.log("新的游客ID已创建:");
      } else {
        console.log("已有游客ID:");
      }
      // 你可以在这里将游客ID发送到你的服务器进行记录
      // 例如：this.sendGuestIdToServer(guestId);
    },
  },
};
</script>

<style lang="less">
.woring {
  color: red;
  font-size: 20px;
  background-color: antiquewhite;
  text-align: center;
}

/* 自定义滚动条样式 */
::-webkit-scrollbar {
  // width: 10px; //水平不滚动过去掉
  /* 滚动条宽度 */
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
  /* 滚动条轨道背景色 */
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #4a4a4a;
  /* 滚动条滑块颜色 */
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #5a5a5a;
  /* 鼠标悬停时滑块颜色 */
}

body {
  margin: 0;
}

#app {
  background-color: rgb(9, 5, 37);
  overflow-x: auto;
  min-width: 1200px;
}
</style>
