<template>
  <div class="dialog-overlay" v-if="visible" @click="handleClose">
    <div class="dialog-container" @click.stop>
      <div class="dialog-header">
        <span class="dialog-title">重新生成大纲</span>
      </div>
      <div class="dialog-content">
        <div class="warning-icon">
          <i class="el-icon-warning"></i>
        </div>
        <div class="dialog-text">论文标题、大纲、及大纲下补充的内容将重置，确定重写?</div>
        <div class="checkbox-container">
          <label class="checkbox-label">
            <input type="checkbox" v-model="notShowAgain">
            <span class="checkbox-text">下次不再提醒</span>
          </label>
        </div>
      </div>
      <div class="dialog-footer">
        <button class="dialog-btn cancel-btn" @click="handleClose">取消</button>
        <button class="dialog-btn confirm-btn" @click="handleConfirm">确认</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RefreshOutlineDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      notShowAgain: false
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false)
    },
    handleConfirm() {
      this.$emit('confirm', this.notShowAgain)
    }
  }
}
</script>

<style lang="less" scoped>
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog-container {
  background: #fff;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.dialog-header {
  padding: 15px 20px;
  border-bottom: 1px solid #EBEEF5;

  .dialog-title {
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }
}

.dialog-content {
  padding: 20px;
  text-align: center;

  .warning-icon {
    margin-bottom: 15px;
    
    i {
      font-size: 24px;
      color: #E6A23C;
    }
  }

  .dialog-text {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
  }

  .checkbox-container {
    text-align: left;
    padding-left: 20px;

    .checkbox-label {
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;

      input[type="checkbox"] {
        margin-right: 8px;
      }

      .checkbox-text {
        font-size: 14px;
        color: #606266;
      }
    }
  }
}

.dialog-footer {
  padding: 15px 20px;
  border-top: 1px solid #EBEEF5;
  display: flex;
  justify-content: flex-end;
  gap: 12px;

  .dialog-btn {
    padding: 8px 20px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #DCDFE6;
    transition: all 0.3s;

    &.cancel-btn {
      background-color: #fff;
      color: #606266;

      &:hover {
        color: #409EFF;
        border-color: #c6e2ff;
        background-color: #ecf5ff;
      }
    }

    &.confirm-btn {
      background-color: #409EFF;
      color: #fff;
      border-color: #409EFF;

      &:hover {
        background-color: #66b1ff;
        border-color: #66b1ff;
      }
    }
  }
}
</style> 