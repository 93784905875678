<template>
    <div class="create-chat-container background-color">
        <!-- 顶部导航 -->
        <div class="chat-header">
            <div class="header-left" @click="goBack">
                <i class="el-icon-arrow-left"></i>
                <span>Back</span>
            </div>
            <div class="header-title">{{ chatTitle ? chatTitle : 'Untitled Conversation' }}</div>
            <div class="header-right">
                <button class="premium-btn" v-if="false">
                    <i class="premium-icon">🔸</i>
                    Buy Premium
                </button>
            </div>
        </div>

        <!-- 聊天内容区域 -->
        <div class="chat-content" ref="chatContent">
            <div class="chat-box">
                <div class="chatbox" v-for="(message, cindex) in chatmessages" :key="cindex">
                    <div class="avatar-box" v-if="!message?.showloading">
                        <img v-if="message.type === 'user'" :src="userAvatar" class="user-avatar" alt="avatar" />
                        <img v-else :src="aiAvatar" class="avatar" alt="avatar" />
                    </div>
                    <div v-if="message.type === 'user'" class="user-message">
                        <div>{{ message.content }}</div>
                        <div class="file-list" v-if="message?.files?.length > 0">
                            <div class="file-item" v-for="(file, findex) in message.files" :key="findex">
                                <div class="file-icon">
                                    <!-- <i class="el-icon-document"></i> -->
                                    <img :src="documentimg" class="file-icon" alt="icon" />
                                    <span>{{ file.name }}</span>
                                </div>
                                <div class="file-info">
                                    <span>{{ file.type }}</span>
                                    <span>{{ formatFileSize(file.size) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ai-message" v-if="message.type === 'ai' && !message?.showloading">
                        <div class="message-header" v-if="message.title">
                            <div class="writing-title">
                                <span>{{ message.title }}</span>
                                <span v-if="message.chatType == 'article'">
                                    Generated draft, click to view
                                </span>
                            </div>
                        </div>
                        <div class="ai-message-content">
                            <div class="iscontent" v-if="message?.chatType != 'article'">
                                {{ message.content }}
                            </div>
                            <div class="isarticle" v-if="message?.chatType == 'article'">
                                <div class="file-item" @click="openEditor(message)">
                                    <div class="file-icon">
                                        <!-- <i class="el-icon-document"></i> -->
                                        <img :src="documentimg" class="file-icon" alt="icon" />
                                        <span class="file-name">{{ message.title }}</span>

                                    </div>

                                    <i class="el-icon-edit-outline"></i>
                                </div>
                            </div>
                            <div class="outline-box" v-if="message?.chatType == 'outline'">
                                <div class="outline-container">
                                    <div class="chapter-item" v-for="(chapter, index) in message.outline.outline"
                                        :key="index">
                                        <div class="chapter-header">
                                            <div v-if="editingItem === `chapter-${index}`" class="edit-input">
                                                <input type="text" v-model="chapter.title" @blur="finishEdit()"
                                                    @keyup.enter="finishEdit()" :ref="`editInput-${index}`">
                                            </div>
                                            <div v-else @dblclick="startEdit('chapter', index)">{{ chapter.title }}
                                            </div>
                                            <div class="action-buttons">
                                                <i class="el-icon-edit" @click="startEdit('chapter', index)"></i>
                                                <i class="el-icon-delete"
                                                    @click="handleCommand({ action: 'delete', cindex, index, type: 'chapter' })"></i>
                                                <i class="el-icon-plus" @click="addNewItem('chapter', index)"></i>
                                            </div>
                                        </div>

                                        <template v-for="(subtitle, subIndex) in chapter.subtitles">
                                            <!-- 小节内容 -->
                                            <div :key="subIndex" class="subtitle-item">
                                                <div v-if="editingItem === `subtitle-${index}-${subIndex}`"
                                                    class="edit-input">
                                                    <input type="text" v-model="chapter.subtitles[subIndex]"
                                                        @blur="finishEdit()" @keyup.enter="finishEdit()"
                                                        :ref="`editInput-${index}-${subIndex}`">
                                                </div>
                                                <div v-else @dblclick="startEdit('subtitle', index, subIndex)">{{
                                                    subtitle }}</div>
                                                <div class="action-buttons">
                                                    <i class="el-icon-edit"
                                                        @click="startEdit('subtitle', index, subIndex)"></i>
                                                    <i class="el-icon-delete"
                                                        @click="handleCommand({ action: 'delete', cindex, index, subIndex: subIndex, type: 'subtitle' })"></i>
                                                    <i class="el-icon-plus"
                                                        @click="addNewItem('subtitle', index, subIndex)"></i>
                                                </div>
                                            </div>

                                            <!-- 在当前小节后显示新增输入框 -->
                                            <div v-if="newItemType === 'subtitle' &&
                                                newItemChapterIndex === index &&
                                                newItemSubIndex === subIndex" :key="`new-${subIndex}`"
                                                class="subtitle-item new-item">
                                                <input type="text" v-model="newItemContent" @blur="saveNewItem(cindex)"
                                                    @keyup.enter="saveNewItem(cindex)"
                                                    placeholder="Please enter the subtitle" :ref="`newInput-${index}`">
                                            </div>
                                        </template>

                                        <!-- 如果是在章节上点击添加，则在第一个小节前显示输入框 -->
                                        <div v-if="newItemType === 'subtitle' &&
                                            newItemChapterIndex === index &&
                                            newItemSubIndex === null" class="subtitle-item new-item">
                                            <input type="text" v-model="newItemContent" @blur="saveNewItem(cindex)"
                                                @keyup.enter="saveNewItem(cindex)"
                                                placeholder="Please enter the subtitle" :ref="`newInput-${index}`">
                                        </div>

                                        <!-- 添加章节的输入框 -->
                                        <div v-if="newItemType === 'chapter' && newItemChapterIndex === index"
                                            class="chapter-header new-item">
                                            <input type="text" v-model="newItemContent" @blur="saveNewItem(cindex)"
                                                @keyup.enter="saveNewItem(cindex)"
                                                placeholder="Please enter the chapter title"
                                                :ref="`newInput-chapter-${index}`">
                                        </div>
                                    </div>
                                </div>
                                <div class="btn-box">
                                    <button class="custom-btn refresh-btn" @click="showRefreshOutlineConfirm">
                                        <i class="el-icon-refresh"></i>
                                        Change the outline
                                    </button>
                                    <button @click="generateArticle(message, 'article')"
                                        class="custom-btn generate-btn">
                                        <i class="el-icon-document"></i>
                                        Generate Article
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- 功能选项网格 -->
                        <div class="options-grid" v-if="message.options">
                            <div class="option-card" v-for="(option, optIndex) in message.options" :key="optIndex"
                                @click="selectOption(option)">
                                <div class="card-header">
                                    <i :class="option.iconClass"></i>
                                    <span>{{ option.title }}</span>
                                    <i class="el-icon-arrow-right"></i>
                                </div>
                                <div class="card-desc">{{ option.description }}</div>
                            </div>
                        </div>
                        <div class="choose-box" v-if="message.choose">
                            <div class="choose-item" v-for="(choose, chooseIndex) in message.choose" :key="chooseIndex"
                                :class="{ 'active': isChooseActive(message, choose) }"
                                @click="selectChoose(message, choose)">
                                <div class="radio-circle"></div>
                                <span>{{ choose.title }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- 示例部分 -->
                    <div class="examples-section" v-if="message.examples">
                        <div class="examples-title">{{ message.examples.title }}</div>
                        <div class="example-items">
                            <span class="example-item" v-for="(example, exIndex) in message.examples.items"
                                :key="exIndex" @click="tryExample(example)">
                                {{ example.text }}
                                <span class="file-name" v-if="example.fileName">
                                    <!-- <i  class="file-icon">📄</i> -->
                                    <img :src="documentimg" class="file-icon" alt="icon" />
                                    {{ example.fileName }}

                                </span>
                                <i class="el-icon-arrow-right"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chat-box" v-if="chatmessages[chatmessages.length - 1]?.showloading">
                <div class="chatbox">
                    <div class="avatar-box">
                        <img :src="aiAvatar" class="avatar" alt="avatar" />
                    </div>
                    <div class="ai-message">
                        <div class="ai-message-content">
                            <div>{{ ai_loading.content }}
                                <span v-if="ai_loading?.chatType == 'ai_loading'" class="ai-loading">
                                    <span class="loading-text">{{ ai_loading.timetext }}</span>
                                </span>
                            </div>
                            <div class="progress-bar" v-if="ai_loading?.chatType == 'ai_loading'">
                                <div class="progress-bar-inner" :style="{ width: progressWidth + '%' }"></div>
                                <div class="progress-time">{{ formatRemainingTime }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 底部输入区域 -->
        <div class="chat-input">
            <div class="input-wrapper">
                <!-- 添加文件列表显示 -->
                <div class="file-list" v-if="selectedFiles.length > 0">
                    <div class="file-item" v-for="(file, index) in selectedFiles" :key="index">
                        <div class="file-icon">
                            <img :src="documentimg" class="file-icon" alt="icon" />
                            <span class="file-name">{{ file.name }}</span>
                        </div>
                        <div class="file-info">
                            <template v-if="file.uploadProgress < 100">
                                <div class="upload-progress">
                                    <el-progress :percentage="file.uploadProgress" :show-text="false"
                                        :stroke-width="2" />
                                    <span class="progress-text">{{ file.uploadProgress }}%</span>
                                </div>
                            </template>
                            <template v-else>
                                <span class="file-meta">{{ getFileType(file) }} · {{ formatFileSize(file.size) }}</span>
                                <i class="el-icon-close" @click.stop="removeFile(index)"></i>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="input-box">
                    <textarea v-model="combinedMessage"
                        placeholder="Click the function card above to start, Shift+enter to line break"
                        @keydown.enter="handleEnterPress" rows="1" ref="messageInput" :disabled="!canInput"></textarea>
                    <div class="input-tools">
                        <i @click="openFilePicker" class="el-icon-upload2 upload-icon"
                            :class="{ 'disabled': !canInput || selectedChooseType != 'reviewFile' }"></i>
                        <i @click="sendMessage" class="el-icon-s-promotion send-icon"
                            :class="{ 'disabled': !canInput }"></i>
                    </div>
                </div>

            </div>
            <div class="input-footer">
                Content generated by AI, for reference only, please comply with the <a href="/privacy">Privacy User
                    Agreement</a> and <a href="/privacy">Privacy Personal Information Protection Rules</a>
            </div>
        </div>

        <!-- 添加隐藏的文件输入框 -->
        <input type="file" ref="fileInput" style="display: none" accept=".pdf" multiple @change="handleFileSelect" />

        <!-- 引入弹窗组件 -->
        <refresh-outline-dialog :visible.sync="refreshOutlineDialogVisible" @confirm="handleRefreshOutlineConfirm" />
    </div>
</template>

<script>
import RefreshOutlineDialog from '@/views/deepresearch/popups/RefreshOutlineDialog.vue'
import Quill from 'quill'
import 'quill/dist/quill.snow.css'
import { saveAs } from 'file-saver'
import { Document, Packer, Paragraph, TextRun } from 'docx'

export default {
    name: 'CreateChat',
    components: {
        RefreshOutlineDialog
    },
    data() {
        return {
            prompt: '',
            inputMessage: '',
            userAvatar: require('@/assets/images/home/userimg.png'),
            aiAvatar: require('@/assets/images/home/easynoteai.png'),
            documentimg: require('@/assets/images/index/icons/3.png'),

            articleobj: {
                type: 'ai',
                title: 'Paper Assistant',
                content: 'The little orange can be your paper assistant, please choose the following way',
                issave: false,
                choose: [
                    {
                        type: 'articleInput',
                        title: 'Input Title',
                        prompt: 'Help me write a paper, title is:'
                    },
                    // {
                    //     type: 'articleFile',
                    //     title: 'Reference Example',
                    //     prompt: 'Please refer to these examples, help me write a paper, input title:'
                    // }
                ]
            },
            reviewobj: {
                type: 'ai',
                title: 'Review',
                content: 'The little orange can help you search for literature, generate summaries and references, you can choose the following way to start',
                issave: false,
                choose: [
                    // {
                    //     type: 'reviewInput',
                    //     title: 'Input Title',
                    //     prompt: 'Help me write a review, title is:'
                    // },
                    {
                        type: 'reviewFile',
                        title: 'Reference Existing Documents',
                        prompt: 'Help me write a review, reference existing documents, input title:'
                    }
                ]
            },
            aiMessage: {
                type: 'ai',
                content: '',
            },

            ai_loading: {
                type: 'ai',
                title: '',
                chatType: 'ai_loading',
                content: 'OK, the background is working hard to help you generate a draft',
                timetext: 'Estimated waiting time 10 minutes'
            },

            // 功能选项
            chatTitle: '',
            chatmessagesid: '',
            chatmessages: [
                {
                    type: 'ai',
                    title: 'Long Text Writing',
                    content: 'Finally, the little orange can help you write a 10,000-word long article, you can choose the following way to start the intelligent creation journey～',
                    issave: false,
                    options: [
                        {
                            icon: require('@/assets/images/home/supports.png'),
                            iconClass: 'el-icon-document',
                            title: 'Paper Assistant',
                            type: 'article',
                            description: 'Based on professional knowledge base, it can generate 30,000 words in minutes'
                        },
                        {
                            icon: require('@/assets/images/home/supports.png'),
                            iconClass: 'el-icon-notebook-2',
                            title: 'Review',
                            type: 'review',
                            description: 'Search for materials, generate summaries and references'
                        }
                    ],
                    // examples: {
                    //     title: '可以试试以下示例：',
                    //     items: [
                    //         {
                    //             type: '新能源',
                    //             text: '帮我生成一篇关于新能源汽车行业的研究报告'
                    //         },
                    //         {
                    //             type: 'AI',
                    //             text: '生成一篇标题为《人工智能时代知识生产范式变革》的论文'
                    //         },
                    //         {
                    //             type: 'AI',
                    //             text: '参考文档生成论文：延迟退休对年轻人的影响',
                    //             fileName: '延迟退休对年轻人的影响.pdf'
                    //         }
                    //     ]
                    // }
                },
                // {
                //     type: 'user',
                //     content: '帮我写一篇论文，标题是;ceshiceshi'
                // },
                // {
                //     type: 'ai',
                //     showloading: true,
                //     articleid: '1234567890',

                // }
            ],
            outlinedata: {
                type: 'ai',
                content: 'The outline of the research report has been generated for you, you can directly modify it, or continue to generate a long text',
                chatType: 'outline',
                title: 'The Development History of Artificial Intelligence',
                outline: {
                    outline: [
                        {
                            "title": "I. Introduction",
                            "subtitles": [
                                "1.1 Research Background",
                                "1.2 Research Significance"
                            ]
                        },
                        {
                            "title": "II. Review",
                            "subtitles": [
                                "2.1 Domestic Research Status",
                                "2.2 Foreign Research Progress"
                            ]
                        },
                        {
                            "title": "III. The Development History of Artificial Intelligence",
                            "subtitles": [
                                "3.1 Early Development",
                                "3.2 Technical Breakthrough",
                                "3.3 Application Expansion",
                                "3.4 Current Trends",
                                "3.5 Future Prospects"
                            ]
                        },
                        {
                            "title": "IV. The Main Technologies of Artificial Intelligence",
                            "subtitles": [
                                "4.1 Machine Learning",
                                "4.2 Deep Learning",
                                "4.3 Natural Language Processing",
                                "4.4 Computer Vision",
                                "4.5 Reinforcement Learning"
                            ]
                        },
                        {
                            "title": "V. The Application Fields of Artificial Intelligence",
                            "subtitles": [
                                "5.1 Medical Health",
                                "5.2 Financial Services",
                                "5.3 Intelligent Manufacturing",
                                "5.4 Transportation"
                            ]
                        },
                        {
                            "title": "VI. Challenges and Future of Artificial Intelligence",
                            "subtitles": [
                                "6.1 Technical Challenges",
                                "6.2 Ethical and Legal Issues"
                            ]
                        }
                    ]
                }
            },

            selectedChooseType: null,
            selectedMessageId: null,
            selectedFiles: [], // 存储选中的文件
            progressWidth: 0,
            remainingTime: 10 * 60,
            progressTimer: null,
            editingItem: null, // 当前正在编辑的项目
            newItemType: null, // 新增项目类型：chapter/subtitle
            newItemContent: '', // 新增项目的内容
            newItemChapterIndex: null, // 新增小节时的章节索引
            newItemSubIndex: null, // 新增小节时的位置索引
            refreshOutlineDialogVisible: false,
            uploadProgress: 0,
            isSending: false, // 添加发送状态标记
            debounceTimer: null, // 添加防抖定时器
            quillEditors: {}, // 存储每个文章的编辑器实例
            saveTimer: null, // 用于存储定时器
            pollTimer: null, // 添加轮询定时器变量
        }
    },
    computed: {

        formatRemainingTime() {
            const minutes = Math.floor(this.remainingTime / 60)
            const seconds = this.remainingTime % 60
            return `${minutes}:${seconds.toString().padStart(2, '0')}`
        },
        canInput() {
            // 这里可以添加其他条件来控制输入框状态
            return !this.chatmessages[this.chatmessages.length - 1]?.showloading && this.combinedMessage.trim() !== '';
        },
        // 计算属性：组合 prompt 和 inputMessage
        combinedMessage: {
            get() {
                return this.prompt + this.inputMessage
            },
            set(value) {
                // 如果value等于prompt，说明用户已经删除到只剩prompt
                if (value === this.prompt) {
                    return;
                }
                // 如果value的开头包含prompt，则从value中移除prompt部分作为inputMessage
                if (value.startsWith(this.prompt)) {
                    this.inputMessage = value.slice(this.prompt.length);
                }
            }
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        formattedText(text) {
            console.log('text', text)
            if (text) {
                return text.replace(/\n/g, '<br/>');
            }
            return '';
        },
        selectOption(option) {
            const messageId = Date.now().toString()

            if (option.type === 'article') {
                // 深拷贝
                const articleobj = JSON.parse(JSON.stringify(this.articleobj))
                articleobj.id = messageId
                this.chatmessages.push(articleobj)
                this.selectChoose(articleobj, articleobj.choose[0])
            } else if (option.type === 'review') {
                const reviewobj = JSON.parse(JSON.stringify(this.reviewobj))
                reviewobj.id = messageId
                this.chatmessages.push(reviewobj)
                this.selectChoose(reviewobj.choose, reviewobj.choose[0])
            }

            // 滚动条滚动到底部
            this.$nextTick(() => {
                const el = this.$refs.chatContent
                el.scrollTop = el.scrollHeight

                // 聚焦输入框
                if (this.$refs.messageInput) {
                    this.$refs.messageInput.focus()
                }
            })
        },
        tryExample(example) {
            // 尝试示例
            const message = {
                id: Date.now().toString(),
                type: 'user',
                content: example.text,
            }

            // 添加到chatmessages
            this.chatmessages.push(message)

            this.createOutline(example.text)

            this.$nextTick(() => {
                // 滚动到底部
                const el = this.$refs.chatContent
                el.scrollTop = el.scrollHeight
            })
        },
        async getChatMessage(chat_id) {
            try {
                const response = await this.$axios.post('/api/getchatdetail/', {
                    chat_id: chat_id
                });
                const resdata = response.data;
                if (resdata.code == 200) {
                    // 对话至少有一条ai的消息
                    let hasAiMessage = false;
                    if (resdata.data.messageList.length > 0) {

                        // 检查是否至少有一条ai的消息
                        for (let i = 0; i < resdata.data.messageList.length; i++) {
                            if (resdata.data.messageList[i].type === 'ai' && !resdata.data.messageList[i].error) {
                                hasAiMessage = true;
                                break;
                            }
                        }
                        if (hasAiMessage) {
                            this.chatmessages = resdata.data.messageList;
                        } else {
                            // 合并
                            this.chatmessages = [...this.chatmessages, ...resdata.data.messageList];
                        }
                    }
                    if (resdata.data.title) {
                        this.chatTitle = resdata.data.title;
                    }
                    if (resdata.data.chat_id) {
                        this.chatmessagesid = resdata.data.chat_id;
                    }
                }
            } catch (error) {
                this.$message.error('Failed to get chat messages, please try again');
            }
        },
        async sendMessage() {
            // 如果正在发送中，直接返回
            if (this.isSending) {
                this.$message.warning('Message is being sent, please wait...');
                return;
            }

            // 清除之前的定时器
            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer);
            }

            // 设置防抖延迟
            this.debounceTimer = setTimeout(async () => {
                try {
                    if (!this.canInput) return;
                    if (this.inputMessage.trim() == '') {
                        this.$message.error('Please enter a title');
                        return;
                    }

                    // 检查是否选择了功能类型
                    if (!this.selectedChooseType) {
                        this.$message.error('Please select a function type');
                        return;
                    }

                    // 设置发送状态
                    this.isSending = true;

                    if (this.combinedMessage.trim() || this.selectedFiles?.length > 0) {
                        const message = {
                            type: 'user',
                            content: this.combinedMessage,
                            files: this.selectedFiles.map(file => ({
                                fileKey: file.fileKey,
                                name: file.name,
                                type: this.getFileType(file),
                                size: file.size
                            }))
                        }

                        this.chatmessages.push(message)
                        // 根据选择的功能类型执行不同操作
                        switch (this.selectedChooseType) {
                            case 'articleInput':
                                if (this.inputMessage.trim() == '') {
                                    this.$message.error('Please enter a title');
                                    return;
                                }
                                await this.createOutline(this.inputMessage);
                                break;
                            case 'articleFile':
                                if (this.selectedFiles.length === 0) {
                                    this.$message.error('Please upload files');
                                    return;
                                }
                                await this.createOutline(this.inputMessage);
                                break;
                            case 'reviewInput':
                                if (this.inputMessage.trim() == '') {
                                    this.$message.error('Please enter a title');
                                    return;
                                }
                                await this.generateArticle(this.inputMessage, 'review');
                                break;

                            case 'reviewFile':
                                if (this.selectedFiles.length === 0) {
                                    this.$message.error('Please upload reference materials');
                                    return;
                                }
                                await this.generateArticle(this.inputMessage, 'review');
                                break;
                            default:
                                this.$message.error('Unknown function type');
                                return;
                        }

                        this.$nextTick(() => {
                            // 滚动到底部
                            const el = this.$refs.chatContent
                            el.scrollTop = el.scrollHeight
                        })

                        // 清空输入框和文件列表
                        this.inputMessage = ''
                        this.prompt = ''
                        this.selectedFiles = []
                        // 重置输入框高度
                        if (this.$refs.messageInput) {
                            this.$refs.messageInput.style.height = 'auto'
                        }
                    }
                } catch (error) {
                    console.error('发送消息失败:', error);
                    this.$message.error('Failed to send message, please try again');
                } finally {
                    // 重置发送状态
                    this.isSending = false;
                }
            }, 300); // 300ms 的防抖延迟
        },
        async generateArticle(chat, type) {

            this.ai_loading.content = 'Generating a research paper...';
            this.ai_loading.timetext = 'Estimated waiting time: 20 minutes';
            this.startProgress(20 * 60);

            this.$nextTick(() => {
                // 滚动到底部
                const el = this.$refs.chatContent
                el.scrollTop = el.scrollHeight
            })
            let outline = {}
            let topic = this.inputMessage;
            let articleid = null;
            if (type == 'article') {
                outline = chat.outline
                topic = chat.title
                articleid = chat.articleid
            }
            let files = [];
            if (this.selectedFiles.length > 0) {
                files = this.selectedFiles.map(file => ({
                    fileKey: file.fileKey,
                    name: file.name,
                    type: this.getFileType(file),
                    size: file.size
                }))
            }
            try {
                const subid = this.$cookies.get('subid');
                const response = await this.$axios.post('/api/generateArticle/', {
                    chatid: this.chatmessagesid,
                    articleid: articleid,
                    topic: topic,
                    files: files,
                    outline: outline,
                    subid: subid,
                    type: type,
                    language: 'en'
                });
                const resdata = response.data;
                if (resdata.code == 200) {

                    // 清空输入框和文件列表
                    this.inputMessage = ''
                    this.prompt = ''
                    this.selectedFiles = []
                    // 重置输入框高度
                    if (this.$refs.messageInput) {
                        this.$refs.messageInput.style.height = 'auto'
                    }
                    const articleid = resdata.data.articleid;
                    if (!this.chatmessagesid) {
                        this.chatmessagesid = resdata.data.chatid;
                    }

                    // 添加loading消息
                    this.chatmessages.push({
                        type: 'ai',
                        showloading: true,
                        articleid: articleid,
                        chatType: 'article'
                    });

                    // 开始轮询大纲生成进度
                    await this.pollOutlineProgress(articleid, 'article');

                } else {
                    throw new Error(resdata.msg || 'Failed to create outline, please try again');
                }
            } catch (error) {
                this.stopPolling(); // 错误时停止轮询
                this.$message.error(error.message || 'Failed to create outline, please try again');
            }
        },
        async createOutline(content) {
            this.ai_loading.content = 'Generating an outline...';
            this.ai_loading.timetext = 'Estimated waiting time: 5 minutes';
            this.startProgress(5 * 60);
            this.chatTitle = content;
            try {
                const subid = this.$cookies.get('subid');
                const response = await this.$axios.post('/api/createOutline/', {
                    chatid: this.chatmessagesid,
                    topic: content,
                    files: this.selectedFiles,
                    language: 'en',
                    subid: subid,
                });

                if (response.data.code === 200) {

                    // 清空输入框和文件列表
                    this.inputMessage = ''
                    this.prompt = ''
                    this.selectedFiles = []
                    // 重置输入框高度
                    if (this.$refs.messageInput) {
                        this.$refs.messageInput.style.height = 'auto'
                    }
                    const articleid = response.data.data.articleid;

                    if (!this.chatmessagesid) {
                        this.chatmessagesid = response.data.data.chatid;
                    }
                    // 添加loading消息
                    this.chatmessages.push({
                        type: 'ai',
                        showloading: true,
                        articleid: articleid,
                        chatType: 'outline'
                    });
                    // 开始轮询大纲生成进度
                    await this.pollOutlineProgress(articleid, 'outline');
                } else {
                    throw new Error(response.data.msg || 'Failed to create outline');
                }
            } catch (error) {
                clearInterval(this.progressTimer);
                this.$message.error(error.message || 'Failed to create outline, please try again');

                // 添加错误消息到聊天
                this.chatmessages.push({
                    type: 'ai',
                    content: 'Sorry, an error occurred while creating the outline, please try again',
                    error: true
                });
            }
        },
        async pollOutlineProgress(articleid, type) {
            // 设置轮询间隔和最大轮询次数
            const POLL_INTERVAL = 3000; // 3秒轮询一次
            const MAX_RETRIES = 200; // 最多轮询200次
            let retryCount = 0;

            // 先停止已有的轮询
            this.stopPolling();

            const poll = async () => {
                try {
                    const res = await this.$axios.get('/api/getArticleProgress', {
                        params: { articleid: articleid, type: type }
                    });

                    const outline_data = res.data;

                    // 如果轮询已被停止,不继续处理结果
                    if (!this.pollTimer) {
                        return;
                    }

                    // 添加调试日志
                    switch (outline_data.code) {
                        case 200: // 成功生成
                            this.stopPolling();
                            clearInterval(this.progressTimer);
                            if (outline_data.data.chatType == 'outline') {
                                // 替换articleid相同的ai消息
                                this.chatmessages = this.chatmessages.map(message => {
                                    if (message.articleid == outline_data.data.articleid) {
                                        return outline_data.data;
                                    }
                                    return message;
                                });
                            }
                            if (outline_data.data.chatType == 'article') {
                                const chat = {
                                    type: 'ai',
                                    content: outline_data.data.article,
                                    chatType: 'article',
                                    title: outline_data.data.title,
                                }
                                // 替换articleid相同的chattype为article的消息
                                this.chatmessages = this.chatmessages.map(message => {
                                    if (message.articleid == outline_data.data.articleid && message.chatType == 'article') {
                                        return chat;
                                    }
                                    return message;
                                });
                            }
                            break;

                        case 201: // 正在生成
                            retryCount++;
                            if (retryCount < MAX_RETRIES) {
                                // 清除旧的定时器,创建新的
                                clearTimeout(this.pollTimer);
                                this.pollTimer = setTimeout(poll, POLL_INTERVAL);
                            } else {
                                this.stopPolling();
                                throw new Error('Generation timed out, please try again later');
                            }
                            break;

                        case 400: // 生成失败
                            this.stopPolling();
                            clearInterval(this.progressTimer);
                            this.chatmessages.push({
                                type: 'ai',
                                content: outline_data.msg || 'Generation failed, please try again',
                                error: true
                            });
                            break;

                        case 500: // 服务器错误
                            this.stopPolling();
                            clearInterval(this.progressTimer);
                            this.chatmessages.push({
                                type: 'ai',
                                content: outline_data.msg || 'Server error, please try again later',
                                error: true
                            });
                            break;

                        default:
                            this.stopPolling();
                            clearInterval(this.progressTimer);
                            this.chatmessages.push({
                                type: 'ai',
                                content: 'Unknown error, please try again',
                                error: true
                            });
                    }
                } catch (error) {

                    this.stopPolling();
                    clearInterval(this.progressTimer);

                    // 移除loading状态的消息
                    const lastMessage = this.chatmessages[this.chatmessages.length - 1];
                    if (lastMessage && lastMessage.showloading) {
                        this.chatmessages.pop();
                    }

                    // 添加具体的错误信息
                    this.chatmessages.push({
                        type: 'ai',
                        content: `Generation failed: ${error.message || 'Please try again later'}`,
                        error: true
                    });
                }
            };

            // 启动新的轮询任务
            this.pollTimer = setTimeout(poll, 0);
        },
        async savechatmessage() {
            // 将chatmessages中的issave为false的元素筛选
            const issaveMessages = this.chatmessages.filter(message => message?.issave != false)
            if (issaveMessages.length <= 0) {
                return;
            }
            //  至少有一个user消息
            const hasUserMessage = issaveMessages.some(message => message.type === 'user');
            if (!hasUserMessage) {
                return;
            }


            const subid = this.$cookies.get('subid');
            const response = await this.$axios.post('/api/upsavechat/', {
                messageList: issaveMessages,
                subid: subid,
                chatid: this.chatmessagesid,
                chattitle: this.chatTitle,
            })
            // console.log('savechatmessage res',response)
            const resdata = response.data;
            if (resdata.code == 200) {
                // this.$message.success('保存成功');
                if (!this.chatTitle) {
                    this.chatTitle = resdata.data.chattitle;
                }

                this.chatmessagesid = resdata.data.chatid;

            } else {
                this.$message.error('Failed to save');
            }
        },
        handleEnterPress(e) {
            // 如果正在输入中文（或其他IME输入），直接返回，不处理回车事件
            if (e.isComposing) {
                return;
            }

            if (e.shiftKey) {
                document.execCommand('insertLineBreak')
                // 换行后滚动到底部
                this.$nextTick(() => {
                    const el = this.$refs.messageInput
                    el.scrollTop = el.scrollHeight
                })
            } else {
                // 普通 Enter，检查是否可以提交
                if (!this.canInput || !this.combinedMessage.trim()) {
                    return;
                }
                e.preventDefault();
                this.sendMessage();
            }
        },
        handleInput() {
            this.inputContent = this.$refs.messageInput.value
            this.togglePlaceholder()
            this.adjustHeight()
            // 输入时如果有滚动条，滚动到底部
            this.$nextTick(() => {
                const el = this.$refs.messageInput
                if (el.scrollHeight > 120) {
                    el.scrollTop = el.scrollHeight
                }
            })
        },
        // 自动调整文本框高度
        adjustTextareaHeight() {
            const textarea = this.$refs.messageInput
            if (textarea) {
                textarea.style.height = 'auto'
                textarea.style.height = textarea.scrollHeight - 40 + 'px'
            }
        },
        selectChoose(message, choose) {
            this.selectedMessageId = message.id
            this.selectedChooseType = choose.type
            if (['articleFile', 'reviewFile'].includes(choose.type)) {
                this.openFilePicker()
            }
            this.prompt = choose.prompt
        },
        isChooseActive(message, choose) {
            // message, choose   message.id === this.selectedMessageId && 
            return choose.type === this.selectedChooseType
        },
        openFilePicker() {

            if (this.selectedChooseType != 'reviewFile') {
                this.$message.error('This function is not available in this scenario');
                return;
            }
            if (this.canInput) {
                this.$refs.fileInput.click()
            }
        },
        // 处理文件选择
        async handleFileSelect(event) {
            const files = Array.from(event.target.files);

            // 检查文件数量限制
            if (this.selectedFiles.length + files.length > 10) {
                this.$message.error('You can only upload up to 10 files');
                event.target.value = '';
                return;
            }

            // 验证文件类型和大小
            const validFiles = files.filter(file => {
                // 只允许PDF格式
                const isPDF = /\.pdf$/i.test(file.name);
                const isValidSize = file.size <= 10 * 1024 * 1024; // 10MB限制

                if (!isPDF) {
                    this.$message.error(`${file.name} Only PDF format is supported`);
                    return false;
                }
                if (!isValidSize) {
                    this.$message.error(`${file.name} Exceeds the 10MB limit`);
                    return false;
                }
                return true;
            });

            if (validFiles.length > 0) {
                // 添加文件到列表，初始化上传进度为0
                const filesWithProgress = validFiles.map(file => ({
                    file,
                    uploadProgress: 0,
                    fileKey: null,
                    url: null,
                    name: file.name,
                    type: this.getFileType(file),
                    size: file.size
                }));

                this.selectedFiles = [...this.selectedFiles, ...filesWithProgress];

                // 逐个上传文件
                for (const fileData of filesWithProgress) {
                    try {
                        const result = await this.uploadToR2(fileData.file, (progress) => {
                            // 更新上传进度
                            const fileIndex = this.selectedFiles.findIndex(f => f.name === fileData.name);
                            if (fileIndex !== -1) {
                                this.$set(this.selectedFiles[fileIndex], 'uploadProgress', Math.round(progress));
                            }
                        });

                        if (result.success) {
                            const fileIndex = this.selectedFiles.findIndex(f => f.name === fileData.name);
                            if (fileIndex !== -1) {
                                this.$set(this.selectedFiles[fileIndex], 'fileKey', result.fileKey);
                                this.$set(this.selectedFiles[fileIndex], 'url', result.url);
                                this.$set(this.selectedFiles[fileIndex], 'uploadProgress', 100);
                            }
                        }
                    } catch (error) {
                        this.$message.error(`${fileData.name} Upload failed: ${error.message}`);
                    }
                }
            }

            // 清空文件输入框，允许重复选择同一文件
            event.target.value = '';
        },
        // 移除文件
        removeFile(index) {
            this.selectedFiles.splice(index, 1);
        },
        // 获取文件类型
        getFileType(file) {
            if (!file.name) {
                return '';
            }
            const extension = file.name.split('.').pop().toLowerCase();
            const typeMap = {
                'pdf': 'PDF',
                'doc': 'Word',
                'docx': 'Word',
                'png': 'Image',
                'jpg': 'Image',
                'jpeg': 'Image'
            };
            return typeMap[extension] || 'PDF';
        },
        // 格式化文件大小
        formatFileSize(bytes) {
            if (bytes === 0) return '0 B';
            const k = 1024;
            const sizes = ['B', 'KB', 'MB', 'GB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
        },
        startProgress(totalTime) {
            this.progressWidth = 0
            this.remainingTime = totalTime

            if (this.progressTimer) {
                clearInterval(this.progressTimer)
            }

            const interval = 1000 // 每秒更新一次
            this.progressTimer = setInterval(() => {
                if (this.remainingTime > 0) {
                    this.remainingTime--

                    // 进度条
                    this.progressWidth = ((totalTime - this.remainingTime) / totalTime) * 100
                } else {
                    clearInterval(this.progressTimer)
                }
            }, interval)
        },
        // 获取当前正在编辑的大纲消息
        // @param index: 可选参数，指定要查找的消息索引，不传则查找最新的大纲消息
        getCurrentOutlineMessage(index = null) {
            if (index !== null) {
                // 如果指定了索引，直接返回该索引的消息（如果是大纲类型的话）
                const message = this.chatmessages[index];
                if (message &&
                    message.type === 'ai' &&
                    message.chatType === 'outline' &&
                    message.outline) {
                    return message;
                }
                return null;
            }

            // 如果没有指定索引，查找最新的大纲消息
            const outlineMessage = this.chatmessages.find(msg =>
                msg.type === 'ai' &&
                msg.chatType === 'outline' &&
                msg.outline
            );

            return outlineMessage;
        },
        handleCommand(command) {
            const { action, chapterIndex, index, subIndex, type } = command;
            const outlineMessage = this.getCurrentOutlineMessage(chapterIndex);
            if (!outlineMessage) return;

            if (type === 'chapter') {
                switch (action) {
                    case 'delete':
                        this.$confirm('Are you sure you want to delete this chapter? Deleting it will not be recoverable', 'Hint', {
                            confirmButtonText: 'ok',
                            cancelButtonText: 'cancel',
                            type: 'warning'
                        }).then(() => {
                            outlineMessage.outline.outline.splice(index, 1);
                            this.$message.success('Delete successful');
                        }).catch(() => { });
                        break;
                }
            } else if (type === 'subtitle') {
                switch (action) {
                    case 'delete':
                        this.$confirm('Are you sure you want to delete this section? Deleting it will not be recoverable', 'Hint', {
                            confirmButtonText: 'ok',
                            cancelButtonText: 'cancel',
                            type: 'warning'
                        }).then(() => {
                            outlineMessage.outline.outline[index].subtitles.splice(subIndex, 1);
                            this.$message.success('Delete successful');
                        }).catch(() => { });
                        break;
                }
            }
        },
        // 保存新项目
        saveNewItem(chapterIndex) {
            const outlineMessage = this.getCurrentOutlineMessage(chapterIndex);
            if (!outlineMessage || !this.newItemContent || this.newItemContent.trim() === '') {
                this.resetNewItemState();
                return;
            }

            if (this.newItemType === 'chapter') {
                // 添加新章节
                outlineMessage.outline.outline.splice(this.newItemChapterIndex + 1, 0, {
                    title: this.newItemContent.trim(),
                    subtitles: []
                });
                this.$message.success('Chapter added successfully');
            } else if (this.newItemType === 'subtitle') {
                // 添加新小节
                const chapter = outlineMessage.outline.outline[this.newItemChapterIndex];
                const insertIndex = this.newItemSubIndex !== null ? this.newItemSubIndex + 1 : 0;
                chapter.subtitles.splice(insertIndex, 0, this.newItemContent.trim());
                this.$message.success('Section added successfully');
            }

            this.resetNewItemState();
        },
        resetNewItemState() {
            this.newItemType = null;
            this.newItemContent = '';
            this.newItemChapterIndex = null;
            this.newItemSubIndex = null;
        },
        startEdit(type, chapterIndex, subIndex = null) {
            this.editingItem = subIndex === null ?
                `${type}-${chapterIndex}` :
                `${type}-${chapterIndex}-${subIndex}`;
            this.$nextTick(() => {
                const refName = subIndex === null ?
                    `editInput-${chapterIndex}` :
                    `editInput-${chapterIndex}-${subIndex}`;
                if (this.$refs[refName] && this.$refs[refName][0]) {
                    this.$refs[refName][0].focus();
                }
            });
        },
        finishEdit() {
            this.editingItem = null;
        },
        // 添加新项目
        addNewItem(type, chapterIndex, subIndex = null) {
            this.newItemType = type;
            this.newItemChapterIndex = chapterIndex;
            this.newItemSubIndex = subIndex;
            this.newItemContent = '';
            this.$nextTick(() => {
                const refName = `newInput-${type}-${chapterIndex}${subIndex !== null ? `-${subIndex}` : ''}`;
                if (this.$refs[refName] && this.$refs[refName][0]) {
                    this.$refs[refName][0].focus();
                }
            });
        },
        showRefreshOutlineConfirm() {
            if (localStorage.getItem('notShowRefreshOutlineConfirm') === 'true') {
                this.refreshOutline();
                return;
            }
            this.refreshOutlineDialogVisible = true;
        },
        handleRefreshOutlineConfirm(notShowAgain) {
            if (notShowAgain) {
                localStorage.setItem('notShowRefreshOutlineConfirm', 'true');
            }
            this.refreshOutlineDialogVisible = false;
            this.refreshOutline();
        },
        refreshOutline() {
            // TODO: 实现重新生成大纲的逻辑
            // this.chatmessages = []
            // 删除最后一次的ai消息
            this.chatmessages.pop()
            // 找到最后一次的user消息
            const lastUserMessage = this.chatmessages.findLast(msg => msg.type === 'user')
            if (lastUserMessage) {
                // 获取content中prompt以外的字符串
                const content = lastUserMessage.content.split(':')[1]


                this.createOutline(content)
            }
        },
        async uploadToR2(file, onProgress) {
            try {
                const subid = this.$cookies.get('subid');
                // 1. 获取预签名URL
                const { data } = await this.$axios.post('/api/get_upload_url/', {
                    fileName: file.name,
                    fileType: file.type,
                    comeptah: 'deepresearch',
                    subid: subid
                });

                if (!data.uploadUrl) {
                    throw new Error('Failed to get upload URL');
                }

                // 2. 使用 XMLHttpRequest 上传文件以获取进度
                return new Promise((resolve, reject) => {
                    const xhr = new XMLHttpRequest();

                    // 监听上传进度
                    xhr.upload.onprogress = (event) => {
                        if (event.lengthComputable) {
                            const percentComplete = Math.max(2, Math.floor((event.loaded / event.total) * 100));
                            // 更新文件上传进度
                            onProgress(percentComplete);
                        }
                    };

                    // 上传完成
                    xhr.onload = () => {
                        if (xhr.status === 200) {
                            resolve({
                                success: true,
                                fileKey: data.fileKey,
                                url: data.uploadUrl.split('?')[0]
                            });
                        } else {
                            reject(new Error(`Upload failed with status: ${xhr.status}`));
                        }
                    };

                    // 上传错误
                    xhr.onerror = () => {
                        reject(new Error('Network error during upload'));
                    };

                    // 开始上传
                    xhr.open('PUT', data.uploadUrl, true);
                    xhr.setRequestHeader('Content-Type', file.type);
                    xhr.send(file);
                });

            } catch (error) {
                console.error('Upload failed:', error);
                this.$message.error(`Upload failed: ${error.message}`);
                throw error;
            }
        },
        createNewChat() {
            this.chatmessages = [
                {
                    type: 'ai',
                    title: 'Long Text Writing',
                    content: 'Finally, you are here! Orange Cat can help you write a 10,000-word long text. You can choose the following ways to start your intelligent creation journey～',
                    issave: false,
                    options: [
                        {
                            icon: require('@/assets/images/home/supports.png'),
                            iconClass: 'el-icon-document',
                            title: 'Paper Assistant',
                            type: 'article',
                            description: 'Rely on professional academic resources, write 30,000 words in minutes'
                        },
                        {
                            icon: require('@/assets/images/home/supports.png'),
                            iconClass: 'el-icon-notebook-2',
                            title: 'Literature Review',
                            type: 'review',
                            description: 'Search for materials and generate summaries and references'
                        }
                    ],
                    // examples: {
                    //     title: '可以试试以下示例：',
                    //     items: [
                    //         {
                    //             type: '新能源',
                    //             text: '帮我生成一篇关于新能源汽车行业的研究报告'
                    //         },
                    //         {
                    //             type: 'AI',
                    //             text: '生成一篇标题为《人工智能时代知识生产范式变革》的论文'
                    //         },
                    //         {
                    //             type: 'AI',
                    //             text: '参考文档生成论文：延迟退休对年轻人的影响',
                    //             fileName: '延迟退休对年轻人的影响.pdf'
                    //         }
                    //     ]
                    // }
                },
            ];
            this.chatmessagesid = null;
            this.chatTitle = '';
        },
        initQuillEditor(element, content) {
            const toolbarOptions = [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ 'header': 1 }, { 'header': 2 }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],
                [{ 'indent': '-1' }, { 'indent': '+1' }],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'font': [] }],
                [{ 'align': [] }],
                ['clean']
            ]

            const quill = new Quill(element, {
                modules: {
                    toolbar: toolbarOptions
                },
                theme: 'snow'
            })

            // 设置初始内容
            quill.root.innerHTML = content

            return quill
        },
        async downloadAsWord(message) {
            try {
                const editor = this.quillEditors[message.id]
                if (!editor) return

                const content = editor.root.innerHTML

                // 创建文档对象
                const doc = new Document({
                    sections: [{
                        properties: {},
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun(content.replace(/<[^>]+>/g, '')) // 移除HTML标签
                                ],
                            }),
                        ],
                    }],
                })

                // 生成blob
                const blob = await Packer.toBlob(doc)

                // 下载文件
                saveAs(blob, `${message.title || 'Document'}.docx`)

                this.$message.success('Document downloaded successfully')
            } catch (error) {
                console.error('Download document failed:', error)
                this.$message.error('Download document failed, please try again')
            }
        },
        openEditor(message) {
            // 将编辑器数据存储到 localStorage
            localStorage.setItem('editorContent', message.content)
            localStorage.setItem('editorTitle', message.title)

            // 加密 message.id
            const encryptedId = btoa(message.id)

            // 构建URL参数
            const params = new URLSearchParams({
                title: message.title || 'Untitled Document',
                messageId: encryptedId || '',
                chatId: this.chatmessagesid || '',
                type: message.type || '',
                chatType: message.chatType || ''
            })

            // 构建完整的URL
            const editorUrl = `/editor?${params.toString()}`

            // 打开新窗口
            const editorWindow = window.open(editorUrl, '_blank')

            // 确保新窗口成功打开
            if (editorWindow) {
                editorWindow.focus()
            } else {
                this.$message.error('Please allow the new window to be opened')
            }
        },
        // 自定义防抖函数
        debounce(fn, delay = 2000) {
            if (this.saveTimer) {
                clearTimeout(this.saveTimer)
            }
            this.saveTimer = setTimeout(() => {
                fn.call(this)
                this.saveTimer = null
            }, delay)
        },
        // 修改调用方式
        handleMessageChange() {
            // ... existing code ...
            this.debounce(this.savechatmessage)
        },
        // 添加停止轮询的方法
        stopPolling() {
            if (this.pollTimer) {
                clearTimeout(this.pollTimer);
                this.pollTimer = null;
            }
        },
    },
    watch: {
        // 监听输入内容变化，自动调整高度
        inputMessage() {
            this.$nextTick(this.adjustTextareaHeight)
        },
        chatmessages(newVal) {
            // 是否有正在loading的消息
            const loadingMessage = newVal.find(message => message.showloading)
            if (loadingMessage) {
                // 如果有loading消息，则启动定时器
                if (loadingMessage.chatType == 'article') {
                    this.ai_loading.content = 'Generating a paper...'
                    this.ai_loading.timetext = 'Estimated time: 20 minutes'
                    this.startProgress(20 * 60)
                }
                if (loadingMessage.chatType == 'outline') {
                    this.ai_loading.content = 'Generating an outline...'
                    this.ai_loading.timetext = 'Estimated time: 5 minutes'
                    this.startProgress(5 * 60)
                }
                this.pollOutlineProgress(loadingMessage.articleid, loadingMessage.chatType)

            }
            this.handleMessageChange()

        },
        '$route'(to,) {
            if (to.query.chat_id) {
                this.getChatMessage(to.query.chat_id)
            } else {
                this.createNewChat()
            }
        }
    },
    mounted() {
        // 初始化时设置文本框高度
        this.adjustTextareaHeight()

        // 获取chat_id
        const chat_id = this.$route?.query?.chat_id
        if (chat_id) {
            this.getChatMessage(chat_id)
        }
    },
    beforeDestroy() {
        if (this.progressTimer) {
            clearInterval(this.progressTimer)
        }
        // 组件销毁前清除定时器
        if (this.saveTimer) {
            clearTimeout(this.saveTimer)
        }
        this.stopPolling(); // 组件销毁时停止轮询
    }
}
</script>

<style lang="less" scoped>
@import '@/assets/styles/deepresearch.less';

.create-chat-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    position: relative;
    align-items: center;

    .chat-header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 100;
        background: #f1f1f1;

        .header-left {
            display: flex;
            align-items: center;
            cursor: pointer;

            .back-icon {
                margin-right: 8px;
            }
        }

        .header-title {
            font-size: 16px;
            font-weight: 500;
        }

        .header-right {
            display: flex;
            align-items: center;
            gap: 16px;

            .notification {
                position: relative;

                .badge {
                    position: absolute;
                    top: -8px;
                    right: -8px;
                    background: #ff4d4f;
                    color: #fff;
                    border-radius: 10px;
                    padding: 0 6px;
                    font-size: 12px;
                }
            }

            .premium-btn {
                display: flex;
                align-items: center;
                gap: 4px;
                background: #f0f0f0;
                border: none;
                padding: 6px 12px;
                border-radius: 16px;
                cursor: pointer;
            }

            .avatar {
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }
        }
    }

    .chat-content {
        flex: 1;
        overflow-y: auto;
        margin-left: 10px;
        width: calc(100% - 10px);
        padding: 80px 0 160px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .chat-box {
            width: 60%;
        }

        .chatbox {
            width: 100%;
            position: relative;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .avatar-box {
                position: absolute;
                top: 5px;
                left: -45px;

                img {
                    width: 30px;
                    height: 30px;

                }

                .user-avatar {
                    background-color: #aaa;
                    padding: 5px;
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
                }
            }

            .user-message {
                background: #0000000d;
                border-radius: 12px;
                padding: 12px;
                max-width: 100%;
                color: #222;
                font-size: 15px;

                .file-list {
                    display: flex;
                    gap: 5px;
                    margin-top: 10px;
                    flex-wrap: wrap;

                    .file-item {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 5px;
                        background: #fff;
                        padding: 5px 10px;
                        border-radius: 15px;
                        font-size: 13px;

                        .file-icon {
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            img {
                                width: 25px;
                                height: 25px;
                            }

                            i {
                                font-size: 20px;
                            }

                            span {
                                font-size: 15px;
                                max-width: 100px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }

                        .file-info {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            font-size: 12px;
                            color: #999;
                            margin-left: 2px;
                        }
                    }
                }
            }

            .message-content {
                color: #555;
                font-size: 14px;
                width: auto;
                max-width: 100%;
                padding: 10px;
                word-wrap: break-word;
                background: #fff;
                border-radius: 12px;
                padding: 12px;
                max-width: 100%;
            }

            .ai-message {
                background: #fff;
                border-radius: 12px;
                padding: 12px;
                max-width: 100%;

                .message-header {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    margin-bottom: 10px;

                    .ai-avatar {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }

                    .writing-title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between; // 修改为两端对齐
                        width: 100%;

                        .el-button {
                            margin-left: 10px;

                            i {
                                margin-right: 5px;
                            }
                        }
                    }
                }

                .ai-message-content {
                    color: #555;
                    font-size: 14px;
                    width: auto;
                    max-width: 100%;
                    word-wrap: break-word;

                    .ai-loading {
                        color: #999;
                        font-size: 13px;
                    }

                    .file-item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 8px;
                        background: #fff;
                        padding: 10px 15px;
                        border-radius: 15px;
                        font-size: 13px;
                        border: 1px solid #ccc;
                        box-shadow: 0 0 2px #ccc;
                        cursor: pointer;

                        &:hover {
                            border: 1px solid #1890ff;
                            box-shadow: 0 0 2px #1890ff;
                        }

                        i {
                            font-size: 24px;
                            cursor: pointer;
                        }

                        .file-icon {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            gap: 5px;

                            .file-name {
                                max-width: 100px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            img {
                                width: 25px;
                                height: 25px;
                            }

                            span {
                                font-size: 15px;
                                max-width: 100px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }


                    }
                }


            }

            .options-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 16px;
                margin-top: 10px;
            }

            .option-card {
                background: #f7f8fa;
                border-radius: 8px;
                padding: 16px;
                cursor: pointer;

                &:hover {
                    background: #f0f0f0;
                }

                .card-header {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin-bottom: 8px;

                    .cc-option-icon {
                        width: 20px;
                        height: 20px;
                    }

                    i {
                        font-size: 20px;
                        color: #1890ff;
                    }

                    .el-icon-arrow-right {
                        margin-left: auto;
                        color: #999;
                    }
                }

                .card-desc {
                    color: #999;
                    font-size: 14px;
                }
            }
        }
    }

    .chat-input {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #f1f1f1;
        padding: 10px;
        text-align: center;

        .input-wrapper {
            position: relative;
            margin-bottom: 12px;
            width: 60%;
            display: inline-block;

            .file-list {
                margin: 8px 0;
                padding: 0 12px;
                max-height: 120px;
                overflow-y: auto;
                display: flex;
                flex-wrap: wrap;
                gap: 5px;

                &::-webkit-scrollbar {
                    width: 4px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #ddd;
                    border-radius: 2px;
                }

                .file-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 8px 12px;
                    background: #f7f8fa;
                    border-radius: 6px;
                    margin-bottom: 8px;
                    transition: all 0.3s ease;


                    &:hover {
                        background: #f0f0f0;
                    }

                    .file-icon {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        img {
                            width: 16px;
                            height: 16px;
                            object-fit: contain;
                        }

                        .file-name {
                            font-size: 13px;
                            color: #333;
                            max-width: 120px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    .file-info {
                        display: flex;
                        align-items: center;
                        gap: 12px;

                        .file-meta {
                            font-size: 12px;
                            color: #999;
                        }

                        .el-icon-close {
                            cursor: pointer;
                            padding: 4px;
                            font-size: 14px;
                            color: #999;
                            transition: all 0.3s ease;

                            &:hover {
                                color: #ff4d4f;
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }

            .input-box {
                position: relative;

                textarea {
                    width: 100%;
                    padding: 20px 12px;
                    border: 1px solid #000;
                    border-radius: 15px;
                    font-size: 14px;
                    resize: none;
                    min-height: 20px;
                    max-height: 120px;
                    line-height: 1.5;
                    overflow-y: auto;

                    &:focus {
                        outline: none;
                        border-color: #1890ff;
                    }

                    &:disabled {
                        background-color: #f5f5f5;
                        cursor: not-allowed;
                        opacity: 0.7;
                    }
                }

                .input-tools {
                    position: absolute;
                    right: -10px;
                    top: 45%;
                    transform: translateY(-50%);
                    display: flex;
                    gap: 12px;

                    .tool-icon {
                        width: 40px;
                        height: 40px;
                        cursor: pointer;
                        border: 1px solid #000;
                    }

                    i {
                        font-size: 26px;
                        font-weight: bold;
                        padding: 4px 6px;
                        cursor: pointer;
                        margin-left: 5px;
                        border-radius: 15px;

                        &.disabled {
                            opacity: 0.5;
                            cursor: not-allowed;
                            color: #999;
                            border: 1px solid #999;
                            box-shadow: 0 0 2px #999;

                            &:hover {
                                color: #999;
                            }
                        }
                    }

                    .upload-icon {
                        border: 1px solid #008c8c;
                        box-shadow: 0 0 2px #008c8c;
                        color: #008c8c;

                        &:hover {
                            border: 1px solid #45A049;
                            box-shadow: 0 0 2px #45A049;
                            color: #45A049;
                        }
                    }

                    .send-icon {
                        border: 1px solid #002FA7;
                        box-shadow: 0 0 2px #002FA7;
                        color: #002FA7;

                        &:hover {
                            border: 1px solid #1E88E5;
                            box-shadow: 0 0 2px #1E88E5;
                            color: #1E88E5;
                        }
                    }


                }
            }
        }

        .input-footer {
            text-align: center;
            color: #999;
            font-size: 12px;
        }
    }
}

.examples-section {
    margin-top: 15px;

    .examples-title {
        color: #909090;
        margin-bottom: 5px;
        font-size: 14px;
    }

    .example-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;

        .example-item {
            color: #333;
            padding: 8px 10px;
            cursor: pointer;
            background-color: #fff;
            font-size: 13px;
            margin: 5px 0;
            border-radius: 8px;
            display: flex;
            align-items: center;
            gap: 5px;

            .file-name {
                color: #909090;
                font-size: 12px;
                display: flex;
                align-items: center;
                gap: 5px;

                img {
                    width: 15px;
                    height: 15px;
                }
            }

            &:hover {
                color: #1890ff;
            }
        }
    }
}

.choose-box {
    display: flex;
    gap: 20px;
    margin-top: 16px;

    .choose-item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 5px 16px;
        background: #fff;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s ease;
        background: #f6f9ff;

        .radio-circle {
            width: 16px;
            height: 16px;
            border: 2px solid #d9d9d9;
            border-radius: 50%;
            position: relative;
            transition: all 0.3s ease;

            &:after {
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                background: #1890ff;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(0);
                transition: transform 0.2s ease;
            }
        }

        span {
            font-size: 14px;
            color: #333;
            transition: color 0.3s ease;
        }

        &:hover {
            border-color: #1890ff;

            .radio-circle {
                border-color: #1890ff;
            }
        }

        &.active {
            border-color: #1890ff;
            background: #f6f9ff;

            .radio-circle {
                border-color: #1890ff;

                &:after {
                    transform: translate(-50%, -50%) scale(1);
                }
            }

            span {
                color: #1890ff;
                font-weight: 500;
            }
        }
    }
}

// 可以添加文件列表的样式
.file-list {
    margin-top: 10px;

    .file-item {
        display: flex;
        align-items: center;
        padding: 8px;
        background: #f7f8fa;
        border-radius: 4px;
        margin-bottom: 8px;

        .file-icon {
            margin-right: 8px;
            color: #1890ff;
        }

        .file-info {
            flex: 1;

            .file-name {
                font-size: 14px;
                color: #333;
            }

            .file-meta {
                font-size: 12px;
                color: #999;
            }
        }
    }
}

.progress-bar {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: #f0f0f0;
    border-radius: 2px;
    margin-top: 8px;
    overflow: hidden;

    .progress-bar-inner {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background-color: #1890ff;
        transition: width 1s linear;
    }

    .progress-time {
        position: absolute;
        right: 0;
        top: -20px;
        font-size: 12px;
        color: #999;
    }
}

.outline-box {
    margin-top: 16px;
    background: #fff;
    min-width: 680px;

    .outline-container {
        max-height: 600px;
        overflow-y: auto;
        padding: 0 20px;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 4px;
        }

        .chapter-item {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }


            .chapter-header {
                font-size: 15px;
                font-weight: 500;
                color: #333;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                padding: 8px 16px;
                border-radius: 4px;
                position: relative;

                input {
                    width: 100%;
                    padding: 6px 8px;
                    border: 1px solid #1890ff;
                    border-radius: 4px;
                    font-size: inherit;
                    outline: none;
                    background: transparent;
                }

                .action-buttons {
                    opacity: 0;
                    transition: opacity 0.2s;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    i {
                        font-size: 16px;
                        color: #999;
                        cursor: pointer;
                        padding: 4px;
                        border-radius: 4px;
                        height: 100%;
                        display: flex;
                        align-items: center;

                        &:hover {
                            color: #1890ff;
                            background-color: rgba(24, 144, 255, 0.1);
                        }

                        &.el-icon-delete:hover {
                            color: #ff4d4f;
                            background-color: rgba(255, 77, 79, 0.1);
                        }

                        &.el-icon-edit:hover {
                            color: #52c41a;
                            background-color: rgba(82, 196, 26, 0.1);
                        }

                        &.el-icon-plus:hover {
                            color: #1890ff;
                            background-color: rgba(24, 144, 255, 0.1);
                        }
                    }
                }

                &:hover {
                    background: #f0f0f0;

                    .action-buttons {
                        opacity: 1;
                    }
                }
            }

            .subtitle-item {
                font-size: 14px;
                color: #666;
                padding: 12px 16px;
                margin-left: 20px;
                border-radius: 4px;
                transition: all 0.2s ease;
                cursor: pointer;
                position: relative;

                input {
                    width: calc(100% - 140px);
                    padding: 6px 8px;
                    border: 1px solid #7d7d7d;
                    border-radius: 4px;
                    font-size: inherit;
                    outline: none;
                    background: transparent;
                }

                .action-buttons {
                    position: absolute;
                    right: 12px;
                    top: 50%;
                    transform: translateY(-50%);
                    opacity: 0;
                    transition: opacity 0.2s;
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    i {
                        font-size: 16px;
                        color: #999;
                        cursor: pointer;
                        padding: 4px;
                        border-radius: 4px;
                        height: 100%;
                        display: flex;
                        align-items: center;

                        &:hover {
                            color: #1890ff;
                            background-color: rgba(24, 144, 255, 0.1);
                        }

                        &.el-icon-delete:hover {
                            color: #ff4d4f;
                            background-color: rgba(255, 77, 79, 0.1);
                        }

                        &.el-icon-edit:hover {
                            color: #52c41a;
                            background-color: rgba(82, 196, 26, 0.1);
                        }

                        &.el-icon-plus:hover {
                            color: #1890ff;
                            background-color: rgba(24, 144, 255, 0.1);
                        }
                    }
                }

                &:hover {
                    background: #f0f0f0;

                    .action-buttons {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.edit-input,
.new-item {
    input {
        width: 100%;
        padding: 4px 8px;
        border: 1px solid #1890ff;
        border-radius: 4px;
        font-size: inherit;
        outline: none;
        background: transparent;

        &:focus {
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        }
    }
}

.new-item {
    margin: 8px 0;
    padding: 8px 16px;
    background: #f7f7f7;
    border-radius: 4px;
}

.btn-box {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    gap: 16px;

    .custom-btn {
        padding: 10px 24px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        gap: 8px;
        border: none;
        outline: none;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

        i {
            font-size: 16px;
        }

        &:active {
            transform: translateY(1px);
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
        }

        &.refresh-btn {
            background-color: #f8f9fa;
            color: #7c8590;
            border: 1px solid #e9ecef;

            &:hover {
                background-color: #f1f3f5;
                border-color: #dee2e6;
                color: #6c757d;
            }

            i {
                color: #adb5bd;
            }
        }

        &.generate-btn {
            background: linear-gradient(135deg, #74b9ff 0%, #63a4ff 100%);
            color: white;

            &:hover {
                background: linear-gradient(135deg, #81c0ff 0%, #74b9ff 100%);
            }

            i {
                color: white;
            }
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;

            &:active {
                transform: none;
            }
        }
    }
}

// 适配暗色标题
@media (prefers-color-scheme: dark) {
    .btn-box {
        .custom-btn {
            &.refresh-btn {
                background-color: #2d3035;
                color: #a9b0ba;
                border-color: #3a3d42;

                &:hover {
                    background-color: #33363c;
                    border-color: #424548;
                    color: #bdc3cc;
                }

                i {
                    color: #8b929b;
                }
            }

            &.generate-btn {
                background: linear-gradient(135deg, #5a95db 0%, #4b7fc2 100%);

                &:hover {
                    background: linear-gradient(135deg, #689ee3 0%, #5a95db 100%);
                }
            }
        }
    }
}

.article-editor {
    width: 100%;

    .ql-container {
        min-height: 200px;
        font-size: 14px;
    }

    .editor-actions {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;

        .download-btn {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 8px 16px;
            border: none;
            border-radius: 4px;
            background: #1890ff;
            color: white;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                background: #40a9ff;
            }

            i {
                font-size: 16px;
            }
        }
    }
}

:deep(.ql-toolbar) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

:deep(.ql-container) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
</style>

<style>
.el-dropdown-menu {
    padding: 4px 0;
}

.el-dropdown-menu__item {
    font-size: 14px;
    padding: 8px 16px;
    line-height: 1;

    &:hover {
        background-color: #f0f0f0;
        color: #1890ff;
    }
}
</style>