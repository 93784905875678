<template>
  <div :style="aboutStyle" class="about">
    <div class="title">
      <span>{{ $t('about.title') }}</span>
    </div>
    <section>
      <p>
        {{ $t('about.p1') }}
      </p>
      <p>
        {{ $t('about.p2') }}
      </p>
      <p>
        {{ $t('about.p3') }}
      </p>
      <p>
        {{ $t('about.p4') }}
      </p>
    </section>

    <section>
      <p>
        {{ $t('about.p5') }}
        <span @click="copyemail()" class="us_email">easynote_ai@hotmail.com</span>
      </p>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      language: localStorage.getItem('language') || 'en', // 从 localStorage 获取语言，默认为英文
    };
  },
  computed: {
    aboutStyle() {
      // 根据语言决定 margin-bottom 样式
      return {
        marginBottom: this.language === 'zh' ? '220px' : '0px', // 如果语言是中文，设置 margin-bottom 为 100px
      };
    },
  },
  methods: {
    copyemail() {
      let text = document.querySelector(".us_email").innerText;
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.value = text;
      input.select();
      document.execCommand("Copy");
      input.remove();
      this.$message.success("Email has been copied to your clipboard");
    },
  },
};
</script>
<style lang="less" scoped>
.about {
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #fff;
  position: relative;
  z-index: 10; //背景图影响点击按钮
  width: 60%;
  min-height: 580px;
  section {
    opacity: 0.8; //文字透明度
  }

  .title {

    // margin-bottom: 1vw;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 6vw;
    margin-top: 4vw;
    // background-image: url("../../../assets/images/about_bgimg.jpg");
    // background-size: cover;
    /* 让背景图覆盖整个容器 */
    // background-position: center;
    /* 背景图居中显示 */
    // background-repeat: no-repeat;
    // padding-left: 100px;
    // padding-right: 100px;

    color: #fff;
    font-size: 36px;
    font-weight: bold;
    // filter: blur(10px);
    // background: red;


    // background: linear-gradient(90deg,
    //     #fff 0%,
    //     #9074ff 25%,
    //     #924ef0 50%,
    //     #b76bf8 75%,
    //     #f299f6 100%);
    // background: #fff;
    // text-shadow: 0 0 4px #b36ef9, 0 0 4px #7d6eee, 0 0 4px #6e66e6;
    // background-size: 200% auto;
    /* 让渐变颜色移动 */
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // animation: glow-animation 2s linear infinite;
    /* 动画循环播放 */
    // background-image: linear-gradient(to right, #a69dff, #b987ff, #fab3fd);

    /* 设置渐变色 */
    // -webkit-background-clip: text;
    /* Safari 支持 */
    // background-clip: text;
    /* Chrome 支持 */
    // color: transparent;
    /* 让文字颜色变透明，这样才会显示背景渐变 */
    /* 不重复背景图 */
    span {
      // background: linear-gradient(90deg, #5c4afc, #924ef0, #f299f6);

      background: rgba(255, 255, 255, 0.9);
      // background-size: 300% auto;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      // animation: glow-animation 5s ease-in-out infinite;
    }

    @keyframes glow-animation {
      0% {
        background-position: 0% 50%;
      }

      100% {
        background-position: 100% 50%;
      }
    }
  }



  p {

    margin-bottom: 1vw;
    line-height: 1.6;
    font-size: 1.05vw;
  }

  .us_email {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
