<template>
    <div class="dhome-chat-container background-color">
        <!-- 顶部导航 -->
        <div class="chat-header">
            <div class="header-title">{{ chatTitle ? chatTitle : 'Untitled Conversation' }}</div>
        </div>
        <!-- 聊天内容区域 -->
        <div class="chat-content" ref="chatContent">
            <div class="chat-box">
                <div class="chatbox" v-for="(message, aindex) in aichatmessages" :key="aindex">
                    <!-- 头像 -->
                    <div class="avatar-box">
                        <img v-if="message.type === 'user'" :src="userAvatar" class="avatar" alt="avatar" />
                        <img v-else :src="aiAvatar" class="avatar" alt="avatar" />
                    </div>
                    <div class="ai-message" v-if="message.type === 'ai'">
                        <div class="message-header" v-if="message.title">
                            <div class="writing-title">
                                <span>{{ message.title }}</span>
                            </div>
                        </div>
                        <div class="ai-message-content">
                            {{ message.content }}
                        </div>
                        <!-- 功能选项网格 -->
                        <div class="options-grid" v-if="message.options">
                            <div class="option-card" v-for="(option, optIndex) in message.options" :key="optIndex"
                                @click="selectOption(option)">
                                <div class="card-header">
                                    <!-- <img :src="option.icon" class="cc-option-icon" alt="icon" /> -->
                                    <i :class="option.iconClass"></i>
                                    <span>{{ option.title }}</span>
                                    <i class="el-icon-arrow-right"></i>
                                </div>
                                <div class="card-desc">{{ option.description }}</div>
                            </div>
                        </div>
                        <div class="choose-box" v-if="message.choose">
                            <div class="choose-item" v-for="(choose, chooseIndex) in message.choose" :key="chooseIndex"
                                :class="{ 'active': isChooseActive(message, choose) }"
                                @click="selectChoose(message, choose)">
                                <div class="radio-circle"></div>
                                <span>{{ choose.title }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- 示例部分 -->
                    <div class="examples-section" v-if="message.examples">
                        <div class="examples-title">{{ message.examples.title }}</div>
                        <div class="example-items">
                            <span class="example-item" v-for="(example, exIndex) in message.examples.items"
                                :key="exIndex" @click="tryExample(example)">
                                {{ example.text }}
                                <span class="file-name" v-if="example.fileName">
                                    <!-- <i  class="file-icon">📄</i> -->
                                    <img :src="documentimg" class="file-icon" alt="icon" />
                                    {{ example.fileName }}

                                </span>
                                <i class="el-icon-arrow-right"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chat-box">
                <div class="chatbox" v-for="(message, cindex) in chatmessages" :key="cindex">
                    <div class="avatar-box">
                        <img v-if="message.type === 'user'" :src="userAvatar" class="avatar" alt="avatar" />
                        <img v-else :src="aiAvatar" class="avatar" alt="avatar" />
                    </div>
                    <div v-if="message.type === 'user'" class="user-message">
                        <div>{{ message.content }}</div>
                        <div class="file-list">
                            <div class="file-item" v-for="(file, findex) in message.files" :key="findex">
                                <div class="file-icon">
                                    <!-- <i class="el-icon-document"></i> -->
                                    <img :src="documentimg" class="file-icon" alt="icon" />
                                    <span>{{ file.name }}</span>
                                </div>
                                <div class="file-info">
                                    <span>{{ file.type }}</span>
                                    <span>{{ file.size }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="message-content">{{ message.content }}</div>
                </div>
            </div>
        </div>

        <!-- 底部输入区域 -->
        <div class="chat-input">
            <div class="input-wrapper">
                <!-- 添加文件列表显示 -->
                <div class="file-list" v-if="selectedFiles.length > 0">
                    <div class="file-item" v-for="(file, index) in selectedFiles" :key="index">
                        <div class="file-icon">
                            <img :src="documentimg" class="file-icon" alt="icon" />
                            <span class="file-name">{{ file.name }}</span>
                        </div>
                        <div class="file-info">
                            <span class="file-meta">{{ getFileType(file) }} · {{ formatFileSize(file.size) }}</span>
                            <i class="el-icon-close" @click.stop="removeFile(index)"></i>
                        </div>
                    </div>
                </div>
                <div class="input-box">
                    <textarea :disabled="true" v-model="inputMessage" placeholder="Click the function card above to start. Shift+enter to enter a new line"
                        @keydown.enter.prevent="handleEnterPress" rows="1" ref="messageInput"></textarea>
                    <div class="input-tools">
                        <i @click="openFilePicker" class="el-icon-upload2" :class="{ 'disabled': true }"></i>
                        <i @click="sendMessage" class="el-icon-s-promotion" :class="{ 'disabled': true }"></i>
                    </div>
                </div>

            </div>
            <div class="input-footer">
                Content generated by AI, for reference only, please comply with the <a href="/privacy">Privacy User Agreement</a> and <a href="/privacy">Privacy Personal Information Protection Rules</a>
            </div>
        </div>

        <!-- 添加隐藏的文件输入框 -->
        <input type="file" ref="fileInput" style="display: none" accept=".pdf" multiple
            @change="handleFileSelect" />
    </div>
</template>

<script>
export default {
    name: 'CreateChat',
    data() {
        return {
            inputMessage: '',
            userAvatar: require('@/assets/images/home/vip_icon3.png'),
            aiAvatar: require('@/assets/images/home/easynoteai.png'),
            documentimg: require('@/assets/images/index/icons/3.png'),
            // 功能选项
            aichatmessages: [
                {
                    type: 'ai',
                    title: 'Long Text Writing',
                    content: 'Finally, you are here! Orange Cat can help you write a 10,000-word long text. You can choose the following ways to start your intelligent creation journey～',
                    options: [
                        {
                            icon: require('@/assets/images/home/supports.png'),
                            iconClass: 'el-icon-document',
                            title: 'Paper Assistant',
                            type: 'article',
                            description: 'Rely on professional academic resources, write 30,000 words in minutes'
                        },
                        {
                            icon: require('@/assets/images/home/supports.png'),
                            iconClass: 'el-icon-notebook-2',
                            title: 'Literature Review',
                            type: 'review',
                            description: 'Search for materials and generate summaries and references'
                        }
                    ],
                    // examples: {
                    //     title: '可以试试以下示例：',
                    //     items: [
                    //         {
                    //             type: '新能源',
                    //             text: '帮我生成一篇关于新能源汽车行业的研究报告'
                    //         },
                    //         {
                    //             type: 'AI',
                    //             text: '生成一篇主题为《人工智能时代知识生产范式变革》的论文'
                    //         },
                    //         {
                    //             type: 'AI',
                    //             text: '参考文档生成论文：延迟退休对年轻人的影响',
                    //             fileName: '延迟退休对年轻人的影响.pdf'
                    //         }
                    //     ]
                    // }
                },

            ],
            chatTitle: '',
            chatmessages: [],
            selectedChooseType: null,
            selectedMessageId: null,
            myprompt: '',
            selectedFiles: [], // 存储选中的文件
        }
    },
    methods: {

        selectOption(option) {

            this.$emit('selectOption', option)

            // const messageId = Date.now().toString()
            // if (option.type === 'article') {
            //     // 深拷贝
            //     const articleobj = JSON.parse(JSON.stringify(this.articleobj))
            //     articleobj.id = messageId
            //     this.aichatmessages.push(articleobj)
            // } else if (option.type === 'review') {
            //     const reviewobj = JSON.parse(JSON.stringify(this.reviewobj))
            //     reviewobj.id = messageId
            //     this.aichatmessages.push(reviewobj)
            // }
            // this.selectedMessageId = messageId
            // this.selectedChooseType = null
            // // 滚动条滚动到底部
            // this.$nextTick(() => {
            //     const el = this.$refs.chatContent
            //     el.scrollTop = el.scrollHeight
            // })
        },
        tryExample(type) {
            // 尝试示例
            this.$emit('tryExample', type)
        },
        sendMessage() {
            if (this.inputMessage.trim() || this.selectedFiles.length > 0) {
                const message = {
                    type: 'user',
                    content: this.inputMessage,
                    files: this.selectedFiles.map(file => ({
                        name: file.name,
                        type: this.getFileType(file),
                        size: this.formatFileSize(file.size)
                    }))
                }

                this.chatmessages.push(message)

                console.log('Send message:', message)

                // 清空输入框和文件列表
                this.inputMessage = ''
                this.selectedFiles = []

                // 重置输入框高度
                if (this.$refs.messageInput) {
                    this.$refs.messageInput.style.height = 'auto'
                }
            }
        },
        handleEnterPress(e) {
            if (e.shiftKey) {
                // shift + enter，插入换行符
                document.execCommand('insertLineBreak')
                // 换行后滚动到底部
                this.$nextTick(() => {
                    const el = this.$refs.messageInput
                    el.scrollTop = el.scrollHeight
                })
            } else {
                // 仅按 enter，发送消息
                this.sendMessage()
            }
        },
        handleInput() {
            this.inputContent = this.$refs.messageInput.value
            this.togglePlaceholder()
            this.adjustHeight()
            // 输入时如果有滚动条，滚动到底部
            this.$nextTick(() => {
                const el = this.$refs.messageInput
                if (el.scrollHeight > 120) {
                    el.scrollTop = el.scrollHeight
                }
            })
        },
        // 自动调整文本框高度
        adjustTextareaHeight() {
            const textarea = this.$refs.messageInput
            if (textarea) {
                textarea.style.height = 'auto'
                textarea.style.height = textarea.scrollHeight - 40 + 'px'
            }
        },
        selectChoose(message, choose) {
            this.selectedMessageId = message.id
            this.selectedChooseType = choose.type
            if (['articleFile', 'reviewFile'].includes(choose.type)) {
                this.openFilePicker()
            }
            this.inputMessage = choose.prompt
        },
        isChooseActive(message, choose) {
            return message.id === this.selectedMessageId && choose.type === this.selectedChooseType
        },
        openFilePicker() {
            // 触发隐藏的文件输入框点击事件
            this.$refs.fileInput.click()
        },
        // 处理文件选择
        handleFileSelect(event) {
            const files = Array.from(event.target.files)

            // 验证文件类型和大小
            const validFiles = files.filter(file => {
                const isValidType = /\.(pdf|doc|docx|png|jpg|jpeg)$/i.test(file.name)
                const isValidSize = file.size <= 10 * 1024 * 1024 // 10MB限制

                if (!isValidType) {
                    this.$message.error(`${file.name} 格式不支持，请上传PDF或Word文档`)
                    return false
                }
                if (!isValidSize) {
                    this.$message.error(`${file.name} 超过10MB限制`)
                    return false
                }
                return true
            })

            if (validFiles.length > 0) {
                // 合并新文件到现有文件列表，而不是覆盖
                this.selectedFiles = [...this.selectedFiles, ...validFiles]
            }

            // 清空文件输入框，允许重复选择同一文件
            event.target.value = ''
        },
        // 格式化文件大小
        formatFileSize(bytes) {
            if (bytes === 0) return '0 B'
            const k = 1024
            const sizes = ['B', 'KB', 'MB', 'GB']
            const i = Math.floor(Math.log(bytes) / Math.log(k))
            return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
        },
        // 获取文件类型
        getFileType(file) {
            const extension = file.name.split('.').pop().toLowerCase()
            switch (extension) {
                case 'pdf':
                    return 'PDF文档'
                case 'doc':
                case 'docx':
                    return 'Word文档'
                default:
                    return '未知类型'
            }
        },
        // 移除文件
        removeFile(index) {
            this.selectedFiles.splice(index, 1)
        },
    },
    watch: {
        // 监听输入内容变化，自动调整高度
        inputMessage() {
            this.$nextTick(this.adjustTextareaHeight)
        }
    },
    mounted() {
        // 初始化时设置文本框高度
        this.adjustTextareaHeight()
    }
}
</script>

<style lang="less" scoped>
@import '@/assets/styles/deepresearch.less';

.dhome-chat-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    position: relative;
    align-items: center;

    .chat-header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 100;
        background: #f1f1f1;

        .header-title {
            font-size: 16px;
            font-weight: 500;
        }
    }

    .chat-content {
        flex: 1;
        overflow-y: auto;
        margin-left: 10px;
        width: calc(100% - 10px);
        padding: 80px 0 120px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .chat-box {
            width: 60%;
        }

        .chatbox {
            width: 100%;
            position: relative;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .avatar-box {
                position: absolute;
                top: 5px;
                left: -45px;

                img {
                    width: 30px;
                    height: 30px;

                }

                .user-avatar {
                    background-color: #aaa;
                    padding: 5px;
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
                }
            }

            .user-message {
                background: #0000000d;
                border-radius: 12px;
                padding: 12px;
                max-width: 100%;
                color: #222;
                font-size: 15px;

                .file-list {
                    display: flex;
                    gap: 5px;
                    margin-top: 10px;
                    flex-wrap: wrap;
                    margin-top: 10px;

                    .file-item {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 5px;
                        background: #fff;
                        padding: 5px 10px;
                        border-radius: 15px;
                        font-size: 13px;

                        .file-icon {
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            img {
                                width: 25px;
                                height: 25px;
                            }

                            i {
                                font-size: 20px;
                            }

                            span {
                                font-size: 15px;
                                max-width: 100px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }

                        .file-info {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            font-size: 12px;
                            color: #999;
                            margin-left: 2px;
                        }
                    }
                }
            }

            .message-content {
                color: #555;
                font-size: 14px;
                width: auto;
                max-width: 100%;
                padding: 10px;
                word-wrap: break-word;
                background: #fff;
                border-radius: 12px;
                padding: 12px;
                max-width: 100%;
            }

            .ai-message {
                background: #fff;
                border-radius: 12px;
                padding: 12px;
                max-width: 100%;

                .message-header {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    margin-bottom: 10px;

                    .ai-avatar {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }

                    .writing-title {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        font-size: 18px;
                        font-weight: 500;

                        .write-icon {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }

                .ai-message-content {
                    color: #555;
                    font-size: 14px;
                    width: auto;
                    max-width: 100%;
                    word-wrap: break-word;
                }


            }

            .options-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 16px;
                margin-top: 10px;
            }

            .option-card {
                background: #f7f8fa;
                border-radius: 8px;
                padding: 16px;
                cursor: pointer;

                &:hover {
                    background: #f0f0f0;
                }

                .card-header {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin-bottom: 8px;

                    .cc-option-icon {
                        width: 20px;
                        height: 20px;
                    }

                    i {
                        font-size: 20px;
                        color: #1890ff;
                    }

                    .el-icon-arrow-right {
                        margin-left: auto;
                        color: #999;
                    }
                }

                .card-desc {
                    color: #999;
                    font-size: 14px;
                }
            }
        }
    }

    .chat-input {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #f1f1f1;
        padding: 10px;
        text-align: center;

        .input-wrapper {
            position: relative;
            margin-bottom: 12px;
            width: 60%;
            display: inline-block;

            .file-list {
                margin: 8px 0;
                padding: 0 12px;
                max-height: 120px;
                overflow-y: auto;
                display: flex;
                flex-wrap: wrap;
                gap: 5px;

                &::-webkit-scrollbar {
                    width: 4px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #ddd;
                    border-radius: 2px;
                }

                .file-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 8px 12px;
                    background: #f7f8fa;
                    border-radius: 6px;
                    margin-bottom: 8px;
                    transition: all 0.3s ease;


                    &:hover {
                        background: #f0f0f0;
                    }

                    .file-icon {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        img {
                            width: 16px;
                            height: 16px;
                            object-fit: contain;
                        }

                        .file-name {
                            font-size: 13px;
                            color: #333;
                            max-width: 120px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    .file-info {
                        display: flex;
                        align-items: center;
                        gap: 12px;

                        .file-meta {
                            font-size: 12px;
                            color: #999;
                        }

                        .el-icon-close {
                            cursor: pointer;
                            padding: 4px;
                            font-size: 14px;
                            color: #999;
                            transition: all 0.3s ease;

                            &:hover {
                                color: #ff4d4f;
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }

            .input-box {
                position: relative;

                textarea {
                    width: 100%;
                    padding: 20px 12px;
                    border: 1px solid #000;
                    border-radius: 15px;
                    font-size: 14px;
                    resize: none;
                    min-height: 20px;
                    max-height: 120px;
                    line-height: 1.5;
                    overflow-y: auto;

                    &:focus {
                        outline: none;
                        border-color: #1890ff;
                    }

                    &:disabled {
                        background-color: #f5f5f5;
                        cursor: not-allowed;
                        color: #999;

                        &::placeholder {
                            color: #999;
                        }
                    }
                }

                .input-tools {
                    position: absolute;
                    right: -10px;
                    top: 45%;
                    transform: translateY(-50%);
                    display: flex;
                    gap: 12px;

                    .tool-icon {
                        width: 40px;
                        height: 40px;
                        cursor: pointer;
                        border: 1px solid #000;
                    }

                    i {
                        font-size: 28px;
                        cursor: pointer;
                        color: #1890ff;
                        margin-left: 5px;

                        &:hover {
                            color: #1864ab;
                        }

                        &.disabled {
                            color: #ccc;
                            cursor: not-allowed;

                            &:hover {
                                color: #ccc;
                            }
                        }
                    }
                }
            }

        }

        .input-footer {
            text-align: center;
            color: #999;
            font-size: 12px;
        }
    }
}

.examples-section {
    margin-top: 15px;

    .examples-title {
        color: #909090;
        margin-bottom: 5px;
        font-size: 14px;
    }

    .example-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;

        .example-item {
            color: #333;
            padding: 8px 10px;
            cursor: pointer;
            background-color: #fff;
            font-size: 13px;
            margin: 5px 0;
            border-radius: 8px;
            display: flex;
            align-items: center;
            gap: 5px;

            .file-name {
                color: #909090;
                font-size: 12px;
                display: flex;
                align-items: center;
                gap: 5px;

                img {
                    width: 15px;
                    height: 15px;
                }
            }

            &:hover {
                color: #1890ff;
            }
        }
    }
}

.choose-box {
    display: flex;
    gap: 20px;
    margin-top: 16px;

    .choose-item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 5px 16px;
        background: #fff;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s ease;
        background: #f6f9ff;

        .radio-circle {
            width: 16px;
            height: 16px;
            border: 2px solid #d9d9d9;
            border-radius: 50%;
            position: relative;
            transition: all 0.3s ease;

            &:after {
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                background: #1890ff;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(0);
                transition: transform 0.2s ease;
            }
        }

        span {
            font-size: 14px;
            color: #333;
            transition: color 0.3s ease;
        }

        &:hover {
            border-color: #1890ff;

            .radio-circle {
                border-color: #1890ff;
            }
        }

        &.active {
            border-color: #1890ff;
            background: #f6f9ff;

            .radio-circle {
                border-color: #1890ff;

                &:after {
                    transform: translate(-50%, -50%) scale(1);
                }
            }

            span {
                color: #1890ff;
                font-weight: 500;
            }
        }
    }
}

// 可以添加文件列表的样式
.file-list {
    margin-top: 10px;

    .file-item {
        display: flex;
        align-items: center;
        padding: 8px;
        background: #f7f8fa;
        border-radius: 4px;
        margin-bottom: 8px;

        .file-icon {
            margin-right: 8px;
            color: #1890ff;
        }

        .file-info {
            flex: 1;

            .file-name {
                font-size: 14px;
                color: #333;
            }

            .file-meta {
                font-size: 12px;
                color: #999;
            }
        }
    }
}
</style>