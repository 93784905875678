<template>
  <div class="faq">

    <div class="tabs-container">
      <!-- 选项卡导航 -->
      <div class="tabs">
        <button v-for="(tab, index) in tabs" :key="index" :class="{ active: activeTab === index }"
          @click="activeTab = index">
          <!-- {{ tab.label }} -->
          {{ $t('faqItemstitle' + index) }}
        </button>
      </div>

      <!-- 选项卡内容 -->
      <div class="tab-content">
        <p v-if="activeTab === 0">
          <MostPopular />
        </p>
        <p v-if="activeTab === 1">
          <RecordNotes />
        </p>
        <p v-if="activeTab === 2">
          <Subscription />
        </p>
      </div>
    </div>


    <!-- <RecordNotes />
   <Subscription /> -->
  </div>
</template>
<script>
import { ref } from "vue";
import MostPopular from '@/views/faq/MostPopular.vue'
import RecordNotes from '@/views/faq/RecordNotes.vue'
import Subscription from '@/views/faq/Subscription.vue'

export default {
  setup() {
    const activeTab = ref(0);
    const tabs = ref([
      { label: "Most Popular" },
      { label: "Record & Notes" },
      { label: "Subscription" }
    ]);

    return { activeTab, tabs };
  },
  components: {
    MostPopular,
    RecordNotes,
    Subscription,
  },
  methods: {

  },
};
</script>
<style lang="less" scoped>
.faq {
  // background: red;
  position: relative;
  z-index: 10; //背景图影响点击按钮

  .tabs-container {
    max-width: 1000px;
    // margin: 20px auto;
    text-align: center;
    margin-top: 80px;

    margin-left: auto;
    margin-right: auto;

  }

  .tabs button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    // background: #ddd;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: transparent;
    // color: rgb(172, 120, 255);
    color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
  }



  .tabs button.active {
    border: 1px solid rgba(206, 47, 241, 0);
    background: #6c19f5;
    color: white;
    border: 1px solid rgb(172, 120, 255);

  }

  .tab-content {
    margin-top: 40px;
    font-size: 16px;
  }
}
</style>
