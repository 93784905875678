<template>
    <div class="modal-overlay" v-if="visible">
        <div class="modal-content">
            <div class="close-btn" @click="closeModal">
                <i class="el-icon-close"></i>
            </div>
            <div class="usage-info">
                <div v-if="text_visible == 'over'">
                    <h2>You've used up your "Record Live Lecture Mode" Usage.</h2>
                    <h3>Get more usage based on your needs:</h3>
                </div>
                <div v-else-if="text_visible == 'running'">
                    <h2>Your Usage is less than 30 minutes</h2>
                    <h3>Choose your usage based on your needs:</h3>
                </div>
                <div v-if="text_visible == 'over_on'">
                    <h2>Get more Record Live Lecture Mode Usage</h2>
                    <h3>Choose your usage based on your needs:</h3>
                </div>

                <!-- 时长选择 -->
                <div class="time-selector-box">
                    <div class="time-selector">
                        <select v-model="selectedTime" class="time-select">
                            <option v-for="item in timeOptions" :key="item.minutes" :value="item.minutes">
                                {{ item.minutes }}
                            </option>
                        </select>
                        <span class="unit">Minutes</span>
                    </div>
                    <div class="shuxian"></div>
                    <!-- 价格显示 -->
                    <div class="price-display">
                        <span class="price-label">Price:</span>
                        <span class="price-amount">${{ currentPrice }}</span>
                        <span class="discount" v-if="currentDiscount">{{ currentDiscount }}%off</span>
                    </div>
                </div>


                <!-- 支付方式 -->
                <div class="payment-methods">
                    <button class="payment-btn credit-card" @click="handlePayment('stripe')">
                        Credit Card
                        <div class="card-logos">
                            <img :src="paymentImages.icon_visa" alt="Visa">
                            <img :src="paymentImages.icon_wanshida" alt="Mastercard">
                            <img :src="paymentImages.icon_us_yt" alt="American Express">
                        </div>
                    </button>
                    <button class="payment-btn wechat" @click="handlePayment('wechat')">
                        WeChat Pay
                        <img :src="paymentImages.icon_wechatpay" alt="WeChat Pay">
                    </button>
                </div>

                <!-- 提示信息 -->
                <div class="notes-section">
                    <h4>Kindly note:</h4>
                    <ul>
                        <li>This item can be purchased in multiples.</li>
                        <li>The purchase takes effect immediately and will reset at the end of the month.</li>
                        <li>Unused usage cannot be carried over to the next month.</li>
                    </ul>
                </div>

                <!-- 不再显示选项 -->
                <div class="dont-show" v-if="text_visible == 'running'">
                    <label class="checkbox-container">
                        <input type="checkbox" v-model="dontShowAgain">
                        <span class="checkmark"></span>
                        Don't show this message again
                    </label>
                </div>
            </div>
        </div>

        <!-- 替换 el-dialog 为自定义弹窗 -->
        <div class="custom-dialog-overlay" v-if="showQRCodeDialog">
            <div class="custom-dialog">
                <div class="dialog-header">
                    <span class="dialog-title">Scan the pay with WeChat</span>
                    <i class="el-icon-close close-icon" @click="handleDialogClose"></i>
                </div>
                <div class="dialog-body">
                    <div class="qrcode-container">
                        <div class="qrcode-img">
                            <span class="order-number">Order Number：{{ orderNumber }}</span>
                            <div class="qrcode-wrapper" v-loading="isLoading" element-loading-text="Loading..."
                                element-loading-spinner="el-icon-loading"
                                element-loading-background="rgba(0, 0, 0, 0.5)">
                                <img v-if="!isLoading" :src="qrCodeUrl" alt="WeChat Pay QR Code">
                                <div v-if="isQrCodeExpired" class="qrcode-overlay" @click="refreshQRCode">
                                    <span>
                                        <i class="el-icon-refresh"></i>
                                        QR Code Expired
                                        <br>
                                        Click to refresh
                                    </span>
                                </div>
                            </div>
                            <span>Extra Package For More Live</span>
                            <span>
                                <span class="price-text">${{ currentPrice }}</span> <span>({{ selectedTime }}
                                    Minutes)</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PopupExtra',
    data() {
        return {
            text_visible: 'over',
            visible: false,
            selectedTime: 300,
            dontShowAgain: false,
            paymentImages: {
                icon_wechatpay: require('@/assets/images/pay/icons8_wechatpay.png'),
                icon_alipay: require('@/assets/images/pay/icons8_alipay.png'),
                icon_visa: require('@/assets/images/pay/icons8_visa.png'),
                icon_wanshida: require('@/assets/images/pay/icons8_wanshida.png'),
                icon_us_yt: require('@/assets/images/pay/icons8_us_yt.png'),
            },
            timeOptions: [
                // { minutes: 1, price: 0.01, discount: 0 },
                // { minutes: 15, price: 0.5, discount: 0 },
                { minutes: 300, price: 5, discount: 0 },
                { minutes: 600, price: 9, discount: 90 },
                { minutes: 900, price: 13.5, discount: 90 },
                { minutes: 1200, price: 18, discount: 90 },
                { minutes: 2400, price: 36, discount: 90 },
                { minutes: 3600, price: 51, discount: 85 },
                { minutes: 4800, price: 68, discount: 85 },
                { minutes: 6000, price: 85, discount: 85 }
            ],
            showQRCodeDialog: false,
            isLoading: false,
            isQrCodeExpired: false,
            qrCodeUrl: '',
            orderNumber: '',
            statusPollingTimer: null,
            checkExpireTimer: null,
            expireTime: 0,
        }
    },
    computed: {
        currentPrice() {
            const option = this.timeOptions.find(opt => opt.minutes === this.selectedTime)
            return option ? option.price : 0
        },
        currentDiscount() {
            const option = this.timeOptions.find(opt => opt.minutes === this.selectedTime)
            return option ? option.discount : 0
        }
    },
    methods: {
        openModal(type) {
            this.text_visible = type;
            // 获取cookies中的dontShowAgain
            const C_dontShowAgain = this.$cookies.get('dontShowAgain', false);
            if (type == 'running' && C_dontShowAgain == 'true') {
                return;
            }
            this.visible = true
            document.body.style.overflow = 'hidden' // 防止背景滚动
        },
        closeModal() {
            this.visible = false

            document.body.style.overflow = '' // 恢复背景滚动
            // 将dontShowAgain保存到本地存储
            if (this.text_visible == 'running') {
                // cookies保存有效期1天
                this.$cookies.set('dontShowAgain', this.dontShowAgain, '1d');   
            }
        },
        async handlePayment(method) {
            // 处理支付逻辑
            if (method == 'stripe') {
                try {
                    const subid = this.$cookies.get("subid");
                    const response = await this.$axios.post("/api/createextrapackagesession/", {
                        subid,
                        unitamount: this.currentPrice,
                        payname: "Extra Package For More Live",
                        extra_time: this.selectedTime,
                    });

                    const { url, code } = response.data;
                    switch (code) {
                        case 200:
                            window.location.href = url;
                            break;
                        case 201:
                            this.$message({
                                message: "Please log in",
                                type: "error",
                            });
                            this.$router.push("/login");
                            break;
                        case 301:
                            this.$message({
                                message: "You have already subscribed",
                                type: "success",
                            });
                            this.$router.push("/home");
                            break;
                        default:
                            this.$message({
                                message: "Error creating checkout session",
                                type: "error",
                            });
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            if (method == 'wechat') {
                this.showQRCodeDialog = true;
                this.handleWechatPay();
            }
        },
        async handleWechatPay() {
            this.showPaymentDialog = false;
            this.showQRCodeDialog = true;
            this.isLoading = true; // 设置 loading 状态

            try {
                const subid = this.$cookies.get("subid");
                const response = await this.$axios.post("/api/create_extra_order/", {
                    subid,
                    unitamount: this.currentPrice,
                    extra_time: this.selectedTime,
                    payname: "Extra package for more Live",
                });

                const { code, qr_image, order_number, expire_time } = response.data;

                if (code === 301) {
                    this.$message.error("You have already subscribed");
                    this.$router.push("/home");
                    return;
                }

                if (code === 200) {
                    this.qrCodeUrl = qr_image;
                    this.orderNumber = order_number;
                    this.expireTime = expire_time;

                    // 开始轮询订单状态
                    this.startPollingOrderStatus();

                    // 设置二维码过期检查
                    clearInterval(this.checkExpireTimer);
                    this.checkExpireTimer = setInterval(() => {
                        if (Date.now() / 1000 >= this.expireTime) {
                            this.isQrCodeExpired = true; // 设置过期状态而不是清空URL
                            clearInterval(this.checkExpireTimer);
                            clearInterval(this.statusPollingTimer);
                        }
                    }, 1000);
                } else {
                    this.$message.error(response.data.message);
                }
            } catch (error) {
                console.error("Error:", error);
                this.$message.error("Error processing request, please try again");
            } finally {
                this.isLoading = false; // 结束 loading 状态
            }
        },
        startPollingOrderStatus() {
            // 清除之前的轮询
            if (this.statusPollingTimer) {
                clearInterval(this.statusPollingTimer);
            }

            // 开始新的轮询
            this.statusPollingTimer = setInterval(async () => {
                try {
                    const response = await this.$axios.post("/api/query_order_status_extra/", {
                        order_number: this.orderNumber
                    });

                    if (response.data.code === 200) {
                        // 支付成功
                        clearInterval(this.statusPollingTimer);
                        this.showQRCodeDialog = false;
                        this.closeModal();
                        this.$message.success("Payment Success!");
                        this.$router.push("/home");
                    }
                } catch (error) {
                    console.error("Error polling status:", error);
                }
            }, 3000); // 每3秒查询一次
        },
        handleDialogClose() {
            this.showQRCodeDialog = false;
            if (this.statusPollingTimer) {
                clearInterval(this.statusPollingTimer);
            }
            if (this.checkExpireTimer) {
                clearInterval(this.checkExpireTimer);
            }
        },
        async refreshQRCode() {
            this.isQrCodeExpired = false;
            this.isLoading = true; // 设置 loading 状态

            try {
                const response = await this.$axios.post("/api/refresh_ex_code/", {
                    order_number: this.orderNumber
                });

                const { code, qr_image, order_number, expire_time } = response.data;
                if (code === 200) {
                    this.qrCodeUrl = qr_image;
                    this.orderNumber = order_number;
                    this.expireTime = expire_time;

                    // 重新开始轮询
                    this.startPollingOrderStatus();

                    // 设置二维码过期检查
                    clearInterval(this.checkExpireTimer);
                    this.checkExpireTimer = setInterval(() => {
                        if (Date.now() / 1000 >= this.expireTime) {
                            this.isQrCodeExpired = true; // 设置过期状态而不是清空URL
                            clearInterval(this.checkExpireTimer);
                            clearInterval(this.statusPollingTimer);
                        }
                    }, 1000);
                } else {
                    this.$message.error(response.data.message);
                }
            } catch (error) {
                this.isQrCodeExpired = true; // 如果刷新失败，显示过期状态
                console.error("Error:", error);
                this.$message.error("Refresh QR Code Failed");
            } finally {
                this.isLoading = false; // 结束 loading 状态
            }
        }
    }
}
</script>

<style lang="less" scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 998;
}

.modal-content {
    width: 650px;
    background: #1a1a2e;
    color: white;
    border-radius: 12px;
    animation: modalFadeIn 0.3s;
    padding: 20px 40px;
    position: relative;
}

@keyframes modalFadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;

    i {
        font-size: 20px;
        color: #fff;
    }

    &:hover {
        background: rgba(255, 255, 255, 0.2);
        transform: rotate(90deg);
    }
}

.usage-info {
    padding: 20px;

    h2 {
        font-size: 22px;
        text-align: center;
        margin-bottom: 20px;
    }

    h3 {
        font-size: 18px;
        margin-bottom: 25px;
        text-align: center;
    }
}

.time-selector-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    .shuxian {
        width: 2px;
        height: 66px;
        background: #ddd;
        margin: 0 20px;
    }

}

.time-selector {
    display: flex;
    align-items: center;
    gap: 10px;

    .time-select {
        width: 100px;
        padding: 5px 10px;
        border-radius: 4px;
        border: 1px solid #ddd;
        background: white;
        font-size: 16px;
        outline: none;

        &:focus {
            border-color: #6c5ce7;
        }
    }

    .unit {
        font-size: 18px;
        font-weight: 600;
    }
}

.price-display {
    display: flex;
    align-items: center;
    gap: 10px;

    .price-label {
        font-size: 18px;
        font-weight: 600;
    }

    .price-amount {
        font-size: 38px;
        color: #ff6b6b;
    }

    .discount {
        background: rgba(255, 107, 107, 0.2);
        color: #ff6b6b;
        padding: 2px 8px;
        border-radius: 4px;
    }
}

.payment-methods {
    display: flex;
    gap: 15px;
    margin: 30px 0;

    .payment-btn {
        flex: 1;
        padding: 10px;
        border-radius: 8px;
        border: none;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        cursor: pointer;
        transition: all 0.3s;
        font-size: 16px;

        &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
        }

        img {
            height: 25px;

        }
    }

    .credit-card {
        background: #6c5ce7;

        .card-logos {
            display: flex;
            gap: 5px;
        }
    }

    .wechat {
        background: #4caf50;
    }
}

.notes-section {
    h4 {
        margin-bottom: 10px;
        font-size: 18px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        background: none;
        width: 100%;

        li {
            margin-bottom: 5px;
            font-size: 15px;
            color: #ddd;
            position: relative;
            padding-left: 15px;

            &:before {
                content: "•";
                position: absolute;
                left: 0;
            }
        }
    }
}

.dont-show {
    text-align: center;

    .checkbox-container {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        color: #ddd;
        user-select: none;

        input[type="checkbox"] {
            width: 16px;
            height: 16px;
            margin: 0;
            cursor: pointer;
        }
    }
}

.qrcode-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;

    .qrcode-img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .order-number {
            color: #ddd;
        }

        img {
            border: 1px solid #fefefe;
            width: 200px;
            height: 200px;
            margin-top: 20px;
        }

        span {
            margin-top: 10px;
        }

        .refresh-link {
            cursor: pointer;
            color: #00F9E5;
        }

        .price-text {
            font-size: 26px;
            font-weight: bold;
            color: red;
            margin-right: 5px;
        }

        .qrcode-wrapper {
            position: relative;
            display: inline-block;
            margin: 20px 0;
            width: 200px; // 添加固定宽度
            height: 200px; // 添加固定高度

            img {
                max-width: 100%;
                max-height: 100%;
                margin: 10px 0;
            }

            .qrcode-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 205px;
                height: 220px;
                background: rgba(0, 0, 0, 0.7);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: background-color 0.3s;

                span {
                    color: white;
                    text-align: center;
                    font-size: 14px;
                    line-height: 1.5;

                    i {
                        font-size: 24px;
                        margin-bottom: 8px;
                        display: block;
                    }
                }

                &:hover {
                    background: rgba(0, 0, 0, 0.8);
                }
            }
        }
    }

    .order-info {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
}

/* 添加自定义弹窗样式 */
.custom-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.custom-dialog {
    background: #1a1a2e;
    border-radius: 8px;
    width: 30vw;
    animation: dialogFadeIn 0.3s ease;
}

@keyframes dialogFadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.dialog-header {
    padding: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dialog-title {
        font-size: 18px;
        font-weight: bold;
        color: white;
        width: 100%;
        text-align: center;
    }

    .close-icon {
        cursor: pointer;
        color: #999;
        font-size: 20px;
        transition: color 0.3s;

        &:hover {
            color: white;
        }
    }
}

.dialog-body {
    padding: 20px;
}
</style>
